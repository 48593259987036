import { login, getUser, getUsers } from '@/services/users'
import { LOGIN, LOGOUT, FETCH_USER, FETCH_USERS, SET_USER, SET_USERS, SET_LOGGED_IN, SET_FILTER_COMPANY } from './constants'

export default {
  async [LOGIN]({ commit }, { email, password }) {
    const { data: { id, token, name, role, company, datetimeFormat } = {} } = await login({ email, password })
    const user = { id, token, name, email, role, company, datetimeFormat }
    commit(SET_USER, user)
    localStorage.setItem('user', JSON.stringify(user))
    commit(SET_LOGGED_IN, true)
  },

  async [LOGOUT]({ commit }) {
    localStorage.removeItem('user')
    commit(SET_USER, null)
    commit(SET_LOGGED_IN, false)
    // Set default option
    commit(SET_FILTER_COMPANY, {
      name: 'All',
      _id: null
    })
  },

  async [FETCH_USER]({ commit, state }, id) {
    // Refresh user after edit 
    const { data: { name, role, email, company, datetimeFormat } = {} }  = await getUser(id)
    const user = { id, name, email, role, company, datetimeFormat }
    // Merge new user props with current and save to store
    commit(SET_USER, { ...state.user, ...user })
    // Merge new user props with current and save to localStorage
    const userFromStorage = localStorage.getItem('user')
    localStorage.setItem('user', JSON.stringify({ ...JSON.parse(userFromStorage), ...user }))
  },

  async [FETCH_USERS]({ commit }) {
    const { data = [] } = await getUsers()
    commit(SET_USERS, data)
  }
}