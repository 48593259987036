<template>
  <div v-if="orderParking" class="row justify-content-md-center">
    <div class="col-12">
      <google-map 
        panelBodyClass="p-0"
        :panelTitle="`Order Parking Details - ${parkingLabel}`"
        mapClass="height-md"
        :mapZoom="13"
        :mapCenter="parkingLocation"
      >
        <template slot="content">
          <GmapCircle :center="parkingLocation" :radius="1500" :options="{fillColor:'blue', fillOpacity:0.1}" :visible="true"></GmapCircle>
          <GmapMarker :position="parkingLocation" :clickable="false" :draggable="false" :icon="parkingMarkerIcon"></GmapMarker>
        </template>
      </google-map>
      <form>
        <panel title="Order Parking Info">
          <div class="row">
            <div class="col-2">
              <label>Start Datetime</label>
            </div>
            <div class="col-4">
              <input class="form-control" :value="orderParking.startDateTime | momentDST" readonly>
            </div>

            <div class="col-2">
              <label>End Datetime</label>
            </div>
            <div class="col-4">
              <input class="form-control" :value="orderParking.stopDateTime | momentDST" readonly>
            </div>
          </div>
        </panel>
      </form>
    </div>
  </div>
</template>

<script>
import GoogleMap from '@/components/map/GoogleMap.vue'
import { getOrderParking } from '@/services/orderParkings'
import { gmapApi } from 'vue2-google-maps'

export default {
  components: {
    GoogleMap,
  },
  data() {
    return {
      orderParking: null,
    }
  },
  computed: {
    google: gmapApi,
    parkingLocation() {
      return { lat: this.orderParking.location?.latitude || 0, lng: this.orderParking.location?.longitude || 0 }
    },
    isLoading() {
      return this.orderParking?.parkingType.toLowerCase() === 'loading'
    },
    index() {
      return this.$route.params.index || 0
    },
    parkingLabel() {
      return this.orderParking?.parkingType.toLowerCase() === 'loading' ? 'Loading' : `Parking #${this.index}`
    },
    parkingMarkerIcon() {
      const path = this.isLoading ? '/assets/img/svg/loading-marker.svg' : '/assets/img/svg/parking-gmap-marker.svg'
      return {
        url: path,
        scaledSize: this.google && new this.google.maps.Size(25, 25)
      }
    },
  },
  methods: {
    async refresh() {
      const { data = {} } = await getOrderParking(this.$route.params.id)
      this.orderParking = data
    }
  },
  async created () {
    await this.refresh()
  }
}
</script>

<style>

</style>