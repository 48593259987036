<template>
  <b-modal 
    id="tracker-basic-info-add-modal"
    title="Add ZOX-SW"
    size="md"
    @hidden="reset"
  >
    <template>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-3 align-self-center">
          <label>ICCID</label>
        </div>
        <div class="col-7">
          <input type="text" class="form-control" v-model="trackerBasicInfo.iccid" placeholder="Enter the ICCID" />
        </div>
      </div>
    </template>
    <div class="row mb-3 justify-content-md-center">
      <div class="col-3 align-self-center">
        <label>MSISDN</label>
      </div>
      <div class="col-7">
        <input type="number" class="form-control" v-model="trackerBasicInfo.msisdn" placeholder="Enter the MSISDN" />
      </div>
    </div>
    <div class="row mb-3 justify-content-md-center">
      <div class="col-3 align-self-center">
        <label>Serial Number (PCB)</label>
      </div>
      <div class="col-7">
        <input type="number" class="form-control" v-model="trackerBasicInfo.sn" placeholder="Enter the Serial Number" />
      </div>
    </div>
    <div class="row mb-3 justify-content-md-center">
      <div class="col-3 align-self-center">
        <label>IMEI</label>
      </div>
      <div class="col-7">
        <input type="number" class="form-control" v-model="trackerBasicInfo.imei" placeholder="Enter the IMEI" />
      </div>
    </div>
    <div class="row mb-3 justify-content-md-center">
      <div class="col-3 align-self-center">
        <label>Tracker No.</label>
      </div>
      <div class="col-7">
        <input type="number" class="form-control" v-model="trackerBasicInfo.tn" placeholder="Enter the Tracker No." />
      </div>
    </div>
    <div class="row mb-3 justify-content-md-center">
      <div class="col-3 align-self-center">
        <label>Model</label>
      </div>
      <div class="col-7">
        <v-select
          transition=""
          v-model="trackerBasicInfo.model"
          :options="['TR1', 'TR2', 'TR4']"
          label="label"
          :clearable="false"
        />
      </div>
    </div>
    <div class="row mb-3 justify-content-md-center">
      <div class="col-3 align-self-center">
        <label>Box Label</label>
      </div>
      <div class="col-7">
        <input type="text" class="form-control" v-model="trackerBasicInfo.bl" placeholder="Enter the Box Label" />
      </div>
    </div>
    <div class="row mb-3 justify-content-md-center">
      <div class="col-3 align-self-center">
        <label>TR Tag</label>
      </div>
      <div class="col-7">
        <v-select
          transition=""
          v-model="trackerBasicInfo.label"
          :reduce="item => item.value"
          :options="[{label: 'true', value: true}, {label: 'false', value: false}]"
          :clearable="false"
        />
      </div>
    </div>
    <div class="row mb-3 justify-content-md-center">
      <div class="col-3 align-self-center">
        <label>Warehouse</label>
      </div>
      <div class="col-7">
        <v-select
          transition=""
          v-model="trackerBasicInfo.wh"
          :reduce="item => item.value"
          :options="[{label: 'true', value: true}, {label: 'false', value: false}]"
          :clearable="false"
        />
      </div>
    </div>
    <div class="row mb-3 justify-content-md-center">
      <div class="col-3 align-self-center">
        <label>Shipped</label>
      </div>
      <div class="col-7">
        <v-select
          transition=""
          v-model="trackerBasicInfo.sent"
          :reduce="item => item.value"
          :options="[{label: 'true', value: true}, {label: 'false', value: false}]"
          :clearable="false"
        />
      </div>
    </div>
    <div class="row mb-3 justify-content-md-center">
      <div class="col-3 align-self-center">
        <label>Company</label>
      </div>
      <div class="col-7">
        <CompanySelect
          v-model="trackerBasicInfo.company"
          disabled
        />
      </div>
    </div>
    <template #modal-footer="{ cancel }">
      <button class="btn" @click="cancel">Cancel</button>
      <button class="btn btn-success" @click="save">Save</button>
    </template>
  </b-modal>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import CompanySelect from '@/components/CompanySelect'

const trackerBasicInfoModel = {
  iccid: null,
  msisdn: null,
  sn: null,
  tn: null,
  bl: null,
  model: null,
  label: false,
  wh: false,
  sent: false,
  company: {},
}

export default {
  data() {
    return {
      trackerBasicInfo: {
        ...trackerBasicInfoModel
      }
    }
  },
  components: {
    CompanySelect
  },
  mounted() {
    this.fillCompany()
  },
  computed: {
    ...mapState('main', ['companies']),
  },
  methods: {
    fillCompany() {
      // Try to find Zoxtracker company 
      const zoxTrackerCompany = this.companies.find(company => company.name === 'dev-Zoxtracker' || company.name === 'Zoxtracker')
      if (zoxTrackerCompany) Vue.set(this.trackerBasicInfo, 'company', zoxTrackerCompany)
    },
    reset() {
      this.trackerBasicInfo = { ...trackerBasicInfoModel }
      this.fillCompany()
    },
    validate() {
      // Basic validation
      if (!this.trackerBasicInfo.iccid) return 'ICCID is required'
      if (!this.trackerBasicInfo.model) return 'Model is required'
      return 
    },
    save() {
      const error = this.validate() 
      if (error) {
        this.$toast(error, { className: ['et-alert'] })
        return 
      }
      this.$emit('save', { ...this.trackerBasicInfo, companyId: this.trackerBasicInfo.company?._id })
    }
  }
}
</script>
