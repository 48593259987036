<template>
  <b-modal
    hide-header-close 
    no-close-on-esc
    no-close-on-backdrop
    id="google-map-picker-modal"
    title="Pick a location"
  >
    <GmapMap class="bg-black height-md" :zoom="7" :center="initialPosition" mapTypeId='hybrid'>
      <GmapMarker :position="initialPosition" @dragend="updateCoordinates" draggable></GmapMarker>
    </GmapMap>
    <template #modal-footer="{ cancel }">
      <button class="btn" @click="cancelPick(cancel)">Cancel</button>
      <button class="btn btn-success" @click="pickLocation">Pick</button>
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    latitude: {
      type: Number,
    },
    longitude: {
      type: Number,
    }
  },
  watch: {
    latitude: {
      immediate: true, 
      handler(newVal) {
        this.lat = _.clone(newVal)
      }
    },
    longitude: {
      immediate: true, 
      handler(newVal) {
        this.lng = _.clone(newVal)
      }
    }
  },
  data() {
    return {
      lat: _.clone(this.latitude),
      lng: _.clone(this.longitude),
    }
  },
  computed: {
    initialPosition() {
      if (this.lat && this.lng) return { lat: this.lat, lng: this.lng }
      return { lat: 50.075539, lng: 14.437800 }
    }
  },
  methods: {
    cancelPick(cancelCallback) {
      // Return back unsaved values
      this.lat = _.clone(this.latitude)
      this.lng = _.clone(this.longitude)
      cancelCallback()
    },
    updateCoordinates(location) {
      this.lat = location.latLng.lat()
      this.lng = location.latLng.lng()
    },
    pickLocation() {
      this.$emit('locationUpdated', { lat: this.lat, lng: this.lng })
    }
  }
}
</script>

<style>

</style>