import Vue from 'vue'
import { 
  SET_ACTIVE_ALERTS, SET_RESOLVED_ALERTS,
} from './constants'

export default {
  [SET_ACTIVE_ALERTS](state, item) {
    Vue.set(state, 'activeAlerts', item)
  },
  [SET_RESOLVED_ALERTS](state, item) {
    Vue.set(state, 'resolvedAlerts', item)
  },
}