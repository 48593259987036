<template>
  <div class="geofencing-place-table">      
    <table-basic :title="'Geofencing - Place'">
      <template slot="afterTitle">
        <button @click="openEditModal(null)" type="button" class="btn btn-orange ml-2 py-1 px-2">
          <i class="fas fa-plus-circle mr-2"></i> Create
        </button>
        <button @click="generateStartGeo" type="button" class="btn btn-orange ml-2 py-1 px-2">
          <i class="fas fa-cogs mr-2"></i> Generate start geo.
        </button>
        <button @click="generateEtaGeo" type="button" class="btn btn-orange ml-2 py-1 px-2">
          <i class="fas fa-cogs mr-2"></i> Generate ETA geo.
        </button>
        <button @click="generateFinalGeo" type="button" class="btn btn-orange ml-2 py-1 px-2">
          <i class="fas fa-cogs mr-2"></i> Generate Final geo.
        </button>
      </template>
      <template v-slot:thead>
        <tr class="cursor-pointer">
          <th>Name</th>
          <th>Distance (metres)</th>
          <th>When trigger</th>
          <th>Latitude</th>
          <th>Longitude</th>
          <th>Update Period (sec)</th>
          <th>Is Active</th>
          <th>Send Emails</th>
          <th>Use GPS</th>
        </tr>
      </template>

      <template v-slot:tbody>
        <tr v-for="geofencing in geofencingPlaces" :key="geofencing._id">
          <td>{{ geofencing.name }}</td>
          <td>{{ geofencing.distance }}</td>
          <td>{{ geofencing.trigger }}</td>
          <td>{{ geofencing.gps.latitude }}</td>
          <td>{{ geofencing.gps.longitude }}</td>
          <td>{{ geofencing.activeTime }}</td>
          <td>{{ geofencing.isActive }}</td>
          <td>{{ geofencing.sendEmails }}</td>
          <td>{{ geofencing.useGps }}</td>
          <td class="with-btn text-center">
            <b-button variant="warning" @click="openEditModal(geofencing)" size="sm" type="button">EDIT</b-button>
          </td>
          <td class="with-btn text-center">
            <b-button variant="danger" @click="deleteGeofencing(geofencing)" size="sm" type="button">DELETE</b-button>
          </td>
        </tr>
      </template>
    </table-basic>
    <geofencing-place-edit-modal @hidden="currentGeofencing = null" :geofencing="currentGeofencing" @save="updateGeofencing" /> 
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapState, mapActions } from 'vuex'
import { FETCH_GEOFENCING_PLACES } from '@/store/order/constants'

import TableBasic from '@/components/tables/TableBasic.vue'
import { createGeofencingPlace, editGeofencingPlace, deleteGeofencingPlace } from '@/services/geofencingPlace'
import GeofencingPlaceEditModal from '../modals/GeofencingPlaceEditModal'

export default {
  props: ['order'],
  data() {
    return {
      currentGeofencing: null,
    }
  },
  components: { 
    TableBasic,
    GeofencingPlaceEditModal,
  },
  created() {
    this.refresh()
  },
  computed: {
    ...mapState('order', ['geofencingPlaces']),
  },
  methods: {
    ...mapActions('order', {
      fetchGeofencingPlaces: FETCH_GEOFENCING_PLACES,
    }),
    async refresh() {
      await this.fetchGeofencingPlaces({ orderId: this.order?._id })
    },
    openEditModal(geofencing) {
      this.currentGeofencing = geofencing
      this.$bvModal.show('geofencing-place-edit-modal')
    },
    async generateStartGeo() {
      const { address = null, gps: { latitude = null, longitude = null } = {} } = this.order.startLocation
      if (!latitude || !longitude || !address) {
        this.$toast('Please provide origin location to generate start geo.', {
          className: ['et-alert']
        })
        return
      }
      const startGeofencing = {
        order: this.order._id,
        name: 'Origin Left',
        distance: 3000,
        trigger: 'Left',
        gps: { latitude, longitude, address },
        activeTime: 600,
        isActive: true,
        sendEmails: true
      }
      await createGeofencingPlace(startGeofencing)
      this.$toast('Success', {
        className: ['bg-success']
      })
      await this.refresh()
    },
    async generateEtaGeo() {
      const { address = null, gps: { latitude = null, longitude = null } = {} } = this.order.finalDestination
      if (!latitude || !longitude || !address) {
        this.$toast('Please provide final destination to generate ETA geo.', {
          className: ['et-alert']
        })
        return
      }
      const etaGeofencings = [
        {
          order: this.order._id,
          name: 'Final destination ETA 100km',
          distance: 100000,
          trigger: 'Reached',
          gps: { latitude, longitude, address },
          activeTime: 600,
          isActive: true,
          sendEmails: true,
          useGps: false,
        },
        {
          order: this.order._id,
          name: 'Final destination ETA 50km',
          distance: 50000,
          trigger: 'Reached',
          gps: { latitude, longitude },
          activeTime: 180,
          isActive: true,
          sendEmails: true,
          useGps: false,
        },
        {
          order: this.order._id,
          name: 'Final destination ETA 20km',
          distance: 20000,
          trigger: 'Reached',
          gps: { latitude, longitude },
          activeTime: 180,
          isActive: false,
          sendEmails: true,
          useGps: false,
        }
      ]
      for (const geofencing of etaGeofencings) {
        await createGeofencingPlace(geofencing) 
      }
      this.$toast('Success', {
        className: ['bg-success']
      })
      await this.refresh()
    },
    async generateFinalGeo() {
      const { address = null, gps: { latitude = null, longitude = null } = {} } = this.order.finalDestination
      if (!latitude || !longitude || !address) {
        this.$toast('Please provide final destination to generate final geo.', {
          className: ['et-alert']
        })
        return
      }
      const finalGeofencing = {
        order: this.order._id,
        name: 'Final Destination',
        distance: 3000,
        trigger: 'Reached',
        gps: { latitude, longitude, address },
        activeTime: 600,
        isActive: false,
        sendEmails: true
      }
      await createGeofencingPlace(finalGeofencing)
      this.$toast('Success', {
        className: ['bg-success']
      })
      await this.refresh()
    },
    async updateGeofencing({ event, value }) {
      try {
        if (event === 'create') {
          await createGeofencingPlace({ ...value, order: this.order._id })
        } else if (event === 'edit') {
          await editGeofencingPlace(value)
        }
        this.$toast('Success', {
          className: ['bg-success']
        })
        this.$bvModal.hide('geofencing-place-edit-modal')
        await this.refresh()
      } catch(e) {
        // Get error message from a response
        const { message = null } = e.response?.data
        if (message) {
          this.$toast(message, {
            className: ['et-alert']
          })
        } else {
          this.$toast('Server error', {
            className: ['et-alert']
          })
        }
      }
    },
    async deleteGeofencing({ _id, name }) {
      const { isConfirmed } = await Swal.fire({
        html: `<span> Are you sure you want to delete this geofencing: </span> <b>${name}</b> ?`,
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-success btn-sm mr-2',
          denyButton: 'btn btn-danger btn-sm'
        },
        buttonsStyling: false
      })
      if (isConfirmed) {
        await deleteGeofencingPlace(_id)
        this.$toast('Success', {
          className: ['bg-success']
        })
        await this.refresh()
      }
    }
  }
}
</script>

<style lang="scss">
  .geofencing-place-table {
    td {
      &.with-btn {
        width: 70px;
      }
    }
  }
</style>