import api from './api'

export const getFirmwares = async () => (
  await api.post('/firmwares')
)

export const getFirmware = async (id) => (
  await api.post(`/firmware/${id}`)
)

export const getFirmwareByModel = async (model) => (
  await api.post(`/firmwareByModel/${model}`)
)

export const createFirmware = async (firmware) => (
  await api.post('/firmware/create', firmware)
) 

export const editFirmware = async (firmware) => (
  await api.post(`/firmware/update/${firmware._id}`, firmware)
)

export const deleteFirmware = async (id) => (
  await api.delete(`/firmware/delete/${id}`)
)

export const deleteFirmwares = async ({ firmwareIds }) => (
  await api.post('/firmwares/delete', { firmwareIds })
)