import { getTrackers, getTracker } from '@/services/trackers'
import router from '@/router'
import { 
  FETCH_TRACKERS, SET_TRACKERS, SET_TOTAL_RECORDS,
  FETCH_TRACKER, SET_TRACKER
} from './constants'

export default {
  async [FETCH_TRACKERS]({ commit, rootState }, { serverParams }) {
    const { user, filterCompany } = rootState.user 
    const { orders } = rootState.order
    // Get tracker query status from current route. Use 'all' as default
    const { status = 'all' } = router.history.current?.params
    const { _id, excludeTestingCompany = false } = filterCompany
    // If user is admin -> use company from selector
    const companyId = user?.role === 'admin' ? _id : user?.company
    const { data: { docs = [], totalDocs = 0 } } = await getTrackers({ status, companyId, excludeTestingCompany, ...serverParams })
    // Define the order of list
    const sortByStatus = {
      'In-transit': 0,
      'Delivered': 1,
      'Unknown': 2,
    }
    const trackers = docs.map(tracker => {
      const order = orders.find(order => order._id === tracker.orders[0])
      return {
        ...tracker,
        orderName: order?.name || '',
        orderStatus: order?.status || 'Unknown'
      }
    }).sort(
      (a, b) => sortByStatus[a.orderStatus] - sortByStatus[b.orderStatus]
    )
    commit(SET_TRACKERS, trackers)
    commit(SET_TOTAL_RECORDS, totalDocs)
  },
  async [FETCH_TRACKER]({ commit }, { id }) {
    const { data = [] } = await getTracker(id)
    commit(SET_TRACKER, data)
  }
}