<template>
  <div class="order-alerts-table">      
    <table-basic :title="'Order Alerts - ' + order.name">
      <template v-slot:thead>
        <tr class="cursor-pointer">
          <th>Tracker ID</th>
          <th>State</th>
          <th>Description</th>
          <th>Created time</th>
          <th>Info</th>
        </tr>
      </template>

      <template v-slot:tbody>
        <tr v-for="alert in orderAlerts" :key="alert._id">
          <td>{{ alert.trackerName }}</td>
          <td>{{ alert.state }}</td>
          <td>{{ alert.description }}</td>
          <td>{{ alert.createdTime }}</td>
          <td>{{ getInfoLabel(alert) }}</td>
          <td v-if="isAdmin" class="with-btn text-center">
            <router-link :to="{ path: `/alerts/${alert._id}`,}">
              <button type="button" class="btn btn-sm btn-primary">DETAIL</button>
            </router-link>
          </td>
        </tr>
      </template>
    </table-basic>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TableBasic from '@/components/tables/TableBasic.vue'

export default {
  components: { 
    TableBasic,
  },
  computed: {
    ...mapState('order', ['order', 'orderAlerts']),
    ...mapState('user', ['user']),
    isAdmin() {
      return this.user?.role === 'admin'
    },
  },
  methods: {
    getInfoLabel({ startDatetime, endDatetime, state }) {
      const prefix = state.toLowerCase() === 'dislocated' ? 'Dislocated' : 'Data Missing'
      if (startDatetime && endDatetime) return `${prefix} between ${startDatetime} and ${endDatetime}`
      if (startDatetime) return `${prefix} from ${startDatetime}`
      if (endDatetime) return `${prefix} till ${endDatetime}`
      return `No ${state.toLowerCase()} data` 
    },
  }
}
</script>