import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const placeholderCompany = {
  name: 'All',
  _id: null
}
const user = JSON.parse(localStorage.getItem('user'))

const state = {
  user:  user || null,
  users: [],
  loggedIn: user ? true : false,
  filterCompany: placeholderCompany,
}

export default {
  namespaced: true, 
  state,
  mutations,
  actions,
  getters
}
