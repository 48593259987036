import { getTrackersCount, getActiveTrackersCount, getFreeTrackersCount } from '@/services/trackers'
import { getActiveAlertsCount } from '@/services/alerts'
import { getCompanies } from '@/services/companies'
import { 
  FETCH_COMPANIES, FETCH_TRACKERS_COUNT,
  FETCH_ACTIVE_ALERTS_COUNT, SET_COMPANIES, SET_TRACKERS_COUNT,
  SET_ACTIVE_TRACKERS_COUNT, SET_FREE_TRACKERS_COUNT, SET_ACTIVE_ALERTS_COUNT, 
} from './constants'

export default {
  async [FETCH_COMPANIES]({ commit }) {
    const { data = [] } = await getCompanies()
    commit(SET_COMPANIES, data)
  },

  async [FETCH_TRACKERS_COUNT]({ commit, rootState }) {
    const { user, filterCompany } = rootState.user 
    const { _id = null, excludeTestingCompany = false } = filterCompany
    // If user is admin -> use company from selector
    const companyId = user?.role === 'admin' ? _id : user?.company
    const trackersCount = (await getTrackersCount({ companyId, excludeTestingCompany })).data || 0
    const activeTrackersCount = (await getActiveTrackersCount({ companyId, excludeTestingCompany })).data || 0 
    const freeTrackersCount = (await getFreeTrackersCount({ companyId, excludeTestingCompany })).data || 0 
    commit(SET_TRACKERS_COUNT, trackersCount)
    commit(SET_ACTIVE_TRACKERS_COUNT, activeTrackersCount)
    commit(SET_FREE_TRACKERS_COUNT, freeTrackersCount)
  },

  async [FETCH_ACTIVE_ALERTS_COUNT]({ commit, rootState }) {
    const { user, filterCompany } = rootState.user 
    const { _id = null, excludeTestingCompany = false } = filterCompany
    // If user is admin -> use company from selector
    const companyId = user?.role === 'admin' ? _id : user?.company
    const activeAlertsCount = (await getActiveAlertsCount({ companyId, excludeTestingCompany })).data || 0
    commit(SET_ACTIVE_ALERTS_COUNT, activeAlertsCount)
  },
}