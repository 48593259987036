<template>
  <b-modal 
    id="user-edit-modal"
    @hidden="handleCloseModal"
    size="lg"
    :title="`${ !user ? 'Create' : 'Edit' } user`"
    no-close-on-backdrop
  >
    <template v-if="userModel">
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Name</label>
        </div>
        <div class="col-5">
          <input class="form-control" v-model="userModel.name" placeholder="Enter name" />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Email</label>
        </div>
        <div class="col-5">
          <input class="form-control" v-model="userModel.email" @input="userModel.email=$event.target.value.toLowerCase()" placeholder="Enter email" />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Role</label>
        </div>
        <div class="col-5">
          <v-select 
            transition=""
            v-model="userModel.role"
            :options="['admin', 'customer', 'zoxsw']"
            label="label"
            :clearable="false"
          />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Datetime Format</label>
        </div>
        <div class="col-5">
          <v-select 
            transition=""
            v-model="userModel.datetimeFormat"
            :options="datetimeFormats"
            label="format"
            :reduce="obj => obj.format"
            :clearable="false"
          >
            <template #option="{ format, example }">
              <h6 style="margin: 0">{{ format }}</h6>
              <em style="color: orange">{{ example }}</em>
            </template>
          </v-select>
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Company</label>
        </div>
        <div class="col-5">
          <CompanySelect 
            v-model="userModel.company" 
          />
        </div>
      </div>
      <div v-if="!user" class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Password</label>
        </div>
        <div class="col-5">
          <input 
            v-model="userModel.password"
            readonly
            onfocus="this.removeAttribute('readonly')"
            type="password"
            class="form-control"
            placeholder="Password" 
          />
        </div>
      </div>
    </template>
    <template #modal-footer="{ cancel }">
      <button class="btn" @click="cancel">Cancel</button>
      <button class="btn btn-success" @click="save">Save</button>
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import CompanySelect from '@/components/CompanySelect'
import datetimeFormats from '@/config/datetimeFormats'

const defaultState = {
  name: '',
  email: '',
  company: '',
  role: '',
  password: '',
  datetimeFormat: ''
}

export default {
  props: ['user'],
  data() {
    return {
      userModel: null,
      datetimeFormats,
    }
  },
  components: {
    CompanySelect,
  },
  watch: {
    user: {
      deep: true,
      immediate: true, 
      handler (newVal) {
        if (newVal) {
          this.userModel = _.cloneDeep(newVal)
        } else {
          this.userModel = _.cloneDeep(defaultState)
        }
      }
    },
    'userModel.role'(newVal, oldVal) {
      if (oldVal) this.userModel.company = null
    }
  },
  methods: {
    handleCloseModal() {
      // Clear user model after close and fire event
      this.$emit('hidden')
      this.userModel = _.cloneDeep(defaultState)
    },
    validate() {
      // Basic validation
      if (!this.userModel.name) return 'Name is required'
      if (!this.userModel.email || !/\S+@\S+\.\S+/.test(this.userModel.email)) return 'Email is required and should be valid'
      if (!this.userModel.role) return 'Role is required'
      if (!this.userModel.password) return 'Password is required'
      if (!this.userModel.company) return 'Company is required'
      return 
    },
    save() {
      if (!this.user) {
        const error = this.validate() 
        if (error) {
          this.$toast(error, { className: ['et-alert'] })
          return 
        }
        this.$emit('save', { event: 'create', value: this.userModel })
      } else {
        // Update existing user
        this.$emit('save', { event: 'edit', value: this.userModel }) 
      }
    }
  }
}
</script>

<style>

</style>