export default [
  {
    title: 'Dashboard',
    path: '/',
    roles: ['admin', 'customer']
  },
  {
    path: '/orders',
    title: 'All Orders',
    roles: ['admin', 'customer']
  },
  // {
  //   path: '/config',
  //   title: 'Config',
  //   children: [
  //     { path: '/config/add-trackers-manualy', title: 'Add trackers - manual' },
  //     { path: '/config/add-trackers-csv', title: 'Add trackers - CSV' },
  //     { path: '/config/user-list', title: 'User/owner list' },
  //   ],
  // },
  // {
  //   path: '/photos',
  //   title: 'Fota',
  // },
  // {
  //   path: '/geofencing',
  //   title: 'Geofencing',
  // }
]
