<template>
  <b-modal
    size="xl"
    id="tracker-list-modal"
    scrollable
    :title="`Pick ${model} trackers to update (${version})`"
  >
    <div class="row justify-content-md-center">
      <div class="col-12">
        <TableAdvanced
          ref="trackersTable"
          :columns="columns" 
          :rows="filteredTrackers"
          :selectOptions="{ 
            enabled: true,
            selectOnCheckboxOnly: true
          }"
        >
          <template slot="outerPanelRow">
            <div class="date-filter d-flex">    
              <div class="d-flex">
                <div class="align-self-center mr-2">
                  <label style="width:80px" class="mb-0">Company</label>
                </div>
                <CompanySelect 
                  v-if="isAdmin"
                  :value="customFilterCompany && customFilterCompany.name"
                  @input="setCompanyFilter"
                  class="align-self-center mr-3" 
                  all-option
                  dark
                />
              </div>
            </div>
          </template>
          <template #rowActions="{ props }">
            <template v-if="props.column.field == 'isActive'">
              <span>{{props.row.isActive ? "Active" : "Inactive"}}</span>
            </template>
            <template v-else-if="props.column.field == 'alarmPeriod'">
              <span>{{ `${Math.floor(props.row.alarmPeriod / 60)} minutes`}}</span>
            </template>
          </template>
        </TableAdvanced>
      </div>
    </div>
    <template #modal-footer="{ cancel }">
      <button class="btn" @click="cancel">Cancel</button>
      <button class="btn btn-success" @click="update">Update</button>
    </template>
  </b-modal>
</template>

<script>
import Swal from 'sweetalert2'
import { getTrackers } from '@/services/trackers'
import { mapState } from 'vuex'
import TableAdvanced from '@/components/tables/TableAdvanced.vue'
import CompanySelect from '@/components/CompanySelect'

export default { 
  props: ['model', 'version'],
  components: {
    TableAdvanced,
    CompanySelect
  },
  computed: {
    ...mapState('user', ['user', 'filterCompany']),
    ...mapState('order', ['orders']),
    columns() {
      return [
        { label: 'Model', field: 'model',tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', sortable: false },
        { label: 'FW version', field: 'firmwareVersion', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', hidden: !this.isAdmin, sortable: false, filterOptions: { enabled: true } }, 
        { label: 'Tracker ID', field: 'name', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', sortable: false, filterOptions: { enabled: true } },
        { label: 'Order List', field: 'orderName', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', sortable: false, filterOptions: { enabled: true } },
        { label: 'Truck Pallete', field: 'truckPallete', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', sortable: false, filterOptions: { enabled: true } },
        { 
          label: 'State', 
          field: 'state', 
          tdClass: 'text-center text-nowrap valign-middle', 
          thClass: 'text-center text-nowrap', 
          sortable: false, 
          filterOptions: { enabled: true, placeholder: ' ', filterDropdownItems: ['OnRoute', 'Used', 'Free'] } 
        },
        { label: 'ICCID', field: 'ccid', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', hidden: !this.isAdmin, sortable: false, filterOptions: { enabled: true } },
        { label: 'IMEI', field: 'imei', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', hidden: !this.isAdmin, sortable: false, filterOptions: { enabled: true } },
      ]
    },
    isAdmin() {
      return this.user?.role === 'admin'
    },
    filteredTrackers() {
      return this.trackers
        .filter(tracker => tracker.model === this.model)
        .filter(tracker => (tracker.isActive && tracker.orders.length) || !tracker.orders.length)
        .filter(tracker => tracker.firmwareVersion !== this.version && tracker?.fota?.firmwareVersion !== this.version)
    }
  },
  methods: {
    getTrackerStateLabel(tracker) {
      if (tracker.isActive) return 'OnRoute'
      if (tracker.orders.length) return 'Used'
      return 'Free' 
    },
    async setCompanyFilter(value) {
      this.customFilterCompany = value
      await this.refresh()
    },
    async refresh() {
      const { _id: filterCompanyId = null, excludeTestingCompany = false } = this.customFilterCompany
      // Fetch all trackers
      const { data: { docs = [] } } = await getTrackers({ status: 'all', companyId: filterCompanyId, excludeTestingCompany, page: 1, limit: 99999 })
      // Define the order of list
      const sortByStatus = {
        'In-transit': 0,
        'Delivered': 1,
        'Unknown': 2,
      }
      this.trackers = docs.map(tracker => {
        const order = this.orders.find(order => order._id === tracker.orders[0])
        return {
          ...tracker,
          orderName: order?.name || '',
          orderStatus: order?.status || 'Unknown',
          state: this.getTrackerStateLabel(tracker)
        }
      }).sort(
        (a, b) => sortByStatus[a.orderStatus] - sortByStatus[b.orderStatus]
      )
    },
    async update() {
      const { selectedRows = [] } = this.$refs.trackersTable.$refs.tableAdvanced
      if (!selectedRows.length) {
        this.$toast('Please select at least one tracker to proceed', {
          className: ['et-alert']
        })
        return
      }
      const { isConfirmed } = await Swal.fire({
        html: `<span> Are you sure you want to update ${selectedRows.length} trackers to version: </span> <b>${this.version}</b> ?`,
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-success btn-sm mr-2',
          denyButton: 'btn btn-danger btn-sm'
        },
        buttonsStyling: false
      })
      if (isConfirmed) {
        this.$emit('update', selectedRows)
      }
    }
  },
  data() {
    return {
      trackers: [],
      customFilterCompany: null,
    }
  },
  async mounted() {  
    this.customFilterCompany = { ...this.filterCompany }  
    await this.refresh()
  }
}
</script>

<style lang="scss">
</style>