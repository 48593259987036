<template>
  <div class="geofencing-time-table">      
    <table-basic :title="'Geofencing - Time'">
      <template slot="afterTitle">
        <button @click="openEditModal(null)" type="button" class="btn btn-orange ml-2 py-1 px-2">
          <i class="fas fa-plus-circle mr-2"></i> Create
        </button>
      </template>
      <template v-slot:thead>
        <tr class="cursor-pointer">
          <th>Name</th>
          <th>When trigger</th>
          <th>Update Period (sec)</th>
          <th>Is Active</th>
          <th>Send Emails</th>
          <th>Use GPS</th>
        </tr>
      </template>

      <template v-slot:tbody>
        <tr v-for="geofencing in geofencingTime" :key="geofencing._id">
          <td>{{ geofencing.name }}</td>
          <td>{{ geofencing.trigger | momentZox }}</td>
          <td>{{ geofencing.activeTime }}</td>
          <td>{{ geofencing.isActive }}</td>
          <td>{{ geofencing.sendEmails }}</td>
          <td>{{ geofencing.useGps }}</td>
          <td class="with-btn text-center">
            <b-button variant="warning" @click="openEditModal(geofencing)" size="sm" type="button">EDIT</b-button>
          </td>
          <td class="with-btn text-center">
            <b-button variant="danger" @click="deleteGeofencing(geofencing)" size="sm" type="button">DELETE</b-button>
          </td>
        </tr>
      </template>
    </table-basic>
    <geofencing-time-edit-modal @hidden="currentGeofencing = null" :geofencing="currentGeofencing" @save="updateGeofencing" /> 
  </div>
</template>

<script>
import Swal from 'sweetalert2'

import TableBasic from '@/components/tables/TableBasic.vue'
import { createGeofencingTime, editGeofencingTime, getGeofencingTimeByOrder, deleteGeofencingTime } from '@/services/geofencingTime'
import GeofencingTimeEditModal from '../modals/GeofencingTimeEditModal'

export default {
  props: ['order'],
  data() {
    return {
      geofencingTime: [],
      currentGeofencing: null,
    }
  },
  components: { 
    TableBasic,
    GeofencingTimeEditModal,
  },
  created() {
    this.refresh()
  },
  computed: {
  },
  methods: {
    async refresh() {
      this.geofencingTime = (await getGeofencingTimeByOrder(this.order?._id)).data || []
    },
    openEditModal(geofencing) {
      this.currentGeofencing = geofencing
      this.$bvModal.show('geofencing-time-edit-modal')
    },
    async updateGeofencing({ event, value }) {
      try {
        if (event === 'create') {
          await createGeofencingTime({ ...value, order: this.order._id })
        } else if (event === 'edit') {
          await editGeofencingTime(value)
        }
        this.$toast('Success', {
          className: ['bg-success']
        })
        this.$bvModal.hide('geofencing-time-edit-modal')
        await this.refresh()
      } catch(e) {
        // Get error message from a response
        const { message = null } = e.response?.data
        if (message) {
          this.$toast(message, {
            className: ['et-alert']
          })
        } else {
          this.$toast('Server error', {
            className: ['et-alert']
          })
        }
      }
    },
    async deleteGeofencing({ _id, name }) {
      const { isConfirmed } = await Swal.fire({
        html: `<span> Are you sure you want to delete this geofencing: </span> <b>${name}</b> ?`,
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-success btn-sm mr-2',
          denyButton: 'btn btn-danger btn-sm'
        },
        buttonsStyling: false
      })
      if (isConfirmed) {
        await deleteGeofencingTime(_id)
        this.$toast('Success', {
          className: ['bg-success']
        })
        await this.refresh()
      }
    }
  }
}
</script>

<style lang="scss">
  .geofencing-time-table {
    td {
      &.with-btn {
        width: 70px;
      }
    }
  }
</style>