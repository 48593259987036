<template>
  <div class="login login-v1">
    <div class="login-container">
      <div class="login-header">
        <div class="brand">
          <span>Create new password</span>
        </div>
        <div class="icon">
          <i class="fa fa-lock"></i>
        </div>
      </div>
      <div class="login-body">
        <div class="login-content">
          <form @submit.prevent="handleSubmit" class="margin-bottom-0">
            <div class="form-group m-b-20">
              <input type="password" v-model="password" class="form-control form-control-lg inverse-mode" placeholder="New password" required />
            </div>
            <div class="form-group m-b-20">
              <input type="password" v-model="passwordAgain" class="form-control form-control-lg inverse-mode" placeholder="Confirm password" required />
            </div>
            <div class="row">
              <router-link class="text-decoration-none loginText col-12 float-right mb-2" to="/login">
                <i class="fas fa-arrow-left mr-1"></i> Back to Login
              </router-link>
            </div>
            <div class="login-buttons">
              <button type="submit" class="btn btn-yellow btn-block btn-lg">Change</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageOptions from '../config/pageOptions'
import { updatePasswordByToken } from '@/services/users'

export default {
  data() {
    return {
      password: '',
      passwordAgain: ''
    }
  },
  created() {
    pageOptions.pageEmpty = true
  },
  beforeRouteLeave (to, from, next) {
    next()
  },
  methods: {
    handleSubmit: async function(e) {
      e.preventDefault()
      if (this.password !== this.passwordAgain) {
        this.$toast('Passwords are different!', {
          className: ['et-alert']
        })
      } else {
        try {
          await updatePasswordByToken(this.$route.params.token, this.password)
          this.$toast('Password changed successfully.', {
            className: ['bg-success']
          })
        } catch (err) {
          this.$toast(err.response.data, {
            className: ['et-alert']
          })
          return
        }
        this.$router.push({ path: '/' })
      }
    }
  }
}
</script>

<style>
.loginText {
  color: #c6ced5;
}

.loginText:hover {
  color: white;
}
</style>