import api from './api'

export const editGeofencingPlace = async (geofencingPlace) => (
  await api.post(`/geofencingPlace/update/${geofencingPlace._id}`, geofencingPlace)
)

export const createGeofencingPlace = async (geofencingPlace) => (
  await api.post('/geofencingPlace/create', geofencingPlace)
)

export const getGeofencingPlace = async (id) => (
  await api.post(`/geofencingPlace/${id}`)
)

export const getGeofencingPlaceByOrder = async (order) => (
  await api.post(`/geofencingPlaceByOrder/${order}`)
)
export const deleteGeofencingPlace = async (id) => (
  await api.delete(`/geofencingPlace/delete/${id}`)
)