import Vue from 'vue'
import { 
  SET_ORDERS, SET_ORDER, SET_ORDER_DETAILS, SET_ORDER_ALERTS,
  SET_ORDER_TEMPLATE, SET_GEOFENCING_PLACES
} from './constants'

export default {
  [SET_ORDERS](state, items) {
    Vue.set(state, 'orders', items)
  },
  [SET_ORDER](state, item) {
    Vue.set(state, 'order', item)
  },
  [SET_ORDER_DETAILS](state, item) {
    Vue.set(state, 'orderDetails', item)
  },
  [SET_ORDER_ALERTS](state, item) {
    Vue.set(state, 'orderAlerts', item)
  },
  [SET_ORDER_TEMPLATE](state, item) {
    Vue.set(state, 'orderTemplate', item)
  },
  [SET_GEOFENCING_PLACES](state, items) {
    Vue.set(state, 'geofencingPlaces', items)
  },
}