<template>
  <div class="fade page-sidebar-fixed page-header-fixed show page-container" v-if="!pageOptions.pageEmpty" :class="{ 
    'page-sidebar-minified': pageOptions.pageSidebarMinified, 
    'page-content-full-height': pageOptions.pageContentFullHeight, 
    'page-without-sidebar': pageOptions.pageWithoutSidebar, 
    'page-with-wide-sidebar': pageOptions.pageWithWideSidebar,
    'page-with-light-sidebar': pageOptions.pageWithLightSidebar,
    'page-sidebar-toggled': pageOptions.pageMobileSidebarToggled,
    'page-right-sidebar-toggled': pageOptions.pageMobileRightSidebarToggled || pageOptions.pageRightSidebarToggled,
    'page-right-sidebar-collapsed': pageOptions.pageRightSidebarCollapsed,
    'has-scroll': pageOptions.pageBodyScrollTop
  }">
    <vue-ins-progress-bar></vue-ins-progress-bar>
    <Header />
    <Sidebar 
      v-if="!pageOptions.pageWithoutSidebar" 
      @hideSidebar="pageOptions.pageWithoutSidebar = true"
    />
    <div v-if="inited" id="content" class="content pr-2 mr-2 pb-xl-2" :class="{ 'content-full-width': pageOptions.pageContentFullWidth, 'content-inverse-mode': pageOptions.pageContentInverseMode }">
      <router-view></router-view>
    </div>
    <Footer v-if="pageOptions.pageWithFooter" />
  </div>
  <div v-else>
    <router-view></router-view>
    <vue-ins-progress-bar></vue-ins-progress-bar>
  </div>
</template>

<script>
import Sidebar from './components/sidebar/Sidebar.vue'
import Header from './components/header/Header.vue'
import Footer from './components/footer/Footer.vue'
import pageOptions from './config/pageOptions'

import { mapState, mapMutations, mapActions } from 'vuex'
import { FETCH_COMPANIES } from '@/store/main/constants'
import { FETCH_ORDERS } from '@/store/order/constants'
import { SET_FILTER_COMPANY } from '@/store/user/constants'

export default {
  name: 'app',
  components: {
    Sidebar,
    Header,
    Footer
  },
  data() {
    return {
      pageOptions: pageOptions,
      inited: false,
    }
  },
  computed: {
    ...mapState('user', ['user', 'loggedIn'])
  },
  methods: {
    ...mapMutations('user', {
      setFilterCompany: SET_FILTER_COMPANY
    }),
    ...mapActions('main', {
      fetchCompanies: FETCH_COMPANIES
    }),
    ...mapActions('order', {
      fetchOrders: FETCH_ORDERS,
    }),
    handleScroll: function() {
      pageOptions.pageBodyScrollTop = window.scrollY
    }
  },
  async mounted () {
    try {
      if (this.loggedIn) {
        // Get filter company from LS
        const cachedFilterCompany = JSON.parse(localStorage.getItem(this.user?.id))
        if (cachedFilterCompany) this.setFilterCompany(cachedFilterCompany)
        await this.fetchOrders()
        await this.fetchCompanies()
      }
    } finally {
      this.inited = true 
      this.$insProgress.finish()
    }
  },
  created() {
    // init 
    pageOptions.pageBodyScrollTop = window.scrollY
		
    window.addEventListener('scroll', this.handleScroll)
		
    this.$insProgress.start()
		
    this.$router.beforeEach((to, from, next) => {
      this.$insProgress.start()
      pageOptions.pageWithoutSidebar = false
      next()
    })
    this.$router.afterEach(() => {
      this.$insProgress.finish()
    })
  }
}
</script>
