<template>
  <div class="row justify-content-md-center">
    <div class="col-12">
      <TableAdvanced 
        ref="logsTable" 
        title="System Logs"
        mode="remote" 
        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        :columns="columns" 
        :rows="logs"
        :paginationOptions="{ enabled: true, perPageDropdown: [10, 50, 100, 500, 1000] }"
        :searchOptions="{ 
          enabled: false,
        }"
        :select-options="{ enabled: true, selectOnCheckboxOnly: true }"
        :isLoading="isLoading"
        :totalRows="totalRecords"
      >
        <template #rowActions="{ props }">
          <span v-if="props.column.field == 'rollbarUrl'">
            <a v-if="props.row.rollbarUrl" :href="props.row.rollbarUrl">Detail</a>
          </span>
        </template>
        <template #selectedRowActions>
          <button type="button" @click="deleteSelectedLogs" class="btn btn-sm btn-danger">DELETE</button>
        </template>
      </TableAdvanced>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { USER_DATETIME_FORMAT } from '@/store/user/constants'
import { getLogs, deleteLogs } from '@/services/logs'
import TableAdvanced from '@/components/tables/TableAdvanced.vue'

export default {
  data() {
    return {
      isLoading: false,
      logs: [],
      totalRecords: 0,
      serverParams: {
        page: 1,
        limit: 10,
        columnFilters: {},
        // initial sort 
        sort: [{
          field: 'timestamp',
          type: 'desc'
        }]
      }
    }
  },
  components: {
    TableAdvanced
  },
  async created() {
    await this.loadItems()
  },
  computed: {
    ...mapGetters('user', [USER_DATETIME_FORMAT]),
    columns() {
      return [
        { 
          label: 'Severity', 
          field: 'severity', 
          filterOptions: { 
            enabled: true,
            filterDropdownItems: ['Debug', 'Warning', 'Error']
          }, 
          tdClass: 'text-center text-nowrap valign-middle', 
          thClass: 'text-center text-nowrap',
          width: '200px' 
        },
        { 
          label: 'Source',
          field: 'source', 
          filterOptions: { 
            enabled: true, 
            filterDropdownItems: ['Server', 'Web', 'Mobile Client']
          }, 
          tdClass: 'text-center text-nowrap valign-middle', 
          thClass: 'text-center text-nowrap',
          width: '200px' 
        },
        { label: 'Device', field: 'deviceId',tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', width: '100px' },
        { label: 'Time', field: 'timestamp', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap' },
        { label: 'Rollbar', field: 'rollbarUrl', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap' },
        { 
          label: 'Entry', 
          field: 'entry', 
          filterOptions: { 
            enabled: true,
          }, 
          tdClass: 'text-nowrap valign-middle', 
          thClass: 'text-nowrap', 
          width: '200px', 
          sortable: false 
        },
      ]
    },
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.loadItems()
    },

    onPerPageChange(params) {
      this.updateParams({ limit: params.currentPerPage })
      this.loadItems()
    },

    onSortChange(params) {
      this.updateParams({
        sort: params.map(param => ({
          type: param.type,
          field: param.field,
        }))
      })
      this.loadItems()
    },
    
    onColumnFilter(params) {
      this.updateParams(params)
      this.loadItems()
    },

    async deleteSelectedLogs() {
      const { selectedRows = [] } = this.$refs.logsTable.$refs.tableAdvanced
      try {
        await deleteLogs({ logIds: selectedRows.map(row => row._id) })
        await this.loadItems()
        this.$toast('Successfully deleted', {
          className: ['bg-success']
        })
      } catch (e) {
        this.$toast('Unable to delete logs', {
          className: ['bg-danger']
        })
      }
    },

    async loadItems() {
      this.isLoading = true
      const { data: { docs = [], totalDocs = 0 } } = await getLogs(this.serverParams)
      this.logs = docs.map(doc => {
        return {
          ...doc, 
          timestamp: moment(doc.timestamp).format(this.USER_DATETIME_FORMAT)
        }
      })
      this.totalRecords = totalDocs
      this.isLoading = false
    }
  }
}
</script>

<style>

</style>