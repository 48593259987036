 <style>
 .gm-style .gm-style-iw-a {
  background-color: transparent;
 }
 .iw-container {
  background-color: transparent;
 }
.gm-style .gm-style-iw-c {
  background-color: transparent;
  box-shadow: none;
  font-weight: bold;
  color: white;
  padding: 0;
  transform: translate(0px,-100%);
  border-radius: 6px;
}
.gm-style .gm-style-iw-tc {
  overflow: hidden !important;
  height: 0;
}
.gm-style .gm-style-iw-d {
  padding: 0 !important;
  display: inline;
}
.gm-style .gm-style-iw-d a {
  color: white;
  padding: 0.8rem 0.8rem 0.8rem 0.4rem;
  display: block;
}
.gm-style .gm-style-iw-d a:after {
  content: '';
  width: 100%;
  height: 100%;
  background: inherit; 
  position: absolute;
  z-index: -1;
  opacity: 1;
  left: 0px;
  right: 0;
  top: 0px;  
  bottom: 0;
  backdrop-filter: contrast(130%) brightness(120%) blur(20px);
  -webkit-backdrop-filter: contrast(130%) brightness(120%) blur(20px);
  background:
    radial-gradient(
      ellipse at 16.7% -10%,
      hsla(0, 0%, 100%, 0.3) 24%,
      hsla(0, 0%, 100%, 0.25) 25%,
      hsla(0, 0%, 100%, 0.15) 45%,
      hsla(0, 0%, 100%, 0.1)
    );
  background-size: 300% 100%;
  border-radius: 10px;
  box-shadow:
    0 2px 1px hsla(0, 0%, 100%, 0.5) inset,
    0 -2px 1px hsla(250, 70%, 5%, 0.3) inset,
    0 -2px 6px hsla(0, 0%, 100%, 0.25);
}
/* slightly transparent fallback for Firefox (and any other browser not supporting backdrop-filter) */
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .gm-style .gm-style-iw-d a:after {

    background-color: rgba(130,130,130,0.9); 
    filter: blur(20px);
  }
}
.gm-style .gm-style-iw-t::after {
  background: transparent;
  box-shadow: none;
  width: 37px;
  height: 37px;
}
.gm-ui-hover-effect {
  display: none !important;
}

</style>
<template>
  <div class="dashboard">
    <div class="row mt-3">
      <div class="col-lg-5">
        <div class="row">
          <div class="col-12 card orders border-0 bg-dark text-white">
            <div class="card-body">   
              <div class="d-flex justify-content-between mb-2">
                <div class="text-grey">
                  <h1 class="d-inline-block mb-0 ml-1">ACTIVE ORDER LIST</h1>
                  <b-badge class="orders__count-bage ml-2" variant="light">{{ activeOrders && activeOrders.length }}</b-badge>
                </div>
                <div class="orders__button-panel">
                  <span @click="prev" v-if="ordersPage !== 1" class="fas fa-lg fa-arrow-left mr-1"></span>
                  <span @click="next" v-if="ordersPage < totalPages" class="fas fa-lg fa-arrow-right ml-1"></span>
                </div>
              </div>
              <ul class="orders__list">
                <li class="orders__list-header">
                  <span>Name</span>
                  <span>City</span>
                  <span>Country</span>
                  <span>TR1</span>
                  <span>TR2</span>
                </li>
                <li class="orders__item" :key="order._id" v-for="order in displayedOrders" @click="$router.push(`orders/${order._id}`)">
                  <span :class="getActiveOrderClass(order)">{{ order.name }}</span>
                  <span class="text-grey">{{ order.finalDestination && order.finalDestination.city }}</span>
                  <span class="text-grey">{{ order.finalDestination && order.finalDestination.country }}</span>
                  <span class="text-grey">{{ getTrackersCountLabel(order.tr1TrackersCount) }}</span>
                  <span class="text-grey py-2 my-1">{{ getTrackersCountLabel(order.tr2TrackersCount) }}</span>
                </li>
              </ul>             
            </div>
          </div>
        </div>
        <div class="row">
          <router-link to="/trackers/active" class="col-md-6 mr-1 card border-0 bg-dark text-white text-truncate text-decoration-none">
            <div class="card-body">
              <div class="text-grey">
                <h1 class="mb-4">ACTIVE TRACKERS</h1>
              </div>
              <div class="d-flex align-items-center row">
                <transition name="fade">
                  <h2 class="text-white mb-0 col">{{ activeTrackersCount }}</h2>
                </transition>
                <svg
                  class="col p-0 active-trackers-icon"
                  viewBox="0 0 245 150"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.4814 105.531C16.8236 105.316 17.1101 105.035 17.3209 104.707C17.5317 104.379 17.6616 104.012 17.7015 103.633C17.7413 103.254 17.6901 102.872 17.5515 102.513C17.4129 102.154 17.1903 101.827 16.8993 101.556L18 79L12.396 79.5774L12.7216 101.64C12.2573 102.119 12.0005 102.738 12 103.379C11.9995 104.02 12.2551 104.639 12.7186 105.119C13.182 105.598 13.8211 105.905 14.5146 105.981C15.2081 106.058 15.9079 105.897 16.4814 105.531Z"
                    fill="#FFB8B8"
                  />
                  <path
                    d="M26 143.477L29.0176 144L32 132.772L28.0462 132L26 143.477Z"
                    fill="#FFB8B8"
                  />
                  <path
                    d="M33.8828 147.84L24.8145 146.247L25.4305 142.746L30.9951 143.723C31.4552 143.804 31.8949 143.974 32.289 144.225C32.6831 144.476 33.024 144.801 33.2922 145.184C33.5603 145.566 33.7505 145.997 33.8518 146.453C33.9532 146.909 33.9637 147.38 33.8828 147.84Z"
                    fill="#2F2E41"
                  />
                  <path
                    d="M18.0004 145L20.7107 145L22 134L18 134L18.0004 145Z"
                    fill="#FFB8B8"
                  />
                  <path
                    d="M26.5637 147.839L17.3564 147.84L17.3562 144.285L23.0062 144.285C23.9497 144.285 24.8545 144.659 25.5217 145.326C26.1888 145.992 26.5637 146.897 26.5637 147.839L26.5637 147.839Z"
                    fill="#2F2E41"
                  />
                  <path
                    d="M30.0961 142.483C30.053 142.483 30.0099 142.48 29.9671 142.475L26.7138 142.195C26.5657 142.177 26.4227 142.128 26.2934 142.05C26.1642 141.972 26.0514 141.868 25.9619 141.743C25.8725 141.619 25.8082 141.477 25.773 141.325C25.7379 141.174 25.7325 141.017 25.7573 140.863L28.7614 123.289L26.7315 112.116C26.715 112.026 26.6676 111.944 26.598 111.887C26.5284 111.83 26.4414 111.801 26.353 111.806C26.2646 111.81 26.1808 111.848 26.1169 111.912C26.0531 111.976 26.0137 112.062 26.0058 112.154L23.4682 141.954C23.4403 142.253 23.3023 142.529 23.0832 142.723C22.864 142.918 22.581 143.017 22.2937 142.998L19.2288 142.879C18.9602 142.859 18.7078 142.737 18.5194 142.536C18.331 142.335 18.2195 142.069 18.206 141.789L18 106.073L33.89 104L35 121.895L34.9956 121.914L31.165 141.605C31.1067 141.855 30.9696 142.077 30.7758 142.236C30.582 142.396 30.3427 142.482 30.0961 142.483Z"
                    fill="#B6C2C9"
                  />
                  <path
                    d="M23 66C26.3137 66 29 63.3137 29 60C29 56.6863 26.3137 54 23 54C19.6863 54 17 56.6863 17 60C17 63.3137 19.6863 66 23 66Z"
                    fill="#FFB8B8"
                  />
                  <path
                    d="M28.5539 108C27.6621 108.006 26.7873 107.742 26.0332 107.239C23.271 105.417 20.1336 106.141 18.5092 106.735C18.348 106.795 18.1762 106.815 18.0065 106.795C17.8368 106.774 17.6737 106.714 17.5293 106.617C17.3866 106.523 17.266 106.395 17.1766 106.245C17.0871 106.094 17.0312 105.924 17.0129 105.747L14.059 77.9113C13.564 73.2475 16.2266 68.8626 20.3899 67.485C20.5463 67.4333 20.7053 67.3842 20.867 67.3378C22.1626 66.9671 23.5194 66.9006 24.842 67.1428C26.1646 67.385 27.4208 67.9301 28.5223 68.7397C29.6442 69.5582 30.5825 70.6263 31.2711 71.8686C31.9597 73.111 32.3819 74.4975 32.5079 75.9306L34.9946 103.953C35.0116 104.135 34.9884 104.318 34.9268 104.488C34.8653 104.658 34.7671 104.811 34.6401 104.935C33.7684 105.786 31.2383 108 28.5539 108Z"
                    fill="#1AACAB"
                  />
                  <path
                    d="M19.4199 84.9915L12.2687 84.2059C12.0653 84.1835 11.869 84.1176 11.6933 84.0126C11.5177 83.9077 11.3667 83.7661 11.2507 83.5976C11.1347 83.429 11.0565 83.2375 11.0212 83.0361C10.986 82.8346 10.9947 82.6279 11.0466 82.4301L12.8668 75.4983C12.9872 74.4588 13.5152 73.5095 14.3351 72.8584C15.155 72.2073 16.1998 71.9076 17.2405 72.025C18.2812 72.1424 19.2328 72.6673 19.8867 73.4847C20.5406 74.3021 20.8434 75.3452 20.7288 76.3853L20.999 83.5234C21.0067 83.7278 20.9702 83.9314 20.892 84.1204C20.8138 84.3094 20.6957 84.4794 20.5459 84.6187C20.396 84.758 20.2179 84.8634 20.0236 84.9278C19.8293 84.9921 19.6234 85.0138 19.4199 84.9915V84.9915Z"
                    fill="#1AACAB"
                  />
                  <path
                    d="M35.2005 105.34C35.4591 105.09 35.6646 104.781 35.8026 104.434C35.9406 104.087 36.0078 103.711 35.9993 103.333C35.9908 102.955 35.9069 102.583 35.7535 102.244C35.6001 101.905 35.381 101.607 35.1115 101.372L33.56 79L29 80.1276L31.6767 101.914C31.3462 102.438 31.202 103.076 31.2715 103.709C31.3409 104.342 31.6192 104.924 32.0535 105.346C32.4879 105.769 33.0481 106.001 33.6281 106C34.2081 105.999 34.7676 105.764 35.2005 105.34V105.34Z"
                    fill="#FFB8B8"
                  />
                  <path
                    d="M26.4541 83.6186C26.3042 83.4793 26.1861 83.3094 26.1079 83.1204C26.0297 82.9313 25.9933 82.7277 26.001 82.5233L26.2712 75.3853C26.1566 74.3452 26.4594 73.3021 27.1133 72.4847C27.7672 71.6673 28.7189 71.1424 29.7596 71.025C30.8002 70.9076 31.8451 71.2073 32.6649 71.8584C33.4848 72.5095 34.0128 73.4588 34.1332 74.4983L35.9534 81.43C36.0053 81.6278 36.014 81.8345 35.9788 82.036C35.9435 82.2374 35.8653 82.429 35.7493 82.5975C35.6333 82.766 35.4823 82.9076 35.3067 83.0126C35.131 83.1175 34.9347 83.1834 34.7313 83.2058L27.5801 83.9914C27.3766 84.0139 27.1707 83.9922 26.9764 83.9278C26.782 83.8635 26.6039 83.758 26.4541 83.6186Z"
                    fill="#1AACAB"
                  />
                  <path
                    d="M22.5883 66C22.4899 65.9999 22.3918 65.9877 22.296 65.9636L22.2679 65.9563C17.4 65.1612 16.3242 62.1511 16.088 60.894C15.8436 59.5925 16.1219 58.3362 16.7508 57.8483C16.408 56.6925 16.463 55.6677 16.9148 54.8011C17.7027 53.2897 19.4127 52.7787 19.6421 52.7162C21.0077 51.6406 22.6417 52.3586 22.939 52.5042C25.5806 51.4608 26.5903 52.3293 26.7729 52.5232C27.9537 52.7496 28.6735 53.2366 28.9124 53.9709C29.3612 55.3501 27.9419 57.0657 27.8812 57.1381L27.8497 57.1756L25.7352 57.2831C25.5346 57.2933 25.3382 57.3485 25.1588 57.4449C24.9794 57.5414 24.821 57.677 24.6939 57.8431C24.5667 58.0091 24.4737 58.2018 24.4208 58.4087C24.368 58.6156 24.3564 58.832 24.3869 59.044C24.4442 59.3175 24.5165 59.5871 24.6034 59.8515C24.9646 61.0563 25.2351 62.0856 24.8862 62.4769C24.8071 62.5526 24.7097 62.603 24.605 62.6224C24.5003 62.6418 24.3927 62.6293 24.2944 62.5864C23.9638 62.4921 23.7393 62.5119 23.6274 62.6453C23.4538 62.8521 23.5068 63.3756 23.7767 64.1194C23.8614 64.3546 23.8842 64.61 23.8426 64.8581C23.8011 65.1061 23.6967 65.3373 23.5409 65.5266C23.4236 65.6743 23.2777 65.7931 23.1133 65.8748C22.9488 65.9566 22.7697 65.9993 22.5883 66Z"
                    fill="#E6E6E6"
                  />
                  <path
                    d="M92.8323 85H63.1677C62.0628 84.9988 61.0034 84.5669 60.2221 83.799C59.4407 83.0312 59.0012 81.9901 59 80.9042V37.0958C59.0012 36.0099 59.4407 34.9688 60.2221 34.201C61.0034 33.4331 62.0628 33.0012 63.1677 33H92.8323C93.9372 33.0012 94.9966 33.4331 95.7779 34.201C96.5593 34.9688 96.9988 36.0099 97 37.0958V80.9042C96.9988 81.9901 96.5593 83.0312 95.7779 83.799C94.9966 84.5669 93.9372 84.9988 92.8323 85ZM63.1677 33.4819C62.1928 33.4829 61.258 33.864 60.5686 34.5416C59.8792 35.2191 59.4914 36.1376 59.4903 37.0958V80.9042C59.4914 81.8624 59.8792 82.7809 60.5686 83.4584C61.258 84.136 62.1928 84.5171 63.1677 84.5181H92.8323C93.8072 84.5171 94.742 84.136 95.4314 83.4584C96.1208 82.7809 96.5086 81.8624 96.5097 80.9042V37.0958C96.5086 36.1376 96.1208 35.2191 95.4314 34.5416C94.742 33.864 93.8072 33.4829 92.8323 33.4819H63.1677Z"
                    fill="#B6C2C9"
                  />
                  <path
                    d="M87.9802 54H68.0198C67.4843 53.9994 66.9709 53.7792 66.5922 53.3878C66.2136 52.9964 66.0006 52.4658 66 51.9123V42.0877C66.0006 41.5342 66.2136 41.0036 66.5922 40.6122C66.9709 40.2208 67.4843 40.0006 68.0198 40H87.9802C88.5157 40.0006 89.0291 40.2208 89.4077 40.6122C89.7864 41.0036 89.9994 41.5342 90 42.0877V51.9123C89.9994 52.4658 89.7864 52.9964 89.4077 53.3878C89.0291 53.7792 88.5157 53.9994 87.9802 54Z"
                    fill="#1AACAB"
                  />
                  <path
                    d="M86.0909 62H68.9091C68.4028 62 67.9172 61.842 67.5592 61.5607C67.2011 61.2794 67 60.8978 67 60.5C67 60.1022 67.2011 59.7206 67.5592 59.4393C67.9172 59.158 68.4028 59 68.9091 59H86.0909C86.5972 59 87.0828 59.158 87.4408 59.4393C87.7989 59.7206 88 60.1022 88 60.5C88 60.8978 87.7989 61.2794 87.4408 61.5607C87.0828 61.842 86.5972 62 86.0909 62Z"
                    fill="#CCCCCC"
                  />
                  <path
                    d="M86.0909 70H68.9091C68.4028 70 67.9172 69.842 67.5592 69.5607C67.2011 69.2794 67 68.8978 67 68.5C67 68.1022 67.2011 67.7206 67.5592 67.4393C67.9172 67.158 68.4028 67 68.9091 67H86.0909C86.5972 67 87.0828 67.158 87.4408 67.4393C87.7989 67.7206 88 68.1022 88 68.5C88 68.8978 87.7989 69.2794 87.4408 69.5607C87.0828 69.842 86.5972 70 86.0909 70Z"
                    fill="#CCCCCC"
                  />
                  <path
                    d="M86.0909 77H68.9091C68.4028 77 67.9172 76.7893 67.5592 76.4142C67.2011 76.0391 67 75.5304 67 75C67 74.4696 67.2011 73.9609 67.5592 73.5858C67.9172 73.2107 68.4028 73 68.9091 73H86.0909C86.5972 73 87.0828 73.2107 87.4408 73.5858C87.7989 73.9609 88 74.4696 88 75C88 75.5304 87.7989 76.0391 87.4408 76.4142C87.0828 76.7893 86.5972 77 86.0909 77Z"
                    fill="#CCCCCC"
                  />
                  <path
                    d="M162.942 109H134.058C132.982 108.999 131.951 108.567 131.19 107.799C130.429 107.031 130.001 105.99 130 104.904V61.0958C130.001 60.0099 130.429 58.9688 131.19 58.201C131.951 57.4331 132.982 57.0012 134.058 57H162.942C164.018 57.0012 165.049 57.4331 165.81 58.201C166.571 58.9688 166.999 60.0099 167 61.0958V104.904C166.999 105.99 166.571 107.031 165.81 107.799C165.049 108.567 164.018 108.999 162.942 109ZM134.058 57.4819C133.109 57.4829 132.199 57.864 131.527 58.5416C130.856 59.2191 130.478 60.1376 130.477 61.0958V104.904C130.478 105.862 130.856 106.781 131.527 107.458C132.199 108.136 133.109 108.517 134.058 108.518H162.942C163.891 108.517 164.801 108.136 165.473 107.458C166.144 106.781 166.522 105.862 166.523 104.904V61.0958C166.522 60.1376 166.144 59.2191 165.473 58.5416C164.801 57.864 163.891 57.4829 162.942 57.4819H134.058Z"
                    fill="#B6C2C9"
                  />
                  <path
                    d="M157 68H139C138.47 68 137.961 67.7893 137.586 67.4142C137.211 67.0391 137 66.5304 137 66C137 65.4696 137.211 64.9609 137.586 64.5858C137.961 64.2107 138.47 64 139 64H157C157.53 64 158.039 64.2107 158.414 64.5858C158.789 64.9609 159 65.4696 159 66C159 66.5304 158.789 67.0391 158.414 67.4142C158.039 67.7893 157.53 68 157 68Z"
                    fill="#CCCCCC"
                  />
                  <path
                    d="M157 75H139C138.47 75 137.961 74.842 137.586 74.5607C137.211 74.2794 137 73.8978 137 73.5C137 73.1022 137.211 72.7206 137.586 72.4393C137.961 72.158 138.47 72 139 72H157C157.53 72 158.039 72.158 158.414 72.4393C158.789 72.7206 159 73.1022 159 73.5C159 73.8978 158.789 74.2794 158.414 74.5607C158.039 74.842 157.53 75 157 75Z"
                    fill="#CCCCCC"
                  />
                  <path
                    d="M157.98 93H138.02C137.484 92.9993 136.971 92.7792 136.592 92.3878C136.214 91.9964 136.001 91.4658 136 90.9123V81.0877C136.001 80.5342 136.214 80.0036 136.592 79.6122C136.971 79.2208 137.484 79.0007 138.02 79H157.98C158.516 79.0007 159.029 79.2208 159.408 79.6122C159.786 80.0036 159.999 80.5342 160 81.0877V90.9123C159.999 91.4658 159.786 91.9964 159.408 92.3878C159.029 92.7792 158.516 92.9993 157.98 93Z"
                    fill="#E6E6E6"
                  />
                  <path
                    d="M157 102H139C138.47 102 137.961 101.789 137.586 101.414C137.211 101.039 137 100.53 137 100C137 99.4696 137.211 98.9609 137.586 98.5858C137.961 98.2107 138.47 98 139 98H157C157.53 98 158.039 98.2107 158.414 98.5858C158.789 98.9609 159 99.4696 159 100C159 100.53 158.789 101.039 158.414 101.414C158.039 101.789 157.53 102 157 102Z"
                    fill="#CCCCCC"
                  />
                  <path
                    d="M144.942 40H116.058C114.982 39.9988 113.951 39.5592 113.19 38.7778C112.429 37.9964 112.001 36.937 112 35.832V8.16804C112.001 7.06299 112.429 6.00355 113.19 5.22216C113.951 4.44077 114.982 4.00124 116.058 4H144.942C146.018 4.00124 147.049 4.44077 147.81 5.22216C148.571 6.00355 148.999 7.06299 149 8.16804V35.832C148.999 36.937 148.571 37.9964 147.81 38.7778C147.049 39.5592 146.018 39.9988 144.942 40ZM116.058 4.49036C115.109 4.49146 114.199 4.87929 113.527 5.56875C112.856 6.25821 112.478 7.193 112.477 8.16804V35.832C112.478 36.807 112.856 37.7418 113.527 38.4313C114.199 39.1207 115.109 39.5085 116.058 39.5096H144.942C145.891 39.5085 146.801 39.1207 147.473 38.4313C148.144 37.7418 148.522 36.807 148.523 35.832V8.16804C148.522 7.193 148.144 6.25821 147.473 5.56875C146.801 4.87929 145.891 4.49146 144.942 4.49036H116.058Z"
                    fill="#B6C2C9"
                  />
                  <path
                    d="M139.091 31H121.909C121.403 31 120.917 30.842 120.559 30.5607C120.201 30.2794 120 29.8978 120 29.5C120 29.1022 120.201 28.7206 120.559 28.4393C120.917 28.158 121.403 28 121.909 28H139.091C139.597 28 140.083 28.158 140.441 28.4393C140.799 28.7206 141 29.1022 141 29.5C141 29.8978 140.799 30.2794 140.441 30.5607C140.083 30.842 139.597 31 139.091 31Z"
                    fill="#CCCCCC"
                  />
                  <path
                    d="M139.091 24H121.909C121.403 24 120.917 23.842 120.559 23.5607C120.201 23.2794 120 22.8978 120 22.5C120 22.1022 120.201 21.7206 120.559 21.4393C120.917 21.158 121.403 21 121.909 21H139.091C139.597 21 140.083 21.158 140.441 21.4393C140.799 21.7206 141 22.1022 141 22.5C141 22.8978 140.799 23.2794 140.441 23.5607C140.083 23.842 139.597 24 139.091 24Z"
                    fill="#CCCCCC"
                  />
                  <path
                    d="M139.091 17H121.909C121.403 17 120.917 16.7893 120.559 16.4142C120.201 16.0391 120 15.5304 120 15C120 14.4696 120.201 13.9609 120.559 13.5858C120.917 13.2107 121.403 13 121.909 13H139.091C139.597 13 140.083 13.2107 140.441 13.5858C140.799 13.9609 141 14.4696 141 15C141 15.5304 140.799 16.0391 140.441 16.4142C140.083 16.7893 139.597 17 139.091 17Z"
                    fill="#CCCCCC"
                  />
                  <path
                    d="M93.5 40C96.5376 40 99 37.5376 99 34.5C99 31.4624 96.5376 29 93.5 29C90.4624 29 88 31.4624 88 34.5C88 37.5376 90.4624 40 93.5 40Z"
                    fill="#1AACAB"
                  />
                  <path
                    d="M93.5666 36C93.4629 36.0002 93.3619 35.962 93.2789 35.891L93.2737 35.8866L92.19 34.9402C92.1396 34.8965 92.0972 34.8418 92.0653 34.7793C92.0334 34.7169 92.0127 34.6478 92.0043 34.5762C91.9958 34.5046 91.9999 34.4317 92.0162 34.3619C92.0325 34.2921 92.0607 34.2266 92.0993 34.1692C92.1378 34.1118 92.1859 34.0637 92.2408 34.0276C92.2957 33.9914 92.3563 33.968 92.4191 33.9587C92.482 33.9493 92.5458 33.9542 92.607 33.973C92.6682 33.9919 92.7255 34.0243 92.7757 34.0685L93.4776 34.6823L95.1364 32.215C95.1749 32.1578 95.2229 32.1097 95.2776 32.0737C95.3323 32.0376 95.3928 32.0142 95.4555 32.0047C95.5181 31.9953 95.5819 32 95.6429 32.0187C95.704 32.0373 95.7613 32.0695 95.8114 32.1134L95.8011 32.1293L95.8117 32.1136C95.9129 32.2023 95.9791 32.3332 95.9959 32.4775C96.0126 32.6219 95.9784 32.7679 95.9008 32.8835L93.9497 35.7857C93.9046 35.8526 93.8465 35.9067 93.7801 35.9439C93.7137 35.981 93.6406 36.0002 93.5666 36Z"
                    fill="white"
                  />
                  <path
                    d="M165.5 64C168.538 64 171 61.7614 171 59C171 56.2386 168.538 54 165.5 54C162.462 54 160 56.2386 160 59C160 61.7614 162.462 64 165.5 64Z"
                    fill="#C4C4C4"
                  />
                  <path
                    d="M164.958 61C164.829 61.0002 164.702 60.962 164.599 60.891L164.592 60.8866L163.238 59.9402C163.174 59.8965 163.121 59.8418 163.082 59.7793C163.042 59.7169 163.016 59.6478 163.005 59.5762C162.995 59.5046 163 59.4317 163.02 59.3619C163.041 59.2921 163.076 59.2266 163.124 59.1692C163.172 59.1118 163.232 59.0637 163.301 59.0276C163.37 58.9914 163.445 58.968 163.524 58.9587C163.602 58.9493 163.682 58.9542 163.759 58.973C163.835 58.9919 163.907 59.0243 163.97 59.0685L164.847 59.6823L166.921 57.215C166.969 57.1578 167.029 57.1097 167.097 57.0737C167.165 57.0376 167.241 57.0142 167.319 57.0047C167.398 56.9953 167.477 57 167.554 57.0187C167.63 57.0373 167.702 57.0695 167.764 57.1134L167.751 57.1293L167.765 57.1136C167.891 57.2023 167.974 57.3332 167.995 57.4775C168.016 57.6219 167.973 57.7679 167.876 57.8835L165.437 60.7857C165.381 60.8526 165.308 60.9067 165.225 60.9439C165.142 60.981 165.051 61.0002 164.958 61Z"
                    fill="white"
                  />
                  <path
                    d="M148.5 11C151.538 11 154 8.53757 154 5.5C154 2.46243 151.538 0 148.5 0C145.462 0 143 2.46243 143 5.5C143 8.53757 145.462 11 148.5 11Z"
                    fill="#C4C4C4"
                  />
                  <path
                    d="M146.958 8C146.829 8.00028 146.702 7.95245 146.599 7.86373L146.592 7.85821L145.238 6.6753C145.174 6.62061 145.121 6.55226 145.082 6.47417C145.042 6.39608 145.016 6.30979 145.005 6.22025C144.995 6.1307 145 6.03967 145.02 5.95236C145.041 5.86506 145.076 5.7832 145.124 5.71149C145.172 5.63977 145.232 5.57961 145.301 5.53445C145.37 5.48929 145.445 5.46002 145.524 5.44832C145.602 5.43663 145.682 5.44273 145.759 5.46628C145.835 5.48983 145.907 5.53037 145.97 5.58557L146.847 6.35293L148.921 3.26875C148.969 3.19721 149.029 3.13718 149.097 3.09208C149.165 3.04698 149.241 3.0177 149.319 3.0059C149.398 2.99411 149.477 3.00003 149.554 3.02333C149.63 3.04663 149.702 3.08686 149.764 3.14171L149.751 3.16167L149.765 3.142C149.891 3.2529 149.974 3.4165 149.995 3.59692C150.016 3.77734 149.973 3.95984 149.876 4.10438L147.437 7.73211C147.381 7.81571 147.308 7.88336 147.225 7.92982C147.142 7.97627 147.051 8.00029 146.958 8Z"
                    fill="white"
                  />
                  <path
                    d="M91.7598 150H0.240209C0.176502 150 0.115403 149.947 0.0703554 149.854C0.0253075 149.76 0 149.633 0 149.5C0 149.367 0.0253075 149.24 0.0703554 149.146C0.115403 149.053 0.176502 149 0.240209 149H91.7598C91.8235 149 91.8846 149.053 91.9297 149.146C91.9747 149.24 92 149.367 92 149.5C92 149.633 91.9747 149.76 91.9297 149.854C91.8846 149.947 91.8235 150 91.7598 150Z"
                    fill="#B6C2C9"
                  />
                </svg>
              </div>
            </div>
          </router-link>
          <router-link to="/trackers/all" class="all-trackers card border-0 bg-dark text-white text-truncate text-decoration-none col ml-1">
            <div class="card-body">
              <div class="text-grey">
                <h1 class="mb-4">ALL TRACKERS</h1>
              </div>
              <div class="d-flex align-items-center row">
                <div class="col">
                  <h2 class="text-white mb-0">{{ trackersCount }}</h2>
                </div>
                <div class="col p-0 text-center all-trackers__icon">
                  <img src="/assets/img/cards/all-trackers.svg" alt="">
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <div class="row">
          <div class="col-md-6 countries card bg-dark border-0 text-white mr-1">
            <div class="card-body">
              <div class="mb-3 text-grey">
                <h1 class="d-inline-block mb-0 ml-1">ORDERS BY LOCATION</h1>
                <div class="countries__info-sign d-inline-block ml-2"><i class="fa fa-info-circle" title="Active orders"></i></div>
              </div>
              <div>
                <div 
                  @click="goToOrdersByLocation(countryCode)"
                  :key="index"
                  v-for="{ index, countryName, countryCode, percentage } in ordersByLocation"
                  class="d-flex countries__item align-items-center text-white mb-2"
                >
                  <div :class="`flag-icon flag-icon-${countryCode} mr-2 ml-2 width-25`"></div>
                  <div class="d-flex w-100 mr-2 mb-2 mt-2">
                    <div>{{ countryName }}</div>
                    <div class="ml-auto text-grey">{{ percentage }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <router-link :to="'/alerts'" class="card alerts ml-1 border-0 bg-dark text-white text-truncate col text-decoration-none">
            <div class="card-body">
              <div class="text-grey">
                <h1 class="mb-4">ACTIVE ALERTS</h1>
              </div>
              <div class="d-flex align-items-center row">
                <h2 class="text-white mb-0 col">{{ activeAlertsCount }}</h2>
                <div class="alerts__icon text-center col p-0">
                  <img :src="alertIcon" />
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <div class="col-lg-7 gmap">
        <div class="row pr-0 pl-lg-2 gmap__wrapper">
          <google-map ref="map" panelBodyClass="p-0 h-100" panelTitle="Map of all Active Orders" panelClass="col-12 px-0" mapClass="h-100">
            <template slot="content">
              <GmapMarker @click="$router.push(`/orders/${m.orderId}`)" :key="i" v-for="(m, i) in ordersLastPositionMarkers" :position="m.position" :clickable="true" :draggable="false"></GmapMarker>
              <gmap-info-window :options="m.infoOptions" :key="((i+1)*10000)" v-for="(m, i) in ordersLastPositionMarkers" :position="m.position" :opened="true">
              </gmap-info-window>
            </template>
          </google-map>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { FETCH_TRACKERS_COUNT, FETCH_ACTIVE_ALERTS_COUNT } from '@/store/main/constants'
import { ACTIVE_ORDERS } from '@/store/order/constants'
import GoogleMap from '@/components/map/GoogleMap.vue'

export default {
  data() {
    return {
      loading: false,
      ordersPage: 1,
      ordersPerPage: 5,
    }
  },
  watch: {
    filterCompany: {
      async handler() {
        await this.refresh()
      },
      deep: true
    }
  },
  components: {
    GoogleMap,
  },
  computed: {
    ...mapState('main', ['trackersCount', 'activeTrackersCount', 'freeTrackersCount', 'activeAlertsCount']),
    ...mapGetters('order', {
      activeOrders: ACTIVE_ORDERS,
    }),
    displayedOrders() {
      return this.paginate(this.activeOrders)
    },
    totalPages() {
      return Math.ceil(this.activeOrders.length / this.ordersPerPage)
    },
    ordersLastPositionMarkers() {
      // Filter to get rid of the orders which have lastPosition undefined
      return this.activeOrders.filter(o => o.lastPosition).map(order => {
        return {
          orderId: order?._id,
          position: {
            lat: parseFloat(order?.lastPosition?.latitude),
            lng: parseFloat(order?.lastPosition?.longitude)
          },
          // Create infoOptions for the info boxes
          infoOptions: {
            content: 
              `<a href='${window.location.origin}/#/orders/${order?._id}/'>
                <span class="order-name" style="font-size:1rem;"><i class="icon-user-female"></i>&nbsp;${order?.name}</span><br />
                <span class="order-destination" style="line-height:1.5rem;"><i class="icon-location-pin"></i>&nbsp;${order?.finalDestination?.city !== '' ? order?.finalDestination?.city : '-'}, ${order?.finalDestination?.country !== '' ? order?.finalDestination?.country : '-'}</span><br />
              </a>`, // <span class="order-status">${order?.status}</span>
            pixelOffset: {
              width: 0,
              height: -15
            }
          }
        }
      })
    },
    ordersByLocation() {
      const result = []
      const countries = this.activeOrders.map(order => {
        return { 
          countryName: order?.lastPosition?.country,
          countryCode: order?.lastPosition?.countryCode 
        }
      })
      const uniqueCountries = countries.filter(
        (country, index, array) => array.findIndex(c => c.countryName && c.countryName == country.countryName) == index
      )
      uniqueCountries.forEach(({ countryName, countryCode }, index) => {
        const numItems = countries.filter(country => country.countryName === countryName) 
        const percentage = numItems.length * 100 / countries.length
        result.push({ index: index, countryName, countryCode, percentage: `${percentage.toFixed(2)}%` })
      })
      return result
    },
    alertIcon() {
      if (this.activeAlertsCount <= 5) return '/assets/img/cards/alerts-minor.svg'
      if (this.activeAlertsCount <= 10) return '/assets/img/cards/alerts-major.svg'
      return '/assets/img/cards/alerts-critical.svg'
    },
  },
  async created() {
    await this.refresh()  
  },
  methods: {
    ...mapActions('main', {
      fetchTrackersCount: FETCH_TRACKERS_COUNT,
      fetchActiveAlertsCount: FETCH_ACTIVE_ALERTS_COUNT
    }),
    prev() {
      if (this.ordersPage > 1) this.ordersPage--
    },
    next() {
      this.ordersPage++
    },
    redirectToOrder(id) {
      this.$router.push(`/orders/${id}`)
    },
    paginate() {
      const from = (this.ordersPage * this.ordersPerPage) - this.ordersPerPage
      const to = (this.ordersPage * this.ordersPerPage)
      return this.activeOrders.slice(from, to)
    },
    goToOrdersByLocation(countryCode) {
      this.$router.push({ name: 'orders', params: { countryCode } })
    },
    getTrackersCountLabel(count) {
      if (!count) return 'No trackers'
      if (count === 1) return `${count} tracker`
      return `${count} trackers`
    },
    getActiveOrderClass({ alertsCount }) {
      if (!alertsCount) return ''
      if (alertsCount === 1) return 'text-orange'
      if (alertsCount > 1) return 'text-danger'
    },
    async refresh() {
      this.loading = true
      // fetch trackers
      await this.fetchTrackersCount()
      // fetch alerts
      await this.fetchActiveAlertsCount()
      this.loading = false
    }
  }
}
</script>
<style lang="scss">
.dashboard {
  .page-header {
    font-size: 26px;
  }
  
  .card {
    margin-bottom: 10px !important;
  }

  .orders {
    &__list {
      padding: 0;
      list-style: none;
    }
    &__list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 5px 0 5px;
      font-size: 13px;
      font-weight: 900;
      span {
        flex-basis: 100%;
        &:last-child {
          text-align: right;
        }
      }
    }
    &__item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 5px 0 5px;
      cursor: pointer;
      border-radius: 1em;
      span {
        flex-basis: 100%;
        &:last-child {
          text-align: right;
        }
        &:not(:first-child) {
          font-size: 11px;
        }
      }
      &:hover {
        background-color: #1a2229;
      }
    }
    &__count-bage {
      margin-bottom: 7px;
    }
    &__button-panel {
      span {
        cursor: pointer;
      }
    }
  }

  .all-trackers {
    &__icon {
      position: relative;
      top: -20px;
      img {
        width: 100%;
      }
    }
  }

  .gmap {
    .panel {
      margin-bottom: 0px !important;
    }
    &__wrapper {
      height: 90.5% !important;
      min-height: 500px;

      @media (min-width: 1000px) {
        height: 88.7% !important;
      }

      @media (min-width: 1900px) {
        height: 87.3% !important;
      }
    }
  }

  .countries {
    &__item {
      cursor: pointer;
      border-radius: 1em;
      &:hover {
        background-color: #1a2229 !important;
      }
    }
    &__info-sign {
      vertical-align: text-bottom;
    }
  }

  .alerts {
    &__icon {
      position: relative;
      top: -20px;
      img {
        width: 100%;
      }
    }
  }
}
</style>
