<template>
  <div class="order-details-table">      
    <table-basic :title="'Order Details - ' + order.name">
      <template v-slot:thead>
        <tr class="cursor-pointer">
          <th>Order status</th>
          <th>Origin</th>
          <th>Destination</th>
          <th>{{ columnPrefix }} Transit Time</th>
          <th>{{ columnPrefix }} Parking Time</th>
          <th>{{ columnPrefix }} Loading Time</th>
          <th>Standard Transit Time</th>
          <th>Loading From</th>
          <th>In-Transit From</th>
          <th>Delivered At</th>
          <th>{{ columnPrefix }} Distance Traveled</th>
        </tr>
      </template>

      <template v-slot:tbody>
        <tr v-if="orderDetails">
          <td>{{ orderDetails.status }}</td>
          <td>{{ orderDetails.originLocation }}</td>
          <td>{{ orderDetails.finalDestination }}</td>
          <td>{{ orderDetails.transitTime }}</td>
          <td>{{ orderDetails.parkingTime }}</td>
          <td>{{ orderDetails.loadingTime }}</td>
          <td>{{ orderDetails.standardTransitTime }}</td>
          <td>{{ orderDetails.inLoadingFrom | momentDST }}</td>
          <td>{{ orderDetails.inTransitFrom | momentDST }}</td>
          <td>{{ orderDetails.deliveredAt }}</td>
          <td>{{ orderDetails.distance }}</td>
        </tr>
      </template>
    </table-basic>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TableBasic from '@/components/tables/TableBasic.vue'

export default {
  components: { 
    TableBasic,
  },
  computed: {
    ...mapState('order', ['order', 'orderDetails']),
    columnPrefix() {
      if (Object.keys(this.orderDetails).length) {
        if (this.orderDetails.status === 'Delivered') return 'Total'
        else return 'Actual'
      }
      return ''
    }
  },
}
</script>
