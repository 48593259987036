import api from './api'

export const editGeofencingTime = async (geofencingTime) => (
  await api.post(`/geofencingTime/update/${geofencingTime._id}`, geofencingTime)
)

export const createGeofencingTime = async (geofencingTime) => (
  await api.post('/geofencingTime/create', geofencingTime)
)

export const getGeofencingTime = async (id) => (
  await api.post(`/geofencingTime/${id}`)
)

export const getGeofencingTimeByOrder = async (order) => (
  await api.post(`/geofencingTimeByOrder/${order}`)
)
export const deleteGeofencingTime = async (id) => (
  await api.delete(`/geofencingTime/delete/${id}`)
)