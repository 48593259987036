<template>
  <div v-if="companies">
    <v-select 
      transition=""
      :class="{ 'dark': dark }"
      :clearable="clearable"
      :value="value"
      :disabled="disabled"
      @input="val => $emit('input', val)"
      label="name" 
      :options="companiesOptions"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CompanySelect',
  props: {
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    allOption: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    companyToExclude: {
      type: String
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('main', ['companies']),
    companiesOptions() {
      const options = [ ...this.companies ]
      if (this.allOption) {
        options.unshift({ name: 'All', _id: null })
        options.unshift({ name: 'All (without testing)', _id: null, excludeTestingCompany: true })
      }
      if (this.companyToExclude) {
        return options.filter(opt => opt._id !== this.companyToExclude)
      }
      return options
    }
  },
}
</script>
<style lang="scss">
.v-select {
  &.dark {
    width: 210px;
    @media (max-width: 568px) {
      width: 100px;
    }
    span.vs__selected {
      color: #fff;
    }
    .vs__actions {
      color: #fff;
    }
  }
}
</style>
