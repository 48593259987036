<template>
  <b-modal
    id="order-templates-modal"
    ref="orderModal"
    size="xl"
    footer-bg-variant="dark">
    <div class="row justify-content-md-center">
      <div class="col-12">
        <table-basic title="Order templates">
          <template slot="afterTitle">
            <div class="row d-inline">
              <router-link :to="'/order-template-detail/' + -1">
                <button class="btn btn-orange ml-4 py-1 px-2">
                  <i class="fas fa-plus-circle mr-2"></i>New template
                </button>
              </router-link>
            </div>
          </template>

          <template v-slot:thead>
            <tr class="cursor-pointer">
              <th>Name</th>
              <th>Origin Country</th>
              <th>Origin City</th>
              <th>Destination Country</th>
              <th>Destination City</th>
              <th>Email for notifications</th>
            </tr>
          </template>

          <template v-slot:tbody>
            <tr v-for="{ _id, name, startLocation, finalDestination} in rows" :key="_id">
              <td>{{ name }}</td>
              <td>{{ startLocation &&  startLocation.country }}</td>
              <td>{{ startLocation &&  startLocation.city }}</td>
              <td>{{ finalDestination &&  finalDestination.country }}</td>
              <td>{{ finalDestination &&  finalDestination.city }}</td>
              <td>{{ finalDestination &&  finalDestination.notificationEmails }}</td>
              <td class="with-btn rowBtn">
                <button type="button" class="btn btn-sm btn-success" @click="transferOrderTemplate({ _id, name, startLocation, finalDestination})">
                  SELECT
                </button>
              </td>
              <td class="with-btn rowBtn">
                <router-link :to="'/order-template-detail/' +  _id">
                  <button type="button" class="btn btn-sm btn-primary">EDIT</button>
                </router-link>
              </td>
              <td class="with-btn rowBtn">
                <button type="button" class="btn btn-sm btn-danger" @click="deleteOrderTemplate({ _id, name, startLocation, finalDestination})">
                  DELETE
                </button>
              </td>
            </tr>
          </template>
        </table-basic>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getOrderTemplates, deleteOrderTemplate } from '@/services/orderTemplates.js'
import TableBasic from '@/components/tables/TableBasic.vue'

export default { 
  components: {
    TableBasic
  },
  methods: {
    async deleteOrderTemplate(orderTemplate) {
      if (orderTemplate._id)
        await deleteOrderTemplate(orderTemplate._id)

      await this.refresh()

      this.$toast('Successfully deleted', {
        className: ['bg-danger']
      })
    },
    async refresh() {
      this.rows = (await getOrderTemplates()).data || []
    },
    transferOrderTemplate(orderTemplate) {
      this.$emit('transferOrderTemplate', orderTemplate)
      this.$refs.orderModal.hide()
    }
  },
  data() {
    return {
      rows: [],
    }
  },
  async mounted() {
    await this.refresh()
  }
}
</script>

<style lang="scss">
  #order-templates-modal {
    .modal-body, .modal-body .panel {
      margin: 0 !important;
      padding: 0 !important;
    }

    .panel {
      margin: 0;
    }

    .panel-body, .panel-heading {
      border-radius: 0em !important;
    }

    .modal-header, .modal-footer {
      display: none !important;
    }

    .rowBtn {
      width: 50px;
    }
  }
</style>