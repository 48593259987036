<template>
  <b-modal 
    id="fota-upload-modal"
    @shown="setInitialModel"
    @hidden="resetDefault"
    title="Upload new firmware"
  >
    <template>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-4 align-self-center">
          <template v-if="!file">
            <button type="button" @click="$refs.fileUpload.click()" class="btn btn-primary py-1 px-2">
              <i class="fas fa-upload mr-2"></i> Upload firmware
            </button>
            <input ref="fileUpload" type="file" accept=".bin" @change="handleFileChange" @click="$refs.fileUpload.value=null" hidden>
          </template>
          <template v-else>
            <button type="button" @click="resetDefault" class="btn btn-danger py-1 px-2">
              <i class="fas fa-times-circle mr-2"></i> Remove file
            </button>
          </template>
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Model</label>
        </div>
        <div class="col-5">
          <input class="form-control" v-model="fota.model" placeholder="Enter model" readonly/>
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Version</label>
        </div>
        <div class="col-5">
          <input class="form-control" v-model="fota.version" placeholder="Enter version" />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Note</label>
        </div>
        <div class="col-5">
          <input class="form-control" v-model="fota.note" placeholder="Enter note" />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-7 align-self-center">
          <div class="checkbox checkbox-css checkbox-inline">
            <input type="checkbox" id="isProductionCheckbox" v-model="fota.isProduction" />
            <label for="isProductionCheckbox">Is Production</label>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer="{ cancel }">
      <button class="btn" @click="cancel">Cancel</button>
      <button class="btn btn-success" @click="upload">Upload</button>
    </template>
  </b-modal>
</template>

<script>
import Vue from 'vue'

const fotaDefault = {
  model: '',
  version: '',
  note: '',
  isProduction: false,
}
export default {
  props: {
    initialModel: {
      required: true,
    }
  },
  data() {
    return {
      fota: { ...fotaDefault },
      file: null,
    }
  },
  methods: {
    resetDefault() {
      this.fota = { ...fotaDefault }
      this.file = null 
    },
    setInitialModel() {
      Vue.set(this.fota, 'model', this.initialModel)
    },
    parseFilename(filename) {
      // Remove filename extension
      const filenameWithoutExt = filename.split('.').slice(0, -1).join('.')
      // Split filename by '_' to obtain model and version
      const parsed = filenameWithoutExt.split('_')
      return { model: parsed[0], version: parsed.slice(1, 4).join('.') }
    },
    upload() {
      if (!this.fota.model || !this.fota.version) {
        this.$toast('Model and Version are required', { className: ['et-alert'] })
        return
      }
      if (!this.file) {
        this.$toast('Please upload firmware file', { className: ['et-alert'] })
        return
      }
      const { model, version } = this.parseFilename(this.file.name)
      if (model.toUpperCase() !== this.fota.model.toUpperCase()) {
        this.$toast('Model does not match, please check your file.', { className: ['et-alert'] })
        return
      }
      if (version !== this.fota.version) {
        this.$toast('Version does not match, please check your file.', { className: ['et-alert'] })
        return
      }
      if (!model || !version) {
        this.$toast('File validation has failed, please check your file.', { className: ['et-alert'] })
        return
      }
      this.$emit('upload', { fota: this.fota, file: this.file })
    },
    handleFileChange(e) {
      const file = e.target.files[0]
      const { model, version } = this.parseFilename(file.name)
      this.file = file
      if (!model || !version) {
        this.$toast('Error while parsing model and version, please check your file.', { className: ['et-alert'] })
        return
      }
      this.fota.model = model.toUpperCase()
      this.fota.version = version
    },
  }
}
</script>

<style>

</style>