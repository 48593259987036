<template>
  <div>
    <b-modal
      id="geofencing-place-edit-modal"
      @hidden="handleCloseModal"
      :title="`${!geofencing ? 'Create' : 'Edit'} geofencing place`"
    >
      <template v-if="geofencingModel">
        <div class="row mb-3 justify-content-md-center">
          <div class="col-2 align-self-center">
            <label>Name</label>
          </div>
          <div class="col-5">
            <input
              class="form-control"
              v-model="geofencingModel.name"
              placeholder="Enter name"
            />
          </div>
        </div>
        <div class="row mb-3 justify-content-md-center">
          <div class="col-2 align-self-center">
            <label>Distance (metres)</label>
          </div>
          <div class="col-5">
            <input
              type="number"
              class="form-control"
              v-model="geofencingModel.distance"
              placeholder="Enter distance"
            />
          </div>
        </div>
        <div class="row mb-3 justify-content-md-center">
          <div class="col-2 align-self-center">
            <label>When trigger</label>
          </div>
          <div class="col-5">
            <v-select
              transition=""
              v-model="geofencingModel.trigger"
              :options="['Reached', 'Left']"
              label="label"
              :clearable="false"
            />
          </div>
        </div>
        <div class="row mb-3 justify-content-md-center">
          <div class="col-2 align-self-center">
            <label>Address</label>
          </div>
          <div class="col-5">
            <gmap-autocomplete
              type="search"
              :value="geofencingModel.gps.address"
              class="form-control"
              placeholder="Type an address"
              @place_changed="setAddress"
            >
            </gmap-autocomplete>
          </div>
        </div>
        <div class="row mb-3 justify-content-md-center">
          <div class="col-2 align-self-center">
            <label>Latitude</label>
          </div>
          <div class="col-5">
            <input
              type="number"
              class="form-control"
              v-model="geofencingModel.gps.latitude"
              placeholder="Enter latitude"
            />
          </div>
        </div>
        <div class="row mb-3 justify-content-md-center">
          <div class="col-2 align-self-center">
            <label>Longitude</label>
          </div>
          <div class="col-5">
            <input
              type="number"
              class="form-control"
              v-model="geofencingModel.gps.longitude"
              placeholder="Enter longitude"
            />
          </div>
        </div>
        <div class="row mb-3 justify-content-md-center">
          <div class="col-2 align-self-center">
            <label></label>
          </div>
          <div class="col-5">
            <button type="button" @click="$bvModal.show('google-map-picker-modal')" class="btn btn-sm btn-primary">Show on map</button>
          </div>
        </div>
        <div class="row mb-3 justify-content-md-center">
          <div class="col-2 align-self-center">
            <label>Update Period (sec)</label>
          </div>
          <div class="col-5">
            <input
              type="number"
              v-model="geofencingModel.activeTime"
              class="form-control"
              placeholder="Enter Update Period"
            />
          </div>
        </div>
        <div class="row mb-3 justify-content-md-center">
          <div class="col-7 align-self-center">
            <div class="checkbox checkbox-css checkbox-inline">
              <input
                type="checkbox"
                id="isActiveCheckbox"
                v-model="geofencingModel.isActive"
              />
              <label for="isActiveCheckbox">Is Active</label>
            </div>
          </div>
        </div>
        <div class="row mb-3 justify-content-md-center">
          <div class="col-7 align-self-center">
            <div class="checkbox checkbox-css checkbox-inline">
              <input
                type="checkbox"
                id="sendEmailsCheckbox"
                v-model="geofencingModel.sendEmails"
              />
              <label for="sendEmailsCheckbox">Send Emails</label>
            </div>
          </div>
        </div>
        <div class="row mb-3 justify-content-md-center">
          <div class="col-7 align-self-center">
            <div class="checkbox checkbox-css checkbox-inline">
              <input
                type="checkbox"
                id="useGpsCheckbox"
                v-model="geofencingModel.useGps"
              />
              <label for="useGpsCheckbox">Use GPS</label>
            </div>
          </div>
        </div>
      </template>
      <template #modal-footer="{ cancel }">
        <button class="btn" @click="cancel">Cancel</button>
        <button class="btn btn-success" @click="save">Save</button>
      </template>
    </b-modal>
    <google-map-picker-modal 
      :latitude="parseFloat(geofencingModel.gps.latitude)"
      :longitude="parseFloat(geofencingModel.gps.longitude)" 
      @locationUpdated="handleUpdateLocation" 
    />
  </div>
</template>

<script>
import _ from 'lodash'
import { gmapApi } from 'vue2-google-maps'
import GoogleMapPickerModal from '@/components/map/GoogleMapPickerModal.vue'

const defaultState = {
  name: '',
  distance: 3000,
  trigger: 'Reached',
  gps: {
    latitude: null,
    longitude: null,
    address: null,
  },
  activeTime: 600,
  isActive: true,
  sendEmails: true,
  useGps: false,
}

export default {
  props: ['geofencing'],
  data() {
    return {
      geofencingModel: null,
      geocoder: null,
    }
  },
  components: {
    GoogleMapPickerModal
  },
  watch: {
    geofencing: {
      deep: true,
      immediate: true, 
      handler (newVal) {
        if (newVal) {
          this.geofencingModel = _.cloneDeep(newVal)
        } else {
          this.geofencingModel = _.cloneDeep(defaultState)
        }
      }
    },
  },
  computed: {
    google: gmapApi,
  },
  methods: {
    handleCloseModal() {
      // Clear geofencing model after close and fire event
      this.$emit('hidden')
      this.geofencingModel = _.cloneDeep(defaultState)
    },
    handleUpdateLocation(latLng) {
      const { lat, lng } = latLng
      this.geofencingModel.gps.latitude = lat
      this.geofencingModel.gps.longitude = lng
      // this.getAddressFromLatLng(lat, lng)
      this.$bvModal.hide('google-map-picker-modal')
    },
    setAddress(data) {
      const { formatted_address = null, geometry = {} } = data 
      this.geofencingModel.gps.address = formatted_address
      this.geofencingModel.gps.latitude = geometry.location.lat()
      this.geofencingModel.gps.longitude = geometry.location.lng()
    },
    // getAddressFromLatLng(lat, lng) {
    //   const geocoder = new this.google.maps.Geocoder()
    //   const latLng = new this.google.maps.LatLng(lat, lng)
    //   geocoder.geocode({
    //     'latLng': latLng
    //   }, function(results, status) {
    //     console.log(results)
    //     console.log(status)
    //   })
    // },
    validate() {
      // Basic validation
      if (!this.geofencingModel.name) return 'Name is required'
      if (!this.geofencingModel.distance) return 'Distance is required'
      if (!this.geofencingModel.trigger) return 'When trigger is required'
      if (!this.geofencingModel.gps?.latitude) return 'Latitude is required'
      if (!this.geofencingModel.gps?.longitude) return 'Longitude is required'
      if (!this.geofencingModel.activeTime) return 'Update period is required'
      return 
    },
    save() {
      if (!this.geofencing) {
        const error = this.validate() 
        if (error) {
          this.$toast(error, { className: ['et-alert'] })
          return 
        }
        this.$emit('save', { event: 'create', value: this.geofencingModel })
      } else {
        // Update existing geofencing
        this.$emit('save', { event: 'edit', value: this.geofencingModel }) 
      }
    }
  }
}
</script>

<style lang="scss">
// Unhide autocomplete input panel in case of modal
.pac-container {
  z-index: 1050 !important;
}
</style>