import Vue from 'vue'
import { 
  SET_COMPANIES,
  SET_TRACKERS_COUNT, SET_ACTIVE_TRACKERS_COUNT, SET_FREE_TRACKERS_COUNT,
  SET_ACTIVE_ALERTS_COUNT,
} from './constants'

export default {
  [SET_COMPANIES](state, items) {
    Vue.set(state, 'companies', items)
  },
  [SET_TRACKERS_COUNT](state, count) {
    Vue.set(state, 'trackersCount', count)
  },
  [SET_ACTIVE_TRACKERS_COUNT](state, count) {
    Vue.set(state, 'activeTrackersCount', count)
  },
  [SET_FREE_TRACKERS_COUNT](state, count) {
    Vue.set(state, 'freeTrackersCount', count)
  },
  [SET_ACTIVE_ALERTS_COUNT](state, item) {
    Vue.set(state, 'activeAlertsCount', item)
  }
}