var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.pageOptions.pageEmpty)?_c('div',{staticClass:"fade page-sidebar-fixed page-header-fixed show page-container",class:{ 
  'page-sidebar-minified': _vm.pageOptions.pageSidebarMinified, 
  'page-content-full-height': _vm.pageOptions.pageContentFullHeight, 
  'page-without-sidebar': _vm.pageOptions.pageWithoutSidebar, 
  'page-with-wide-sidebar': _vm.pageOptions.pageWithWideSidebar,
  'page-with-light-sidebar': _vm.pageOptions.pageWithLightSidebar,
  'page-sidebar-toggled': _vm.pageOptions.pageMobileSidebarToggled,
  'page-right-sidebar-toggled': _vm.pageOptions.pageMobileRightSidebarToggled || _vm.pageOptions.pageRightSidebarToggled,
  'page-right-sidebar-collapsed': _vm.pageOptions.pageRightSidebarCollapsed,
  'has-scroll': _vm.pageOptions.pageBodyScrollTop
}},[_c('vue-ins-progress-bar'),_c('Header'),(!_vm.pageOptions.pageWithoutSidebar)?_c('Sidebar',{on:{"hideSidebar":function($event){_vm.pageOptions.pageWithoutSidebar = true}}}):_vm._e(),(_vm.inited)?_c('div',{staticClass:"content pr-2 mr-2 pb-xl-2",class:{ 'content-full-width': _vm.pageOptions.pageContentFullWidth, 'content-inverse-mode': _vm.pageOptions.pageContentInverseMode },attrs:{"id":"content"}},[_c('router-view')],1):_vm._e(),(_vm.pageOptions.pageWithFooter)?_c('Footer'):_vm._e()],1):_c('div',[_c('router-view'),_c('vue-ins-progress-bar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }