<template>
  <div class="row justify-content-md-center">
    <div class="col-10">
      <form>
        <panel id="trackerEditPanel" title='Order template detail'>
          <div class="row mb-3 justify-content-md-center">
            <div class="col-2">
              <label>Template name</label>
            </div>
            <div class="col-5">
              <input class="form-control" v-model="orderTemplate.name" placeholder="Enter order name">
            </div>
          </div>

          <div class="row mb-3 justify-content-md-center">
            <div class="col-2">
              <label>Origin Country</label>
            </div>
            <div class="col-5">
              <input class="form-control" v-model="orderTemplate.startLocation.country" placeholder="Enter destination country">
            </div>
          </div>

          <div class="row mb-3 justify-content-md-center">
            <div class="col-2">
              <label>Origin City</label>
            </div>
            <div class="col-5">
              <input class="form-control" v-model="orderTemplate.startLocation.city" placeholder="Enter destination city">
            </div>
          </div>

          <div class="row mb-3 justify-content-md-center">
            <div class="col-2">
              <label>Destination country</label>
            </div>
            <div class="col-5">
              <input class="form-control" v-model="orderTemplate.finalDestination.country" placeholder="Enter destination country">
            </div>
          </div>

          <div class="row mb-3 justify-content-md-center">
            <div class="col-2">
              <label>Destination city</label>
            </div>
            <div class="col-5">
              <input class="form-control" v-model="orderTemplate.finalDestination.city" placeholder="Enter destination city">
            </div>
          </div>

          <div class="row mb-3 justify-content-md-center">
            <div class="col-2">
              <label>Email for notifications</label>
            </div>
            <div class="col-5">
              <input class="form-control" v-model="orderTemplate.finalDestination.notificationEmails" placeholder="Enter emails">
            </div>
          </div>

          <hr class="bg-white">

          <hr>
          <div class="text-center">
            <button type="submit" class="btn btn-success btn-save" @click.prevent="save">
              Save
            </button>
          </div>
        </panel>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { FETCH_ORDER_TEMPLATE } from '@/store/order/constants'
import { editOrderTemplate, createOrderTemplate } from '@/services/orderTemplates'

export default {
  components: {
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('order', ['orderTemplate']),
  },
  methods: {
    ...mapActions('order', {
      fetchOrderTemplate: FETCH_ORDER_TEMPLATE
    }),
    async save() {
      if (this.orderTemplate._id !== undefined)
        await editOrderTemplate(this.orderTemplate)
      else
        await createOrderTemplate(this.orderTemplate)

      this.$toast('Successfully saved', {
        className: ['bg-success']
      })

      this.$router.go(-1)
    }
  },
  created: async function () {
    const { id } = this.$route.params
    this.orderTemplate.finalDestination = {}

    if (id === '-1') {
      Object.assign(this.orderTemplate, null)
      this.orderTemplate.name = ''
    } else {
      await this.fetchOrderTemplate({ id })
    }
  }
}
</script>