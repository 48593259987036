<template>
  <b-modal 
    id="geofencing-time-edit-modal"
    @hidden="handleCloseModal"
    :title="`${ !geofencing ? 'Create' : 'Edit' } geofencing time`"
  >
    <template v-if="geofencingModel">
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Name</label>
        </div>
        <div class="col-5">
          <input class="form-control" v-model="geofencingModel.name" placeholder="Enter name" />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>When trigger</label>
        </div>
        <div class="col-5">
          <DateTimePicker class="form-control" v-model="geofencingModel.trigger" :config="dateTimeOptions" />
        </div>
      </div>
      <div  class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Update Period (sec)</label>
        </div>
        <div class="col-5">
          <input 
            type="number"
            v-model="geofencingModel.activeTime"
            class="form-control"
            placeholder="Enter Update Period" 
          />
        </div>
      </div>
      <div  class="row mb-3 justify-content-md-center">
        <div class="col-7 align-self-center">
          <div class="checkbox checkbox-css checkbox-inline">
            <input type="checkbox" id="isActiveCheckbox" v-model="geofencingModel.isActive" />
            <label for="isActiveCheckbox">Is Active</label>
          </div>
        </div>
      </div>
      <div  class="row mb-3 justify-content-md-center">
        <div class="col-7 align-self-center">
          <div class="checkbox checkbox-css checkbox-inline">
            <input type="checkbox" id="sendEmailsCheckbox" v-model="geofencingModel.sendEmails" />
            <label for="sendEmailsCheckbox">Send Emails</label>
          </div>
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-7 align-self-center">
          <div class="checkbox checkbox-css checkbox-inline">
            <input
              type="checkbox"
              id="useGpsCheckbox"
              v-model="geofencingModel.useGps"
            />
            <label for="useGpsCheckbox">Use GPS</label>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer="{ cancel }">
      <button class="btn" @click="cancel">Cancel</button>
      <button class="btn btn-success" @click="save">Save</button>
    </template>
  </b-modal>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import DateTimePicker from 'vue-bootstrap-datetimepicker'
import { USER_DATETIME_FORMAT } from '@/store/user/constants'

const defaultState = {
  name: '',
  trigger: '',
  activeTime: 600,
  isActive: true,
  sendEmails: true,
  useGps: false,
}

export default {
  props: ['geofencing'],
  data() {
    return {
      geofencingModel: null,
      dateTimeOptions: null,
    }
  },
  components: {
    DateTimePicker,
  },
  mounted() {
    this.dateTimeOptions = {
      format: this.USER_DATETIME_FORMAT,
      useCurrent: false,
      sideBySide: true
    }
  },
  watch: {
    geofencing: {
      deep: true,
      immediate: true, 
      handler (newVal) {
        if (newVal) {
          this.geofencingModel = { ...newVal, trigger: moment(newVal.trigger).format(this.USER_DATETIME_FORMAT) }
        } else {
          this.geofencingModel = { ...defaultState }
        }
      }
    },
  },
  computed: {
    ...mapGetters('user', [USER_DATETIME_FORMAT])
  },
  methods: {
    handleCloseModal() {
      // Clear geofencing model after close and fire event
      this.$emit('hidden')
      this.geofencingModel = { ...defaultState }
    },
    validate() {
      // Basic validation
      if (!this.geofencingModel.name) return 'Name is required'
      if (!this.geofencingModel.trigger) return 'When trigger is required'
      if (!this.geofencingModel.activeTime) return 'Update period is required'
      return 
    },
    save() {
      if (!this.geofencing) {
        const error = this.validate() 
        if (error) {
          this.$toast(error, { className: ['et-alert'] })
          return 
        }
        this.$emit('save', { event: 'create', value: { ...this.geofencingModel, trigger: moment(this.geofencingModel.trigger, this.USER_DATETIME_FORMAT).format() } })
      } else {
        // Update existing geofencing
        this.$emit('save', { event: 'edit', value: { ...this.geofencingModel, trigger: moment(this.geofencingModel.trigger, this.USER_DATETIME_FORMAT).format() } }) 
      }
    }
  }
}
</script>

<style>

</style>