<template>
  <panel class="table-advanced table-hover" :class="{ 'wide': isWide }" :title="title"  :tablePanelId="tablePanelId">
    <template slot="title">
      <slot name="title"></slot>
    </template>
    <template slot="afterTitle">
      <slot name="afterTitle"></slot>
    </template>
    <slot name="outerPanelRow"></slot>
    <vue-good-table
      ref="tableAdvanced"
      :mode="mode"
      @on-page-change="params => $emit('on-page-change', params)"
      @on-sort-change="params => $emit('on-sort-change', params)"
      @on-column-filter="params => $emit('on-column-filter', params)"
      @on-per-page-change="params => $emit('on-per-page-change', params)"
      @on-selected-rows-change="params => $emit('on-selected-rows-change', params)"
      :columns="columns" 
      :rows="rows" 
      :totalRows="totalRows"
      isLoading.sync="isLoading"
      :search-options="searchOptions" 
      :sort-options="{
        enabled: true,
        initialSortBy: sortBy
      }"
      :pagination-options="paginationOptions"
      :select-options="selectOptions"
      :row-style-class="rowStyleClass"
    >
      <template slot="table-actions">
        <slot name="tableActions"></slot>  
      </template>
      <template slot="selected-row-actions">
        <slot name="selectedRowActions"></slot>
      </template>
      <template slot="table-row" slot-scope="props">
        <slot name="rowActions" :props="props"></slot>
      </template>
    </vue-good-table>
  </panel>
</template>

<script>
export default {
  name: 'TableAdvanced',
  props: {
    tablePanelId: String,
    title: String,
    mode: {
      type: String,
      default: ''
    },
    totalRows: Number,
    isLoading: {
      type: Boolean,
      default: false,
    },
    isWide: {
      type: Boolean,
      default: false,
    },
    searchOptions: {
      type: Object,
      default() {
        return {
          enabled: true,
          placeholder: 'Search'
        }
      }
    },
    paginationOptions: {
      type: Object,
      default() {
        return {
          enabled: true,
          position: 'bottom',
          perPage: 20
        }
      }
    },
    selectOptions: {
      type: Object,
      default: () => {},
    },
    sortBy: {
      type: Object,
      default: () => {},
    },
    columns: {
      type: Array,
      default: () => []
    },
    rows: {
      type: Array,
      default: () => []
    },
    rowStyleClass: { default: null, type: [Function, String] },
  },
}
</script>

<style lang="scss">
.table-advanced {
  .panel-body, table, .panel-body, .vgt-global-search, th, .footer__row-count__label, .footer__navigation a, .footer__navigation__info {
    background-color: #2d353c !important;
    color: white !important;
  }
  .bootstrap-datetimepicker-widget.dropdown-menu {
    background-color: #2d353c;

    .datepicker .prev i, .datepicker .next i {
      display: block;
    }
    .timepicker {
      table {
        .btn {
          color: #c4c4c4 !important;
        }
      }
    }

  }
  
  td {
    padding: .5em !important;
    color: rgb(196, 196, 196) !important;
    font-weight: 100;
    border-color: rgb(95, 95, 95) !important;
    border-right: none !important;
    border-left: none !important;
  }

  th {
    border-right: none !important;
    border-left: none !important;
    border-color: rgb(95, 95, 95) !important;
  }

  .vgt-wrap__footer {
    background: #2d353c !important;
  }

  tr:hover {
    background-color: #545a5f !important;
    color: black !important;
  }

  .panel-body {
    border-radius: 0em 0em 1em 1em !important;
  }
}

.table-advanced.wide {
  .bootstrap-datetimepicker-widget.dropdown-menu {
    max-width: 600px !important;
    width: 600px !important;
  }
}

.row-btn {
  width: 50px;
  margin: 0;
  padding: 0;
}

.label-router-link:hover {
  cursor: pointer !important;
  text-decoration: underline;
}
</style>