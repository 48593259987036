<template>
  <b-modal 
    id="company-edit-modal"
    @hidden="$emit('hidden')"
    body-class="p-30"
    :title="`${ !company ? 'Create' : 'Edit' } company`"
    size="xl"
  >
    <template v-if="companyModel">
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Name</label>
        </div>
        <div class="col-4">
          <input class="form-control" v-model="companyModel.name" placeholder="Enter name" />
        </div>
        <div class="col-2 align-self-center">
          <label>Parent Company</label>
        </div>
        <div class="col-4">
          <CompanySelect 
            clearable
            v-model="companyModel.parentCompany" 
            :company-to-exclude="companyModel._id"
          />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Notification Email</label>
        </div>
        <div class="col-4">
          <input class="form-control" v-model="companyModel.email" @input="companyModel.email=$event.target.value.toLowerCase()" placeholder="Enter email" />
        </div>
        <div class="col-2 align-self-center">
          <label>Contact Phone</label>
        </div>
        <div class="col-4">
          <input class="form-control" v-model="companyModel.phone" placeholder="Enter phone" />
        </div>
      </div>
      <div class="row mb-4 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Billing Address</label>
        </div>
        <div class="col-10">
          <input class="form-control" v-model="companyModel.address" placeholder="Enter billing address" />
        </div>
      </div>
      <template v-for="(sAddress, index) in companyModel.shippingAddresses">
        <div :key="index" class="row mb-3 justify-content-md-center">
          <div class="col-2 align-self-center">
            <label>Shipping Address {{ index + 1 }}</label>
          </div>
          <div class="col-10 d-flex address">
            <template v-for="(addressPart, partIndex) in sAddress">
              <input :key="`part` + partIndex" class="form-control address__input" :value="addressPart" @input="e => setShippingAddress(index, partIndex, e.target.value)"/>
            </template>
            <i @click="deleteShippingAddress(index)" v-if="companyModel.shippingAddresses.length > 1" class="icon-delete fa fa-times"></i>
          </div>
        </div>
      </template>
      <div class="row mb-4 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label></label>
        </div>
        <div class="col-10">
          <button type="button" @click="addShippingAddress" class="btn btn-sm btn-outline-secondary">Add new</button>
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Default Origin Address</label>
        </div>
        <div class="col-10">
          <gmap-autocomplete
            type="search"
            :value="companyModel.defaultOrigin.address"
            class="form-control"
            placeholder="Type an address of default origin"
            @place_changed="setAddress"
          />
          <div v-if="companyModel.defaultOrigin.address" @click="clearAddress" class="autocomplete-clear-button">
            <i class="fas fa-lg fa-times-circle bg-white"></i>
          </div>        
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Latitude</label>
        </div>
        <div class="col-4">
          <input
            type="number"
            class="form-control"
            v-model="companyModel.defaultOrigin.latitude"
            placeholder="Enter latitude"
          />
        </div>
        <div class="col-2 align-self-center">
          <label>Longitude</label>
        </div>
        <div class="col-4">
          <input
            type="number"
            class="form-control"
            v-model="companyModel.defaultOrigin.longitude"
            placeholder="Enter longitude"
          />   
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label></label>
        </div>
        <div class="col-10">
          <button type="button" @click="$bvModal.show('google-map-picker-modal')" class="btn btn-sm btn-primary">Show on map</button>
        </div>
      </div>
    </template>
    <template #modal-footer="{ cancel }">
      <button class="btn" @click="cancel">Cancel</button>
      <button class="btn btn-success" @click="save">Save</button>
    </template>
    <google-map-picker-modal 
      :latitude="parseFloat(companyModel.defaultOrigin.latitude)"
      :longitude="parseFloat(companyModel.defaultOrigin.longitude)" 
      @locationUpdated="handleUpdateLocation" 
    />
  </b-modal>
</template>

<script>
import _ from 'lodash'
import CompanySelect from '@/components/CompanySelect'
import GoogleMapPickerModal from '@/components/map/GoogleMapPickerModal.vue'

export default {
  props: ['company'],
  data() {
    return {
      companyModel: null,
    }
  },
  components: {
    CompanySelect,
    GoogleMapPickerModal,
  },
  watch: {
    company: {
      deep: true,
      immediate: true, 
      handler (newVal) {
        if (newVal) {
          this.companyModel = _.cloneDeep(newVal)
        } else {
          this.companyModel = {
            name: '',
            parentCompany: null,
            phone: '',
            email: '',
            address: '',
            shippingAddresses: [['', '', '']],
          }
        }

        if (!this.companyModel.defaultOrigin) {
          this.$set(this.companyModel, 'defaultOrigin', {
            latitude: null,
            longitude: null,
            address: ''
          })
        }

        if (!this.companyModel.shippingAddresses || !this.companyModel.shippingAddresses.length) {
          this.$set(this.companyModel, 'shippingAddresses', [''])
        }
      }
    },
  },
  methods: {
    setAddress(data) {
      const { formatted_address = null, geometry = {} } = data 
      this.companyModel.defaultOrigin.address = formatted_address
      this.companyModel.defaultOrigin.latitude = geometry.location.lat()
      this.companyModel.defaultOrigin.longitude = geometry.location.lng()
    },
    setShippingAddress(index, partIndex, value) {
      this.$set(this.companyModel.shippingAddresses[index], partIndex, value)
    },
    addShippingAddress() {
      this.companyModel.shippingAddresses.push(['', '', ''])
    },
    deleteShippingAddress(index) {
      this.$delete(this.companyModel.shippingAddresses, index)
    },
    clearAddress() {
      this.$set(this.companyModel, 'defaultOrigin', {
        latitude: null,
        longitude: null,
        address: ''
      })
    },
    async handleUpdateLocation(latLng) {
      const { lat, lng } = latLng
      this.companyModel.defaultOrigin.address = ''
      this.companyModel.defaultOrigin.latitude = lat
      this.companyModel.defaultOrigin.longitude = lng
      this.$bvModal.hide('google-map-picker-modal')
    },
    validate() {
      // Basic validation
      if (!this.companyModel.name) return 'Name is required'
      if (this.companyModel.phone && !/^[\d.\-+()\s]+$/.test(this.companyModel.phone)) {
        return 'Please provide a valid phone number'
      }
      if (this.companyModel.email && !/\S+@\S+\.\S+/.test(this.companyModel.email)) {
        return 'Please provide a valid e-mail'
      }
      if (!this.companyModel?.defaultOrigin?.latitude || !this.companyModel?.defaultOrigin?.longitude) return 'Please provide coordinates of default origin'
      return 
    },
    save() {
      if (!this.company) {
        const error = this.validate() 
        if (error) {
          this.$toast(error, { className: ['et-alert'] })
          return 
        }
        // Create new company
        this.$emit('save', { event: 'create', value: {
          ...this.companyModel,
          // Remove extra spaces from phone number
          phone: this.companyModel.phone ? this.companyModel.phone.replace(/  +/g, ' ').trim() : null,
          shippingAddresses: this.companyModel.shippingAddresses.filter(address => address.every(addressPart => !_.isEmpty(addressPart)))
        } }) 
      } else {
        // Update existing company
        this.$emit('save', { event: 'edit', value: {
          ...this.companyModel,
          // Remove extra spaces from phone number
          phone: this.companyModel.phone ? this.companyModel.phone.replace(/  +/g, ' ').trim() : null,
          shippingAddresses: this.companyModel.shippingAddresses.filter(address => address)
        } }) 
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .icon-delete {
    cursor: pointer;
    line-height: 34px;
    margin: 0 10px;
  }
  .address {
    &__input {
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
</style>