<template>
  <ul class="nav" v-if="menus">
    <li class="nav-search" v-if="pageOptions.pageSidebarSearch">
      <input
        type="text"
        @keyup="handleSidebarFilter"
        class="form-control"
        placeholder="Sidebar menu filter..."
      />
    </li>
    <li class="new-order-button">
      <button class="btn btn-orange ml-4 py-1 px-2" @click="openModal">
        <i class="fas fa-plus-circle mr-2"></i>New Order
      </button>
    </li>
    <template v-for="menu in filteredMenus">
      <sidebar-nav-list
        ref="sidebarNavList"
        :menu="menu"
        :scrollTop="scrollTop"
        :key="menu.path"
        :status="menu.status"
        @collapse-other="handleCollapseOther(menu)"
        @showFloatSubmenu="handleShowFloatSubmenu"
        @hideFloatSubmenu="handleHideFloatSubmenu"
      ></sidebar-nav-list>
    </template>
    <NewOrderModal modalId="sidebar-new-order-modal" @created="handleCloseModal"/>
  </ul>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'
import { ACTIVE_ORDERS } from '@/store/order/constants'
import { FETCH_ORDERS } from '@/store/order/constants'

import menuRoutes from './routes/menuStaticRoutes'
import SidebarNavList from './SidebarNavList.vue'
import pageOptions from '../../config/pageOptions'

import NewOrderModal from '@/components/modals/NewOrderModal'

import _ from 'lodash'

// Inserting new item within the specific index
const insert = (arr, index, newItem) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index)
]

export default {
  name: 'SidebarNav',
  props: ['scrollTop'],
  components: {
    SidebarNavList,
    NewOrderModal,
  },
  data() {
    return {
      menus: menuRoutes,
      pageOptions: pageOptions,
    }
  },
  created() {
    // Hide sidebar if filtered menus is empty
    if (_.isEmpty(this.filteredMenus)) this.$emit('hideSidebar') 
  },
  computed: {
    ...mapGetters('order', {
      activeOrders: ACTIVE_ORDERS,
    }),
    ...mapState('user', ['user']),
    // Filter menus by user role 
    filteredMenus() {
      return this.allMenus.filter(menu => menu.roles.includes(this.user?.role))    
    },
    allMenus() {
      // Dynamically adds routes 
      if (this.activeOrders) {
        return insert(this.menus, 2, {
          title: 'Active Order List',
          status: 'expand',
          collapsible: false,
          roles: ['admin', 'customer'],
          children: this.activeOrders.map(order => ({ title: order.name, path: `/orders/${order._id}` }))
        })
      }
      return this.menus
    }
  },
  methods: {
    ...mapActions('order', {
      fetchOrders: FETCH_ORDERS,
    }),

    handleShowFloatSubmenu: function (menu, offset) {
      this.$emit('showFloatSubmenu', menu, offset)
    },
    handleHideFloatSubmenu: function () {
      this.$emit('hideFloatSubmenu')
    },
    handleCollapseOther: function (menu) {
      for (var i = 0; i < this.menus.length; i++) {
        this.$refs.sidebarNavList[i].collapse(menu)
      }
    },
    handleSidebarMinify: function () {
      this.pageOptions.pageSidebarMinified = !this.pageOptions
        .pageSidebarMinified
    },
    handleSidebarFilter: function (e) {
      var value = e.target.value
      value = value.toLowerCase()

      if (value) {
        for (var x = 0; x < this.menus.length; x++) {
          var title = this.menus[x].title.toLowerCase()
          var children = this.menus[x].children

          if (title.search(value) > -1) {
            this.$refs.sidebarNavList[x].show()

            if (children) {
              this.$refs.sidebarNavList[x].searchExpand()
            }
          } else {
            if (children) {
              var hasActive = false
              for (var y = 0; y < children.length; y++) {
                var title2 = children[y].title.toLowerCase()

                if (title2.search(value) > -1) {
                  hasActive = true
                  this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].show()
                  this.$refs.sidebarNavList[x].searchExpand()
                } else {
                  if (hasActive) {
                    this.$refs.sidebarNavList[x].searchExpand()
                  } else {
                    this.$refs.sidebarNavList[x].hide()
                  }
                  this.$refs.sidebarNavList[x].$refs.sidebarNavList[y].hide()
                }
              }
            } else {
              this.$refs.sidebarNavList[x].hide()
            }
          }
        }
      } else {
        for (var a = 0; a < this.menus.length; a++) {
          this.$refs.sidebarNavList[a].show()

          var submenu = this.menus[a].children
          if (submenu) {
            for (var b = 0; b < submenu.length; b++) {
              this.$refs.sidebarNavList[a].$refs.sidebarNavList[b].show()
            }
          }
        }
      }
    },
    openModal() {
      this.$bvModal.show('sidebar-new-order-modal')
    },
    async handleCloseModal() {
      this.$bvModal.hide('sidebar-new-order-modal')
      await this.fetchOrders()
    },
  },
}
</script>
<style lang="scss">
.new-order-button {
  margin: 10px 0;
}
</style>