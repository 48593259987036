<template>
  <div class="row justify-content-md-center">
    <div class="col-12">
      <form>
        <panel v-if="alert" id="alertEditPanel" title="Alert Edit">
          <div class="row mb-3">
            <div class="col-1">
              <label>Order status</label>
            </div>
            <div class="col-3">
              <template v-if="alert.ordersFull && alert.ordersFull[0]">
                <b-form-select v-model="alert.ordersFull[0].status" :options="orderStatuses"></b-form-select>
              </template>
              <template v-else>
                <b-form-select :value="null" disabled></b-form-select>
              </template>
            </div>
            
            <div class="col-1">
              <label>Start Datetime</label>
            </div>
            <div class="col-3">
              <DateTimePicker v-if="dateTimeOptions" v-model="alert.startDatetime" :config="dateTimeOptions" />
            </div>
            <div class="col-1">
              <label>Description</label>
            </div>
            <div class="col-3">
              <input class="form-control" v-model="alert.description">
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-1">
              <label>Tracker Status</label>
            </div>
            <div class="col-3">
              <template v-if="alert.trackers && alert.trackers[0]">
                <b-form-select v-model="alert.trackers[0].isActive" :options="trackerStatuses"></b-form-select>
              </template>
              <template v-else>
                <b-form-select :value="null" disabled></b-form-select>
              </template>
            </div>
            <div class="col-1">
              <label>End Datetime</label>
            </div>
            <div class="col-3">
              <DateTimePicker v-if="dateTimeOptions" v-model="alert.endDatetime" :config="dateTimeOptions" />
            </div>
            <div class="col-1">
              <label>Resolved</label>
            </div>
            <div class="col-3">
              <b-form-checkbox v-model="alert.isResolved"/>            
            </div>
          </div>

          <hr>
          <div class="text-center">
            <button type="submit" class="btn btn-success btn-save" @click.prevent="save">
              Save
            </button>
          </div>
        </panel>
      </form>
      <google-map panelBodyClass="p-0" mapClass="height-md">
        <template slot="content">
          <GmapMarker 
            v-if="startLocationMarker"
            title="title"
            :position="startLocationMarker.position"
            :draggable="false"
          />
          <GmapMarker 
            v-if="endLocationMarker"
            :position="endLocationMarker.position"
            :icon="endLocationMarker.icon"
            :draggable="false"
          />
        </template>
      </google-map>
    </div>
  </div>
</template>

<script>
import DateTimePicker from 'vue-bootstrap-datetimepicker'

import GoogleMap from '@/components/map/GoogleMap.vue'
import { FETCH_ACTIVE_ALERTS, FETCH_RESOLVED_ALERTS } from '@/store/alert/constants'
import { USER_DATETIME_FORMAT } from '@/store/user/constants'
import { editAlert } from '@/services/alerts'
import { editOrder } from '@/services/orders'
import { editTracker } from '@/services/trackers'

import _ from 'lodash'
import { mapState, mapActions, mapGetters } from 'vuex'
import { gmapApi } from 'vue2-google-maps'

export default {
  components: {
    GoogleMap,
    DateTimePicker
  },
  data() {
    return {
      alert: null,
      originalIsResolved: null,
      dateTimeOptions: null
    }
  },
  computed: {
    ...mapState('alert', ['activeAlerts', 'resolvedAlerts']),
    ...mapGetters('user', [USER_DATETIME_FORMAT]),
    google: gmapApi,
    orderStatuses() {
      return ['Delivered', 'In-Transit', 'Loading']
    },
    trackerStatuses() {
      return [{ value: false, text: 'Inactive' }, { value: true, text: 'Active' }]
    },
    startLocationMarker() {
      const { startLocation = null } = this.alert
      if (!startLocation) return undefined
      const marker = {
        position: {
          lat: startLocation?.coordinates[0],
          lng: startLocation?.coordinates[1]
        }
      }
      return marker
    },
    endLocationMarker() {
      const { endLocation = null } = this.alert
      if (!endLocation) return undefined
      const marker = {
        position: {
          lat: endLocation?.coordinates[0],
          lng: endLocation?.coordinates[1]
        },
        icon: {
          url: '/assets/img/svg/flag-marker.svg',
          scaledSize: this.google && new this.google.maps.Size(25, 25)
        }
      }
      return marker
    }
  },
  methods: {
    ...mapActions('alert', {
      fetchActiveAlerts: FETCH_ACTIVE_ALERTS,
      fetchResolvedAlerts: FETCH_RESOLVED_ALERTS
    }),
    async save() { 
      let { _id, createdTime, startDatetime, endDatetime, description, resolvedTime, isResolved, ordersFull, trackers } = this.alert
      try { 
        if (isResolved !== this.originalIsResolved) {
          if (isResolved) resolvedTime = new Date()
          else resolvedTime = null
        }
        await editAlert({ _id, createdTime, startDatetime, isResolved, endDatetime, description, resolvedTime })
        if (ordersFull[0]) await editOrder(ordersFull[0])
        if (trackers[0]) await editTracker(trackers[0])
        this.$toast('Successfully saved', {
          className: ['bg-success']
        })
        this.$router.go(-1)
      } catch (e) {
        console.warn(e)
        this.$toast('Error while saving', {
          className: ['bg-danger']
        })
      } 
     
    },
    async refresh() {
      // If alerts are not loaded -> fetch from API
      if (!this.activeAlerts.length || !this.resolvedAlerts.length) {
        await this.fetchActiveAlerts()
        await this.fetchResolvedAlerts()
      }
      const alert = this.activeAlerts.concat(this.resolvedAlerts).find(alert => alert._id === this.$route.params.id) || {}
      this.alert = _.cloneDeep(alert)
      this.originalIsResolved = alert.isResolved
    }
  },
  async created () {
    await this.refresh()
  },
  mounted() {
    this.dateTimeOptions = {
      format: this.USER_DATETIME_FORMAT,
      useCurrent: false,
      sideBySide: true
    }
  }
}
</script>

<style>
  .btn-save {
    width: 150px;
  }
</style>