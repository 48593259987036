import Swal from 'sweetalert2'
import axios from 'axios'
import router from '@/router'
import jwt_decode from 'jwt-decode'

const DAY = 86400000

function getToken() {
  return JSON.parse(localStorage.getItem('user'))?.token || null
}

const api = axios.create({
  baseURL: process.env.VUE_APP_API || 'http://localhost:8393',
  timeout: 100000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
})

api.interceptors.request.use((config) => {
  config.headers.Authorization = getToken()
  return config
})

api.interceptors.response.use((response) => {
  const token = getToken()
  if (token) {
    const decoded = jwt_decode(token)
    // If time of expiration is bigger than 2 days -> invalidate it and redirect to login page
    if (decoded.exp * 1000 > (Date.now() + DAY * 2)) {
      if (router.history.current.path !== '/login') router.push('/login')
      return response
    } 
  } 
  return response
}, async (error) => {
  if (error.response && error.response.status === 401) {
    const { isConfirmed } = await Swal.fire({
      title: 'Session Expired',
      text: 'Your session has expired. Would you like to be redirected to the login page?',
      showDenyButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        confirmButton: 'btn btn-success btn-sm mr-2',
        denyButton: 'btn btn-danger btn-sm'
      },
      buttonsStyling: false
    })
    if (isConfirmed) {
      if (router.history.current.path !== '/login') router.push('/login')
    }
    return Promise.reject(error)
  } else if (!error.response) {
    // Handle BE outage 
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong on server-side! We are working to resolve the problem as quickly as we could.',
      confirmButtonText: 'Understood',
      customClass: {
        confirmButton: 'btn btn-success btn-sm mr-2',
      },
    })
  }
  return Promise.reject(error)
})

export default api 
