import moment from 'moment'
import { getActiveAlerts, getResolvedAlerts } from '@/services/alerts'

import { 
  FETCH_ACTIVE_ALERTS,
  FETCH_RESOLVED_ALERTS,
  SET_ACTIVE_ALERTS, SET_RESOLVED_ALERTS
} from './constants'
import { USER_DATETIME_FORMAT } from '@/store/user/constants'

export default {
  async [FETCH_ACTIVE_ALERTS]({ commit, rootState, rootGetters }) {
    const { user, filterCompany } = rootState.user 
    const userDatetimeFormat = rootGetters[`user/${USER_DATETIME_FORMAT}`].replace('Z', '')
    const { _id, excludeTestingCompany = false } = filterCompany
    // If user is admin -> use company from selector
    const companyId = user?.role === 'admin' ? _id : user?.company

    const { data = [] } = (await getActiveAlerts({ companyId, excludeTestingCompany })) || []
    commit(SET_ACTIVE_ALERTS, data.map(aa => {
      return {
        ...aa,
        createdTime: moment(aa.createdTime).format(userDatetimeFormat),
        startDatetime: aa.startDatetime && moment(aa.startDatetime).format(userDatetimeFormat),
        endDatetime: aa.endDatetime && moment(aa.endDatetime).format(userDatetimeFormat),
        resolvedTime: null,
      }
    }))
  },

  async [FETCH_RESOLVED_ALERTS]({ commit, rootState, rootGetters }) {
    const { user, filterCompany } = rootState.user 
    const userDatetimeFormat = rootGetters[`user/${USER_DATETIME_FORMAT}`].replace('Z', '')
    const { _id, excludeTestingCompany = false } = filterCompany
    // If user is admin -> use company from selector
    const companyId = user?.role === 'admin' ? _id : user?.company

    const { data = [] } = (await getResolvedAlerts({ companyId, excludeTestingCompany })) || []
    commit(SET_RESOLVED_ALERTS, data.map(ra => {
      return {
        ...ra,
        createdTime: moment(ra.createdTime).format(userDatetimeFormat),
        resolvedTime: ra.resolvedTime && moment(ra.resolvedTime).format(userDatetimeFormat),
        startDatetime: ra.startDatetime && moment(ra.startDatetime).format(userDatetimeFormat),
        endDatetime: ra.endDatetime && moment(ra.endDatetime).format(userDatetimeFormat)
      }
    }))
  }
}