<template>
  <b-modal 
    :id="modalId"
    title="New order"
    size="lg"
    @shown="getCompaniesWithChilds"
    @hidden="resetOrder"
  >
    <template>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Tracker</label>
        </div>
        <div class="col-5">
          <input class="form-control" v-model="trackerImei" placeholder="Scan tracker barcode/QR code here" />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Order</label>
        </div>
        <div class="col-5">
          <input class="form-control" v-model="orderName" placeholder="Scan your Order number" />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Pallet/truck</label>
        </div>
        <div class="col-5">
          <input class="form-control" v-model="truckPallete" placeholder="Enter pallet / 3rd identification" />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Company</label>
        </div>
        <div class="col-5">
          <template v-if="isAdmin">
            <CompanySelect 
              :value="company && company.name"
              @input="updateSelection"
            />
          </template>
          <template v-else-if="isCustomer">
            <v-select 
              transition=""
              :clearable="false"
              :value="company && company.name"
              @input="updateSelection"
              label="name" 
              :options="companiesWithChilds"
            />
          </template>
        </div>
      </div>
    </template>
    <template #modal-footer="{ cancel }">
      <button class="btn" @click="cancel">Cancel</button>
      <button class="btn btn-success" @click="save">Save</button>
    </template>
  </b-modal>
</template>

<script>
import CompanySelect from '@/components/CompanySelect'
import { consignment } from '@/services/consignment'
import { getCompaniesWithChilds } from '@/services/companies'
import { mapState } from 'vuex'

const defaultState = {
  orderName: null,
  truckPallete: null,
  trackerImei: null,
  company: null,
  companiesWithChilds: []
}

export default {
  props: {
    modalId: {
      type: String,
    },
  },
  data() {
    return {
      ...defaultState
    }
  },
  components: { CompanySelect },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('main', ['companies']),
    isAdmin() {
      return this.user?.role === 'admin'
    },
    isCustomer() {
      return this.user?.role === 'customer'
    }
  },
  methods: {
    updateSelection(value) {
      this.company = value
    },
    resetOrder() {
      Object.assign(this.$data, defaultState)
    },
    async getCompaniesWithChilds() {
      if (this.isCustomer) this.companiesWithChilds = (await getCompaniesWithChilds(this.user.company)).data || []
    },
    async save() {
      if (!this.orderName || !this.truckPallete || !this.trackerImei || !this.company) {
        this.$toast('Fill in all fields', { className: ['et-alert'] })
        return
      }
      try {
        await consignment({ 
          orderListValue: this.orderName,
          truckPalleteValue: this.truckPallete, 
          trackerValue: this.trackerImei,
          companyId: this.company._id
        })
      } catch(error) {
        this.$toast(error?.response?.data || 'Error', { className: ['et-alert'] })
        return 
      }
      this.$toast('Successfully created', { className: ['bg-success'] })
      this.$emit('created')
    }
  }
}
</script>