var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-12"},[_c('TableAdvanced',{ref:"firmwaresTable",staticClass:"firmwares-table",attrs:{"title":"TR1 Firmware List","columns":_vm.columns,"rows":_vm.tr1Firmawares,"row-style-class":_vm.getRowStyleClass,"selectOptions":{ 
        enabled: true,
        selectOnCheckboxOnly: true
      },"sortBy":{field: 'version', type: 'desc'}},scopedSlots:_vm._u([{key:"selectedRowActions",fn:function(){return [_c('button',{staticClass:"btn btn-sm btn-danger",attrs:{"type":"button"},on:{"click":_vm.deleteFirmwares}},[_vm._v("DELETE")])]},proxy:true},{key:"rowActions",fn:function(ref){
      var props = ref.props;
return [(props.column.field == 'updateTrackers')?_c('span',[_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.openUpdateTrackersModal(props.row)}}},[_vm._v("UPDATE TRACKERS")])]):(props.column.field == 'edit')?_c('span',[_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.openEditModal(props.row)}}},[_vm._v("EDIT")])]):_vm._e()]}}])},[_c('template',{slot:"afterTitle"},[_c('button',{staticClass:"btn btn-orange ml-2 py-1 px-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.openUploadModal('TR1')}}},[_c('i',{staticClass:"fas fa-file-upload mr-2"}),_vm._v(" Upload new TR1 firmware ")])])],2),_c('TableAdvanced',{ref:"firmwaresTable",staticClass:"firmwares-table",attrs:{"title":"TR2 Firmware List","columns":_vm.columns,"rows":_vm.tr2Firmawares,"row-style-class":_vm.getRowStyleClass,"selectOptions":{ 
        enabled: true,
        selectOnCheckboxOnly: true
      },"sortBy":{field: 'version', type: 'desc'}},scopedSlots:_vm._u([{key:"selectedRowActions",fn:function(){return [_c('button',{staticClass:"btn btn-sm btn-danger",attrs:{"type":"button"},on:{"click":_vm.deleteFirmwares}},[_vm._v("DELETE")])]},proxy:true},{key:"rowActions",fn:function(ref){
      var props = ref.props;
return [(props.column.field == 'updateTrackers')?_c('span',[_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.openUpdateTrackersModal(props.row)}}},[_vm._v("UPDATE TRACKERS")])]):(props.column.field == 'edit')?_c('span',[_c('button',{staticClass:"btn btn-sm btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.openEditModal(props.row)}}},[_vm._v("EDIT")])]):_vm._e()]}}])},[_c('template',{slot:"afterTitle"},[_c('button',{staticClass:"btn btn-orange ml-2 py-1 px-2",attrs:{"type":"button"},on:{"click":function($event){return _vm.openUploadModal('TR2')}}},[_c('i',{staticClass:"fas fa-file-upload mr-2"}),_vm._v(" Upload new TR2 firmware ")])])],2)],1),_c('FotaUploadModal',{attrs:{"initial-model":_vm.initialModel},on:{"upload":_vm.uploadNewFirmware}}),_c('FotaEditModal',{attrs:{"fota":_vm.firmware},on:{"hidden":function($event){_vm.firmware = null},"save":_vm.updateFirmware}}),_c('TrackerListModal',{attrs:{"model":_vm.firmware && _vm.firmware.model,"version":_vm.firmware && _vm.firmware.version},on:{"update":_vm.updateTrackersFirmware}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }