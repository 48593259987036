import api from './api'

export const getTrackerBasicInfos = async () => (
  await api.post('/trackerBasicInfos')
)

export const getTrackerBasicInfoByCcid = async (ccid) => (
  await api.post(`/trackerBasicInfoByCcid/${ccid}`)
)

export const upsertTrackerBasicInfo = async (trackerBasicInfos) => (
  await api.post('/trackerBasicInfo/upsert', trackerBasicInfos)
)

export const editTrackerBasicInfo = async (trackerBasicInfo) => (
  await api.post(`/trackerBasicInfo/update/${trackerBasicInfo._id}`, trackerBasicInfo)
)

export const createTrackerBasicInfo = async (trackerBasicInfo) => (
  await api.post('/trackerBasicInfo/create', trackerBasicInfo)
)

export const getTrackerBasicInfoById = async (id) => (
  await api.post(`/trackerBasicInfoById/${id}`)
)

export const deleteTrackerBasicInfo = async (id) => (
  await api.delete(`/trackerBasicInfo/delete/${id}`)
)