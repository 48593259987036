import api from './api'

export const getSimByIccid = async (iccid) => (
  await api.post(`/simByIccid/${iccid}`)
)

export const simStatus = async (iccid) => (
  await api.post(`/simStatus/${iccid}`)
)

export const simActivate = async ({ iccid, tariffId, duration }) => (
  await api.post(`/simActivate/${iccid}`, { tariffId, duration })
)

export const simDeactivate = async (iccid) => (
  await api.post(`/simDeactivate/${iccid}`)
)