import api from './api'

export const login = async ({ email, password }) => (
  await api.post('/login', { email, password })
)

export const register = async({ name, email, password, role, company, datetimeFormat }) => (
  await api.post('/register', { name, email, password, role, company, datetimeFormat })
)

export const getUsers = async () => (
  await api.post('/users')
)

export const getUser = async (id) => (
  await api.post(`/user/${id}`)
)

export const editUser = async (user) => (
  await api.post(`/user/update/${user._id}`, user)
)

export const deleteUser = async (id) => (
  await api.delete(`/user/delete/${id}`)
)

export const updatePasswordByToken = async (token, password) => (
  await api.post(`/user/updatePasswordByToken/${token}`, { password: password })
)

export const forgottenPasswordSendEmail = async (email) => (
  await api.get(`/forgotten-password/${email}`)
)