import api from './api'

export const getOrderTemplates = async () => (
  await api.post('/orderTemplates')
)

export const editOrderTemplate = async (orderTemplate) => (
  await api.post(`/orderTemplate/update/${orderTemplate._id}`, orderTemplate)
)

export const createOrderTemplate = async (orderTemplate) => (
  await api.post('/orderTemplate/create', orderTemplate)
)

export const getOrderTemplate = async (id) => (
  await api.post(`/orderTemplate/${id}`)
)

export const deleteOrderTemplate = async (id) => (
  await api.delete(`/orderTemplate/delete/${id}`)
)