import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from '@/pages/Dashboard.vue'
import Login from '@/pages/Login.vue'
import Alerts from '@/pages/Alerts/Alerts.vue'
import AlertDetails from '@/pages/Alerts/AlertDetails.vue'
import Users from '@/pages/Users/Users.vue'
import Companies from '@/pages/Companies/Companies.vue'
import SystemLogs from '@/pages/SystemLogs/SystemLogs.vue'
import Fota from '@/pages/Fota/Fota.vue'
import TrackerBasicInfo from '@/pages/TrackerBasicInfo/TrackerBasicInfo.vue'
import Orders from '@/pages/Orders/Orders.vue'
import OrderDetails from '@/pages/Orders/OrderDetails.vue'
import OrderEdit from '@/pages/Orders/OrderEdit.vue'
import Trackers from '@/pages/Trackers/Trackers.vue'
import ForgottenPassword from '@/pages/ForgottenPassword.vue'
import TrackerDetails from '@/pages/Trackers/TrackerDetails.vue'
import TrackerEdit from '@/pages/Trackers/TrackerEdit.vue'
import PrivacyPolicy from '@/pages/PrivacyPolicy.vue'
import ResetPassword from '@/pages/ResetPassword.vue'
import OrderTemplateDetail from '@/pages/OrderTemplates/OrderTemplateDetail.vue'
import OrderTemplatesModal from '@/pages/OrderTemplates/OrderTemplatesModal.vue'
import OrderParkingDetails from '@/pages/OrderParkings/OrderParkingDetails.vue'


Vue.use(VueRouter)

const routes = [
  // Generic
  { 
    path: '/',
    component: Dashboard 
  },
  { 
    path: '/login',
    name: 'login',
    component: Login,
  },
  { 
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: PrivacyPolicy,
  },
  {
    path: '/reset-password/:token',
    name: 'reset-password',
    component: ResetPassword
  },
  {
    path: '/forgotten-password',
    name: 'forgotten-password',
    component: ForgottenPassword
  },

  // Alerts
  { 
    path: '/alerts',
    component: Alerts,
  },
  { 
    path: '/alerts/:id',
    component: AlertDetails,
  },

  // Order templates
  {
    path: '/order-templates',
    name: 'order-templates',
    component: OrderTemplatesModal,
    props: true,
  },
  {
    path: '/order-template-detail/:id',
    component: OrderTemplateDetail
  },

  // Order parkings
  {
    path: '/order-parking-detail/:id',
    component: OrderParkingDetails,
    name: 'order-parking-detail',
    props: true,
  },

  // Orders
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
    props: true,
  },
  {
    path: '/orders/:id',
    component: OrderDetails
  },
  {
    path: '/orders/:id/edit',
    component: OrderEdit
  },


  // Trackers
  {
    path: '/trackers/:status',
    component: Trackers
  },
  {
    path: '/tracker-details/:id',
    component: TrackerDetails
  },
  {
    path: '/tracker-edit/:id',
    component: TrackerEdit
  },

  // Users
  { 
    path: '/users',
    component: Users,
  },

  // Companies
  { 
    path: '/companies',
    component: Companies,
  },

  // System logs
  { 
    path: '/logs',
    component: SystemLogs,
  },

  // ZOX-SW
  { 
    path: '/zox-sw',
    component: TrackerBasicInfo,
  },

  // FOTA
  {
    path: '/fota',
    component: Fota,
  },

  // Redirect
  { 
    path: '*', 
    redirect: '/' 
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  // Redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['login', 'privacy-policy', 'forgotten-password', 'reset-password']
  const authRequired = !publicPages.includes(to.name || to.path)
  const userObj = localStorage.getItem('user')
  if (authRequired && !userObj) {
    return next('/login')
  }

  // When user is logged in
  const user = JSON.parse(userObj)
  if (to.path === '/' && user?.role === 'zoxsw') {
    return next('/zox-sw')
  }

  return next()
})

export default router