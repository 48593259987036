<template>
  <div class="tracker-basic-info row justify-content-md-center">
    <div class="col-12">
      <TableAdvanced
        v-if="trackerBasicInfos"
        ref="trackerBasicInfoTable"
        title="Tracker basic info"
        :columns="columns" 
        :rows="filteredTrackerBasicInfos"
        :searchOptions="{ 
          enabled: false,
        }"
        :sortBy="{field: 'tn', type: 'desc'}"
      >
        <template slot="afterTitle">
          <button v-if="isAdmin" type="button" @click="$refs.fileUpload.click()" class="btn btn-orange ml-2 py-1 px-2">
            <i class="fas fa-file-import mr-2"></i> Import CSV
          </button>
          <button v-if="isAdmin" type="button" @click="$bvModal.show('tracker-basic-info-add-modal')" class="btn btn-orange ml-2 py-1 px-2">
            <i class="fas fa-plus mr-2"></i> Add
          </button>
          <input ref="fileUpload" type="file" accept=".csv" @change="handleFileChange" @click="$refs.fileUpload.value=null" hidden>
        </template>
        <template slot="outerPanelRow">
          <div class="date-filter d-flex">    
            <div class="d-flex">
              <div class="align-self-center mr-2">
                <label style="width:80px" class="mb-0">Update time</label>
              </div>
              <DateTimePicker 
                class="form-control" 
                v-model="updatedAtFilter" 
                :config="dateTimeOptions"
                placeholder="Select datetime"
              />  
            </div>
          </div>
        </template>
        <template #rowActions="{ props }">
          <span v-if="props.column.field == 'iccid' && props.row.trackerId">
            <router-link :to="'/tracker-edit/' + props.row.trackerId">
              <a class="text-white">{{ props.row.iccid }}</a>
            </router-link>
          </span>
          <span v-else-if="props.column.field == 'updatedAt'">
            {{ props.row.updatedAt | momentZox }}
          </span>
          <span v-else-if="props.column.field == 'logs' && props.row.updatedAt">
            <button type="button" @click="openModal(props.row._id)" class="btn btn-sm btn-success">Logs</button>
          </span>
        </template>
      </TableAdvanced>
    </div>
    <TrackerBasicInfoLogsModal :trackerBasicInfo="trackerBasicInfo"/>
    <TrackerBasicInfoAddModal @save="addTrackerBasicInfo" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import TableAdvanced from '@/components/tables/TableAdvanced.vue'
import { getTrackerBasicInfos, upsertTrackerBasicInfo, createTrackerBasicInfo } from '@/services/trackerBasicInfo'
import { USER_DATETIME_FORMAT } from '@/store/user/constants'
import TrackerBasicInfoLogsModal from './modals/TrackerBasicInfoLogsModal'
import TrackerBasicInfoAddModal from './modals/TrackerBasicInfoAddModal'
import DateTimePicker from 'vue-bootstrap-datetimepicker'

export default {
  data() {
    return {
      parseCsv: null,
      trackerBasicInfos: null,
      trackerBasicInfo: null,
      dateTimeOptions: {
        format: 'DD.MM.YYYY',
        showClear: true,
        sideBySide: false,
        useCurrent: true,
      },
      updatedAtFilter: null,
    }
  },
  components: { 
    TrackerBasicInfoLogsModal,
    TrackerBasicInfoAddModal,
    TableAdvanced,
    DateTimePicker
  },
  created() {
    this.refresh()
  },
  computed: {
    ...mapState('main', ['companies']),
    ...mapState('user', ['user']),
    ...mapGetters('user', [USER_DATETIME_FORMAT]),
    filteredTrackerBasicInfos() {
      if (!this.updatedAtFilter) return this.trackerBasicInfos
      return this.trackerBasicInfos.filter(tbi => moment(tbi.updatedAt).isSame(moment(this.updatedAtFilter, 'DD.MM.YYYY').format(), 'day'))
    },
    isAdmin() {
      return this.user?.role === 'admin'
    },
    columns() {
      return [
        { 
          label: 'ICCID',
          field: 'iccid',
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap',
          sortable: false,
          filterOptions: { 
            enabled: true,
          }, 
        },
        { 
          label: 'MSISDN',
          field: 'msisdn',
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap',
          sortable: false,
          filterOptions: { 
            enabled: true,
          }, 
        },
        { 
          label: 'Serial Number (PCB)', 
          field: 'sn',
          tdClass: 'text-center valign-middle text-nowrap', 
          thClass: 'text-center text-nowrap', 
          sortable: false,
          filterOptions: { 
            enabled: true,
          }, 
        },
        { 
          label: 'IMEI', 
          field: 'imei', 
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap',
          sortable: false,
          filterOptions: { 
            enabled: true,
          }, 
        },
        { 
          label: 'Tracker No.', 
          field: 'tn', 
          type: 'number', 
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: { 
            enabled: true,
          }, 
        },
        { 
          label: 'Box Label',
          field: 'bl', 
          tdClass: 'text-center valign-middle text-nowrap', 
          thClass: 'text-center text-nowrap',
          filterOptions: { 
            enabled: true,
          }, 
          sortable: false
        },
        { 
          label: 'TR Tag', 
          field: 'label', 
          tdClass: 'text-center valign-middle text-nowrap', 
          thClass: 'text-center text-nowrap',
          width: '100px',
          filterOptions: { 
            enabled: true,
            placeholder: ' ',
            filterDropdownItems: ['true', 'false']
          },  
          sortable: false 
        },
        { 
          label: 'Warehouse',
          field: 'wh', 
          tdClass: 'text-center valign-middle text-nowrap', 
          thClass: 'text-center text-nowrap', 
          width: '100px',
          sortable: false,
          filterOptions: { 
            enabled: true,
            placeholder: ' ',
            filterDropdownItems: ['true', 'false']

          }, 
        },
        { 
          label: 'Shipped',
          field: 'sent', 
          tdClass: 'text-center valign-middle text-nowrap', 
          thClass: 'text-center text-nowrap', 
          sortable: false,
          width: '100px',
          filterOptions: { 
            enabled: true,
            placeholder: ' ',
            filterDropdownItems: ['true', 'false']

          }, 
        },
        { 
          label: 'Model',
          field: 'model', 
          tdClass: 'text-center valign-middle text-nowrap', 
          thClass: 'text-center text-nowrap', 
          sortable: false,
          width: '100px',
          filterOptions: { 
            enabled: true,
            placeholder: ' ',
            filterDropdownItems: ['TR1', 'TR2', 'TR4']

          }, 
        },
        { 
          label: 'Company',
          field: 'companyName',
          tdClass: 'text-center valign-middle text-nowrap',
          thClass: 'text-center text-nowrap',
          filterOptions: { 
            enabled: true,
          }, 
          sortable: false 
        },
        { label: 'Update Time', field: 'updatedAt', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap', sortable: false },
        { label: '', field: 'logs', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap', sortable: false },
      ]
    }
  },
  methods: {
    getCompanyName(companyId) {
      const company = this.companies.find(company => company._id === companyId)
      return company?.name || 'None' 
    },
    async onComplete(results) {
      const parsedData = results.data.map(item => {
        return {
          iccid: item.ICCID,
          msisdn: item.MSISDN,
          tn: item.TN,
          sn: item.SN,
          imei: item.IMEI,
        }
      })
      try {
        await upsertTrackerBasicInfo(parsedData)
        this.$toast('Import successful', {
          className: ['bg-success']
        })
        await this.refresh() 
      } catch(e) {
        this.$toast('Import failed', {
          className: ['et-alert']
        })
      }
    },
    openModal(trackerBasicInfo) {
      this.trackerBasicInfo = trackerBasicInfo
      this.$bvModal.show('tracker-basic-info-logs-modal')
    },
    handleFileChange(e) {
      const fileToParse = e.target.files[0]
      this.$papa.parse(fileToParse, {
        header: true,
        trasformHeader: function(header) {
          return header.toLowerCase()
        },
        error: function (err, file, inputElem, reason) {
          console.error('Error parsing: ', reason)
        },
        complete: this.onComplete
      })
    },
    async addTrackerBasicInfo(tbi) {
      try {
        await createTrackerBasicInfo(tbi)
        this.$toast('Success', {
          className: ['bg-success']
        })
        await this.refresh() 
      } catch(e) {
        this.$toast('Failed', {
          className: ['et-alert']
        })
      }
      this.$bvModal.hide('tracker-basic-info-add-modal')
    },
    async refresh() {
      const { data = [] } = await getTrackerBasicInfos()
      this.trackerBasicInfos = data.map(tbi => {
        return {
          ...tbi,
          companyName: this.getCompanyName(tbi.companyId)
        }
      })
    }
  }
}
</script>

<style lang="scss">
</style>
