<template>
  <div class="login login-v1">
    <div class="login-container">
      <div class="login-header">
        <div class="brand">
          <span>Forgotten password</span>
        </div>
        <div class="icon">
          <i class="fa fa-lock"></i>
        </div>
      </div>
      <div class="login-body">
        <div class="login-content">
          <form @submit.prevent="handleSubmit" class="margin-bottom-0">
            <div class="form-group m-b-20">
              <input type="text" v-model="email" @input="email=$event.target.value.toLowerCase()" class="form-control form-control-lg inverse-mode" placeholder="Email Address" required />
            </div>
            <div class="row">
              <router-link class="text-decoration-none loginText col-12 float-right mb-2" to="/login">
                <i class="fas fa-arrow-left mr-1"></i> Back to Login
              </router-link>
            </div>
            <div class="login-buttons">
              <button type="submit" class="btn btn-yellow btn-block btn-lg">Send</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageOptions from '../config/pageOptions'
import { forgottenPasswordSendEmail } from '@/services/users'

export default {
  data() {
    return {
      email: ''
    }
  },
  created() {
    pageOptions.pageEmpty = true
  },
  beforeRouteLeave (to, from, next) {
    next()
  },
  methods: {
    handleSubmit: async function(e) {
      e.preventDefault()
      try {
        await forgottenPasswordSendEmail(this.email)
        this.$toast('An email has been sent with instructions on how to reset your password.', {
          className: ['bg-success']
        })
      } catch (err) {
        this.$toast('Email does not exist or another error occurred.', {
          className: ['et-alert']
        })
        return
      }
      this.$router.push({ path: '/' }).catch(() => {})
    },
  }
}
</script>

<style>
.loginText {
  color: #c6ced5;
}

.loginText:hover {
  color: white;
}
</style>