import mutations from './mutations'
import actions from './actions'

const state = {
  activeAlerts: [],
  resolvedAlerts: [],
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}