import api from './api'

export const getCompanies = async () => (
  await api.post('/companies')
)

export const getCompaniesWithChilds = async (id) => (
  await api.post(`/companyWithChilds/${id}`)
)

export const getCompany = async (id) => (
  await api.post(`/company/${id}`)
)

export const createCompany = async (company) => (
  await api.post('/company/create', company)
) 

export const editCompany = async (company) => (
  await api.post(`/company/update/${company._id}`, company)
)

export const deleteCompany = async (id) => (
  await api.delete(`/company/delete/${id}`)
)