<template>
  <div class="row">
    <div class="col-4"></div>
    <div class="col-4 mt-5 privacyPolicy">
      ZOXTRACKER a.s. with registered office at Havlíčkova 1030/1, 110 00 Praha 1, identification number (IČ): 27906574, 
      registered in the Commercial Register kept at the Municipal Court in Prague, Section B, Insert 11938 (hereinafter 
      referred to as “Seller” or “Administrator” (EC) No 2016/679 of the European Parliament and of the Council on the 
      protection of individuals with regard to the processing of personal data and on the free movement of such data and 
      repealing Directive 95/46 / EC (General Regulation on the protection of personal data) ), is not collecting any 
      personal information. Usage of the camera is only restricted to allow read barcodes. They are not stored, and data 
      history is not allowed.
    </div>
  </div>
</template>

<script>
import pageOptions from '@/config/pageOptions'

export default {
  data() {
    return {
    }
  },
  created() {
    pageOptions.pageEmpty = true
  },
}
</script>

<style>
  .privacyPolicy {
    font-size: 16px;
  }
</style>