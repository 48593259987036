var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"size":"xl","id":"tracker-list-modal","scrollable":"","title":("Pick " + _vm.model + " trackers to update (" + _vm.version + ")")},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var cancel = ref.cancel;
return [_c('button',{staticClass:"btn",on:{"click":cancel}},[_vm._v("Cancel")]),_c('button',{staticClass:"btn btn-success",on:{"click":_vm.update}},[_vm._v("Update")])]}}])},[_c('div',{staticClass:"row justify-content-md-center"},[_c('div',{staticClass:"col-12"},[_c('TableAdvanced',{ref:"trackersTable",attrs:{"columns":_vm.columns,"rows":_vm.filteredTrackers,"selectOptions":{ 
          enabled: true,
          selectOnCheckboxOnly: true
        }},scopedSlots:_vm._u([{key:"rowActions",fn:function(ref){
        var props = ref.props;
return [(props.column.field == 'isActive')?[_c('span',[_vm._v(_vm._s(props.row.isActive ? "Active" : "Inactive"))])]:(props.column.field == 'alarmPeriod')?[_c('span',[_vm._v(_vm._s(((Math.floor(props.row.alarmPeriod / 60)) + " minutes")))])]:_vm._e()]}}])},[_c('template',{slot:"outerPanelRow"},[_c('div',{staticClass:"date-filter d-flex"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"align-self-center mr-2"},[_c('label',{staticClass:"mb-0",staticStyle:{"width":"80px"}},[_vm._v("Company")])]),(_vm.isAdmin)?_c('CompanySelect',{staticClass:"align-self-center mr-3",attrs:{"value":_vm.customFilterCompany && _vm.customFilterCompany.name,"all-option":"","dark":""},on:{"input":_vm.setCompanyFilter}}):_vm._e()],1)])])],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }