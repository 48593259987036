// Mutations
export const SET_LOGGED_IN = 'SET_STATUS'
export const SET_USER = 'SET_USER'
export const SET_USERS = 'SET_USERS'
export const SET_FILTER_COMPANY = 'SET_FILTER_COMPANY'

// Actions 
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const FETCH_USER = 'FETCH_USER'
export const FETCH_USERS = 'FETCH_USERS'

// Getters 
export const USER_DATETIME_FORMAT = 'USER_DATETIME_FORMAT'