import mutations from './mutations'
import actions from './actions'

const state = {
  companies: [],
  trackersCount: 0,
  activeTrackersCount: 0,
  freeTrackersCount: 0,
  activeAlertsCount: 0,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}