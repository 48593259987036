import api from './api'

export const getOrderParkings = async () => (
  await api.post('/orderParking')
)

export const getOrderParkingsByOrderId = async (orderId) => (
  await api.post(`/orderParkingsByOrderId/${orderId}`)
)

export const editOrderParking = async (orderParking) => (
  await api.post(`/orderParking/update/${orderParking._id}`, orderParking)
)

export const createOrderParking = async (orderParking) => (
  await api.post('/orderParking/create', orderParking)
)

export const getOrderParking = async (id) => (
  await api.post(`/orderParking/${id}`)
)

export const deleteOrderParking = async (id) => (
  await api.delete(`/orderParking/delete/${id}`)
)