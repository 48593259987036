<template>
  <div>
    <div id="header" class="header navbar-default">
      <div class="navbar-header">
        <router-link to="/" class="navbar-brand"
        ><img src="/assets/img/logo/logo-admin.png"
        /></router-link>
      </div>
      <div class="navbar-nav navbar-right">
        <CompanySelect 
          v-if="isAdmin"
          :value="filterCompany && filterCompany.name"
          @input="updateSelection"
          class="align-self-center mr-3" 
          all-option
          dark
        />
        <div v-else class="align-self-center text-white text-uppercase mr-3">
          {{  company ? company.name : '' }} {{ company ? '/' : '' }} {{  company ? company.subsidiary : '' }}
        </div>
        <div class="username align-self-center text-white text-uppercase">
          {{ user ? user.email : '' }}
        </div>
        <div id="right-buttons">
          <div class="d-flex">
            <a class="header__icon mr-2" title="Edit Profile" @click="$bvModal.show('profile-edit-modal')" href="#">
              <i class="fas fa-user fa-3x"></i>
            </a>
            <HeaderSettingsDropdown ref='headerSettingsDropdown' v-if="isAdmin" class="mr-2" /> 
            <a class="header__icon" title="Logout" @click="logout" href="#">
              <i class="fas fa-sign-out-alt fa-3x"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <ProfileEditModal @save="handleProfileUpdate"/>
  </div>
</template>

<script>
import Swal from 'sweetalert2'

import pageOptions from '../../config/pageOptions'
import CompanySelect from '@/components/CompanySelect'
import HeaderSettingsDropdown from './HeaderSettingsDropdown'
import ProfileEditModal from './modals/ProfileEditModal'
import { getCompany } from '@/services/companies'
import { editUser } from '@/services/users'
import { mapState, mapMutations, mapActions } from 'vuex'
import { SET_FILTER_COMPANY, FETCH_USER } from '@/store/user/constants'
import { FETCH_TRACKERS_COUNT, FETCH_ACTIVE_ALERTS_COUNT } from '@/store/main/constants'
import { FETCH_ACTIVE_ALERTS, FETCH_RESOLVED_ALERTS } from '@/store/alert/constants'
import { FETCH_ORDERS } from '@/store/order/constants'

export default {
  name: 'Header',
  components: { CompanySelect, HeaderSettingsDropdown, ProfileEditModal },
  data() {
    return {
      pageOptions: pageOptions,
      company: null,
    }
  },
  async mounted() {
    if (this.user && this.user.company) {
      this.company = (await getCompany(this.user.company)).data 
    }
  },
  watch: {
    '$route': {
      handler: function() {
        // Close dropdown after item click
        if (this.isAdmin) this.$refs.headerSettingsDropdown.$refs.dropdown.hide()
      },
      deep: true,
    }
  },
  computed: {
    ...mapState('user', ['user', 'filterCompany']),
    isAdmin() {
      return this.user?.role === 'admin'
    },
  },
  methods: {
    ...mapMutations('user', {
      setFilterCompany: SET_FILTER_COMPANY
    }),
    ...mapActions('user', {
      fetchUser: FETCH_USER,
    }),
    ...mapActions('main', {
      fetchTrackersCount: FETCH_TRACKERS_COUNT,
      fetchOrders: FETCH_ORDERS,
      fetchActiveAlertsCount: FETCH_ACTIVE_ALERTS_COUNT,
      fetchActiveAlerts: FETCH_ACTIVE_ALERTS,
      fetchResolvedAlerts: FETCH_RESOLVED_ALERTS
    }),
    ...mapActions('alert', {
      fetchActiveAlerts: FETCH_ACTIVE_ALERTS,
      fetchResolvedAlerts: FETCH_RESOLVED_ALERTS
    }),
    ...mapActions('order', {
      fetchOrders: FETCH_ORDERS,
    }),
    async logout() {
      const { isConfirmed } = await Swal.fire({
        text: 'Really log out?',
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-success btn-sm mr-2',
          denyButton: 'btn btn-danger btn-sm'
        },
        buttonsStyling: false
      })
      if (isConfirmed) {
        this.$router.push('/login')
      }
    },
    async handleProfileUpdate({ id, name, email, datetimeFormat }) {
      this.$bvModal.hide('profile-edit-modal')
      await editUser({ _id: id, name, email, datetimeFormat })
      await this.fetchUser(id)
    },
    async updateSelection(company) {
      this.setFilterCompany(company)
      await this.fetchTrackersCount()
      await this.fetchOrders()
      await this.fetchActiveAlertsCount()
      await this.fetchActiveAlerts()
      await this.fetchResolvedAlerts()
    }
  }
}
</script>
<style lang="scss">
  .header {
    .username {
      @media (max-width: 576px) {
        font-size: 9px;
      }
    }
    &__icon {
      color: #d3d3d3;
      &:hover {
        color: #808080;
      }
    }
  }
</style>