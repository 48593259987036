// Mutations
export const SET_ORDERS = 'SET_ORDERS'
export const SET_ORDER = 'SET_ORDER'
export const SET_ORDER_DETAILS = 'SET_ORDER_DETAILS'
export const SET_ORDER_ALERTS = 'SET_ORDER_ALERTS'
export const SET_ORDER_TEMPLATE = 'SET_ORDER_TEMPLATE'
export const SET_GEOFENCING_PLACES = 'SET_GEOFENCING_PLACES'

// Actions 
export const FETCH_ORDERS = 'FETCH_ORDERS'
export const FETCH_ORDER = 'FETCH_ORDER'
export const FETCH_ORDER_DETAILS = 'FETCH_ORDER_DETAILS'
export const FETCH_ORDER_ALERTS = 'FETCH_ORDER_ALERTS'
export const FETCH_ORDER_TEMPLATE = 'FETCH_ORDER_TEMPLATE'
export const FETCH_GEOFENCING_PLACES = 'FETCH_GEOFENCING_PLACES'

// Getters
export const ACTIVE_ORDERS = 'ACTIVE_ORDERS'