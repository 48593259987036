<template>
  <div class="users row justify-content-md-center">
    <div class="col-10">
      
      <table-basic :title="'Users'">
        <template slot="afterTitle">
          <button type="button" @click="openEditModal(null)" class="btn btn-orange ml-2 py-1 px-2">
            <i class="fas fa-plus-circle mr-2"></i> Create
          </button>
        </template>
        <template v-slot:thead>
          <tr class="cursor-pointer">
            <th>Name</th>
            <th>Email</th>
            <th>Company</th>
            <th>Role</th>
            <th>Last login</th>
          </tr>
        </template>

        <template v-slot:tbody>
          <tr v-for="user in filteredUsers" :key="user._id">
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.company &&  user.company.name }}</td>
            <td>{{ user.role }}</td>
            <td>{{ user.lastLogin }}</td>
            <td class="with-btn text-center">
              <button type="button" @click="openEditModal(user)" class="btn btn-sm btn-yellow">EDIT</button>
            </td>
            <td class="with-btn text-center">
              <button type="button" @click="deleteUser(user)" class="btn btn-sm btn-danger">DELETE</button>
            </td>
          </tr>
        </template>
      </table-basic>
    </div>
    <user-edit-modal @hidden="currentUser = null" :user="currentUser" @save="updateUser" />
  </div>
</template>

<script>
import Swal from 'sweetalert2'

import TableBasic from '@/components/tables/TableBasic.vue'
import UserEditModal from './modals/UserEditModal.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { FETCH_USER, FETCH_USERS, USER_DATETIME_FORMAT } from '@/store/user/constants'
import { deleteUser, register, editUser } from '@/services/users'
import moment from 'moment'


export default {
  data() {
    return {
      currentUser: null,
    }
  },
  components: { 
    TableBasic,
    UserEditModal
  },
  created() {
    this.refresh()
  },
  computed: {
    ...mapState('main', ['companies']),
    ...mapState('user', ['user', 'users', 'filterCompany']),
    ...mapGetters('user', [USER_DATETIME_FORMAT]),
    usersWithCompany() {
      return this.users.map(user => {
        return {
          ...user,
          lastLogin: user.lastLogin ? moment(user.lastLogin).format(this.USER_DATETIME_FORMAT) : "Haven't logged in yet.",
          company: this.companies.find(company => company._id === user.company) || null
        }
      })
    },
    filteredUsers() {
      const { _id: filterCompanyId = null } = this.filterCompany
      if (!filterCompanyId) return this.usersWithCompany
      return this.usersWithCompany.filter(user => user.company?._id === filterCompanyId)
    }
  },
  methods: {
    ...mapActions('user', {
      fetchUser: FETCH_USER,
      fetchUsers: FETCH_USERS
    }),
    openEditModal(user) {
      this.currentUser = user
      this.$bvModal.show('user-edit-modal')
    },
    async deleteUser(user) {
      const { isConfirmed } = await Swal.fire({
        html: `<span> Are you sure you want to delete this user: </span> <b>${user.name}</b> ?`,
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-success btn-sm mr-2',
          denyButton: 'btn btn-danger btn-sm'
        },
        buttonsStyling: false
      })
      if (isConfirmed) {
        await deleteUser(user._id)
        this.$toast('Success', {
          className: ['bg-success']
        })
        await this.refresh()
      }
    },
    async updateUser({ event, value }) {
      try {
        if (event === 'create') {
          const { name, email, password, role, company, datetimeFormat } = value 
          await register({ name, email, password, role, company: company?._id || null, datetimeFormat })
        } else if (event === 'edit') {
          const { _id, name, email, role, company, datetimeFormat } = value 
          await editUser({ _id, name, email, role, company: company?._id || null, datetimeFormat })
          if (this.user.id === _id) {
            await this.fetchUser(_id)
          }
        }
        this.$toast('Success', {
          className: ['bg-success']
        })
        this.$bvModal.hide('user-edit-modal')
        await this.refresh()
      } catch(e) {
        // Get error message from a response
        const { message = null } = e.response?.data
        if (message) {
          this.$toast(message, {
            className: ['et-alert']
          })
        } else {
          this.$toast('Server error', {
            className: ['et-alert']
          })
        }
      }
    },
    async refresh() {
      await this.fetchUsers()
    }
  }
}
</script>

<style lang="scss">
  .users {
    td {
      &.with-btn {
        width: 70px;
      }
    }
  }
</style>