import Vue from 'vue'
import Vuex from 'vuex'

import main from './main'
import user from './user'
import order from './order'
import tracker from './tracker'
import alert from './alert'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    main,
    user,
    order,
    tracker,
    alert,
  },
})
