<template>
  <div class="color-picker">
    <div @click="$bvModal.show(`color-picker-modal-${index}`)" class="color-picker__trigger" :style="{ backgroundColor: value }" />
    <b-modal
      size="sm"
      :id="`color-picker-modal-${index}`"
      :title="`Pick a color`"
      body-class="d-flex justify-content-md-center"
      hide-footer
    >
      <v-swatches :value="value" @input="val => $emit('onColorChange', val)" swatches="text-advanced" inline />
    </b-modal>
  </div>
</template>

<script>
import VSwatches from 'vue-swatches'

export default { 
  props: ['value', 'index'],
  components: {
    VSwatches
  },
}
</script>

<style lang="scss">
.color-picker {
  &__trigger {
    width: 28px;
    height: 28px;
    border-radius: 6px;
    cursor: pointer;
  }
}
</style>