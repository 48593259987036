export default [
  {
    'format': 'DD-MMM-YYYY HH:mm:ss',
    'example': '25-Mar-2021 13:44:35'
  },
  {
    'format': 'DD.MM.YYYY HH:mm:ss',
    'example': '25.05.2021 13:44:35'
  },
  {
    'format': 'DD/MM/YYYY HH:mm:ss',
    'example': '25/05/2021 13:44:35'
  },
  {
    'format': 'DD/MM/YYYY hh:mm:ss A',
    'example': '25/05/2021 01:44:35 PM'
  },
  {
    'format': 'DD/MM/YYYY hh:mm:ss a Z',
    'example': '25/05/2021 01:44:35 pm +02:00'
  },
  { 
    'format': 'DD/MM/YYYY HH:mm:ss Z',
    'example': '25/05/2021 13:44:35 +02:00'
  },
  {
    'format': 'MM/DD/YYYY HH:mm:ss',
    'example': '05/25/2021 13:44:35'
  },
  {
    'format': 'MM/DD/YYYY hh:mm:ss a Z',
    'example': '05/25/2021 01:44:35 pm +02:00'
  },
  {
    'format': 'MM/DD/YYYY HH:mm:ss Z',
    'example': '05/25/2021 13:44:35 +02:00'
  },
  {
    'format': 'MMMM DD, YYYY hh:mm:ss a Z',
    'example': 'March 25, 2021 01:44:35 pm +02:00'
  },
  {
    'format': 'YYYY-MM-DD hh:mm:ss a Z',
    'example': '2021-05-25 01:44:35 pm +02:00'
  },
  {
    'format': 'YYYY-MM-DD HH.mm.ss',
    'example': '2021-05-25 13.44.35'
  },
  {
    'format': 'YYYY.MM.DD hh:mm:ss a Z',
    'example': '2021.05.25 01:44:35 pm +02:00'
  },
  {
    'format': 'YYYY/MM/DD hh:mm:ss a Z',
    'example': '2021/05/25 01:44:35 pm +02:00'
  },
  {
    'format': 'YYYY/MM/DD HH:mm:ss Z',
    'example': '2021/05/25 13:44:35 +02:00'
  }
]