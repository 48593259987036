import api from './api'

export const getRouteInfos = async () => (
  await api.post('/routeInfo')
)

export const getRouteInfoByOrderId = async (orderId) => (
  await api.post(`/routeInfo/${orderId}`)
)

export const editRouteInfo = async (order, routeInfo) => (
  await api.post(`/routeInfo/update/${order._id}`, routeInfo)
)

export const createRouteInfo = async (routeInfo) => (
  await api.post('/routeInfo/create', routeInfo)
)

export const getRouteInfo = async (id) => (
  await api.post(`/routeInfo/${id}`)
)

export const deleteRouteInfo = async (id) => (
  await api.delete(`/routeInfo/delete/${id}`)
)