import mutations from './mutations'
import actions from './actions'

const state = {
  trackers: [],
  totalRecords: 0,
  tracker: {},
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}