<template>
  <b-modal 
    id="profile-edit-modal"
    size="lg"
    :title="`Edit Profile`"
    no-close-on-backdrop
  >
    <template v-if="userModel">
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Name</label>
        </div>
        <div class="col-5">
          <input class="form-control" v-model="userModel.name" placeholder="Enter name" />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Email</label>
        </div>
        <div class="col-5">
          <input class="form-control" :disabled="!isAdmin" v-model="userModel.email" @input="userModel.email=$event.target.value.toLowerCase()" placeholder="Enter email" />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Datetime Format</label>
        </div>
        <div class="col-5">
          <v-select 
            transition=""
            v-model="userModel.datetimeFormat"
            :options="datetimeFormats"
            label="format"
            :reduce="obj => obj.format"
            :clearable="false"
          >
            <template #option="{ format, example }">
              <h6 style="margin: 0">{{ format }}</h6>
              <em style="color: orange">{{ example }}</em>
            </template>
          </v-select>
        </div>
      </div>
    </template>
    <template #modal-footer="{ cancel }">
      <button class="btn" @click="cancel">Cancel</button>
      <button class="btn btn-success" @click="save">Save</button>
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'
import { mapState } from 'vuex'
import datetimeFormats from '@/config/datetimeFormats'

export default {
  data() {
    return {
      userModel: null,
      datetimeFormats,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    isAdmin() {
      return this.user?.role === 'admin'
    }, 
  },
  mounted() {
    this.userModel = _.cloneDeep(this.user)
  },
  methods: {
    validate() {
      // Basic validation
      if (!this.userModel.name) return 'Name is required'
      if (!this.userModel.email || !/\S+@\S+\.\S+/.test(this.userModel.email)) return 'Email is required and should be valid'
      return 
    },
    save() {
      this.$emit('save', this.userModel)
    }
  }
}
</script>

<style>

</style>