// Mutations
export const SET_COMPANIES = 'SET_COMPANIES'
export const SET_TRACKERS_COUNT = 'SET_TRACKERS_COUNT'
export const SET_ACTIVE_TRACKERS_COUNT = 'SET_ACTIVE_TRACKERS_COUNT'
export const SET_FREE_TRACKERS_COUNT = 'SET_FREE_TRACKERS_COUNT'
export const SET_ACTIVE_ALERTS_COUNT = 'SET_ACTIVE_ALERTS_COUNT'

// Actions 
export const FETCH_COMPANIES = 'FETCH_COMPANIES'
export const FETCH_TRACKERS_COUNT = 'FETCH_TRACKERS_COUNT'
export const FETCH_ACTIVE_ALERTS_COUNT = 'FETCH_ACTIVE_ALERTS_COUNT'
