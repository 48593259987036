import api from './api'

export const getOrders = async ({ companyId, excludeTestingCompany = false, countryCode }) => (
  await api.post('/orders', { companyId, excludeTestingCompany, countryCode })
)

export const getOrdersNames = async (companyId) => (
  await api.post('/orders/names', { companyId })
)

export const getOrder = async (id) => (
  await api.post(`/order/${id}`)
)

export const getOrderDetails = async (id) => (
  await api.post(`/order/detail/${id}`)
)

export const getOrderTrackers = async (id) => (
  await api.post(`/order/${id}/trackers`)
)

export const getOrderAlerts = async (id) => (
  await api.post(`/order/${id}/alerts`)
)

export const createOrder = async (payload) => (
  await api.post('/order/create', payload)
)

export const editOrder = async (order) => (
  await api.post(`/order/update/${order._id}`, order)
)

export const editOrderCompany = async (order, companyId) => (
  await api.post(`/order/updateCompany/${order._id}`, { companyId })
)

export const deleteOrder = async (order) => (
  await api.delete(`/order/delete/${order._id}`, order)
)