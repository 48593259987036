<template>
  <div class="row tracker-details justify-content-md-center">
    <div class="col-12">
      <b-overlay :show="isMapLoading" rounded="sm">
        <google-map 
          panelBodyClass="p-0" 
          mapClass="height-md" 
          :mapZoom="7" 
          :mapCenter="lastGooglePathMarker" 
        >
          <template slot="title">
            <span class="tracker-details__panel-title">{{ `Tracker detail ${!tracker.name ? '' : ' - '} ${tracker.name} for order `}}
              <router-link v-if="trackerOrder" :to="`/orders/${trackerOrder._id}`">{{ trackerOrder.name }}</router-link> 
            </span>
          </template>
          <template v-if="isAdmin" slot="gmapActions">
            <div class="checkbox ml-2 checkbox-css checkbox-inline">
              <input type="checkbox" id="googleCheckbox" v-model="showGoogleRoute" />
              <label for="googleCheckbox">Show Google route</label>
            </div>
            <div class="checkbox ml-2 checkbox-css checkbox-inline">
              <input type="checkbox" id="hereCheckbox" v-model="showHereRoute" />
              <label for="hereCheckbox">Show HERE route</label>
            </div>
            <div class="checkbox ml-2 checkbox-css checkbox-inline">
              <input type="checkbox" id="gpsCheckbox" v-model="showGpsRoute" />
              <label for="gpsCheckbox">Show GPS route</label>
            </div>
          </template>
          <template slot="content">
            <!-- Google path -->
            <gmap-polyline v-if="showGoogleRoute" :path.sync="trackerRouteGooglePath" v-bind:options="{ strokeColor: 'red' }"></gmap-polyline>
            <!-- HERE path -->
            <gmap-polyline v-if="showHereRoute" :path.sync="trackerRouteHerePath" v-bind:options="{ strokeColor: 'blue' }"></gmap-polyline>
            <gmap-polyline v-if="trackerHereApis && showGpsRoute" :path.sync="trackerRouteGpsPath" v-bind:options="{ strokeColor: 'purple' }"></gmap-polyline>
            <!-- Google last marker -->
            <GmapMarker 
              v-if="showGoogleRoute"
              :position="lastGooglePathMarker" 
              :clickable="true" 
              :draggable="false" 
              @click="center=lastGooglePathMarker"
            />
            <!-- HERE last marker -->
            <GmapMarker 
              v-if="showHereRoute"
              :position="lastHerePathMarker"
              :clickable="true" 
              :draggable="false"
              :icon="hereMarkerIcon"
              @click="center=lastHerePathMarker"
            />
            <!-- GPS last marker -->
            <GmapMarker 
              v-if="trackerHereApis && showGpsRoute"
              :position="lastGpsPathMarker"
              :clickable="true"
              :draggable="false" 
              :icon="gpsMarkerIcon"
              @click="center=lastGpsPathMarker"
            />
            <GmapMarker 
              v-if="finalDestinationMarker"
              :position="finalDestinationMarker.position"
              :clickable="true"
              :draggable="false"
              :icon="finalDestinationMarker.icon"
              @click="center=finalDestinationMarker"
            />
            <GmapMarker 
              v-if="locatorMarker"
              :position="locatorMarker.position"
              :clickable="true"
              :draggable="false"
              :icon="locatorMarkerIcon"
              @click="center=locatorMarker"
            />
            <gmap-info-window v-if="locatorMarker" :options="locatorMarkerInfoWindow" :position="locatorMarker.position" :opened="true">
            </gmap-info-window>
            <GmapMarker 
              :key="i" v-for="(m, i) in orderParkingsMarkers"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              @click="$router.push({name: 'order-parking-detail', params: {id: m.parkingId, index: i }})"
              :icon="m.icon"
              :zIndex="m.zValue"
            />
          </template>
        </google-map>
      </b-overlay>
      <b-overlay :show="isLoading" rounded="sm">
        <TableAdvanced 
          ref="trackerPositionsTable"
          :title="'Tracker positions' + (!tracker.name ? '' : ' - ' + tracker.name)" 
          mode="remote" 
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          :columns="columns" 
          :rows="trackerLocators"
          :paginationOptions="{ enabled: true}"
          :selectOptions="{ enabled: true, selectOnCheckboxOnly: true }"
          :searchOptions="{ 
            enabled: false,
          }"
          :isLoading="isLoading"
          :totalRows="totalRecords"
          :isWide="true"
        >
          <template slot="title">
            <span class="tracker-details__panel-title">{{ `Tracker positions ${!tracker.name ? '' : ' - '} ${tracker.name} for order `}}
              <router-link v-if="trackerOrder" :to="`/orders/${trackerOrder._id}`">{{ trackerOrder.name }}</router-link> 
            </span>
          </template>
          <template slot="outerPanelRow">
            <div class="date-filter d-flex">    
              <div class="date-filter__from d-flex">
                <div class="align-self-center mr-2">
                  <label class="mb-0">From</label>
                </div>
                <DateTimePicker 
                  class="form-control wider" 
                  v-model="startDate" 
                  :config="dateTimeOptions"
                  placeholder="Select datetime"
                />  
              </div>
              <div class="date-filter__to d-flex">
                <div class="align-self-center mr-2">
                  <label class="mb-0">To</label>
                </div>
                <DateTimePicker 
                  class="form-control wider" 
                  v-model="endDate" 
                  :config="dateTimeOptions"
                  placeholder="Select datetime"
                />  
              </div>
              <div class="align-self-center">
                <button type="button" @click="fetchTrackerLocators()" class="btn btn-sm btn-success">APPLY</button>
              </div>
            </div>
          </template>
          <template slot="afterTitle">
            <div v-if="isAdmin" class="checkbox ml-2 checkbox-css checkbox-inline">
              <input type="checkbox" id="showRawDataCheckbox" v-model="showRawData" />
              <label for="showRawDataCheckbox">Show Raw Data</label>
            </div>
            <span v-if="isAdmin" class="ml-3">Total wakeups: {{totalWakeups}}</span>
          </template>
          <template #rowActions="{ props }">
            <span v-if="props.column.field == 'gpsSource'">{{ locatorSourceMapping[props.row.gpsSource] }}</span>
            <span v-else-if="props.column.field == 'time.timestamp'">
              <span class="clickable" @click="showLocatorMarker(props.row)">{{ props.row.time.timestamp }}</span>
            </span>
            <span v-else-if="props.column.field == 'bandChannel' && props.row.netw">{{ `${props.row.netw.band || ''}/${props.row.netw.channel || ''}` }}</span>
          </template>
          <template #selectedRowActions>
            <button @click="deleteLocators" type="button" class="btn btn-sm btn-danger">DELETE</button>
          </template>
        </TableAdvanced>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters, mapActions } from 'vuex'
import { USER_DATETIME_FORMAT } from '@/store/user/constants'
import { FETCH_TRACKER } from '@/store/tracker/constants'
import { getOrder } from '@/services/orders'
import { getOrderParkingsByOrderId } from '@/services/orderParkings'
import { getTrackerLocators, getTrackerTotalWakeups } from '@/services/trackers'
import { deleteLocators } from '@/services/locators'
import { getTrackerHereApisByCcid, refreshRoute } from '@/services/trackerHereApi'
import TableAdvanced from '@/components/tables/TableAdvanced.vue'
import GoogleMap from '@/components/map/GoogleMap.vue'
import { gmapApi } from 'vue2-google-maps'
import DateTimePicker from 'vue-bootstrap-datetimepicker'

export default {
  components: {
    TableAdvanced,
    GoogleMap,
    DateTimePicker
  },
  data() {
    return {
      isMounted: false,
      showRawData: false,
      showGoogleRoute: false,
      showHereRoute: false,
      showGpsRoute: false,
      trackerOrder: null,
      trackerOrderParkings: [],
      trackerLocators: [],
      trackerHereApis: [],
      locatorMarker: null,
      isLoading: false,
      isMapLoading: false,
      startDate: null,
      endDate: null,
      dateTimeOptions: {
        useCurrent: false,
        sideBySide: true,
      },
      totalRecords: 0,
      totalWakeups: null,
      serverParams: {
        page: 1,
        limit: 10,
        columnFilters: {},
        // initial sort 
        sort: [{
          field: 'time.timestamp',
          type: 'desc'
        }]
      }
    }
  },
  methods: {
    ...mapActions('tracker', {
      fetchTracker: FETCH_TRACKER
    }),

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.fetchTrackerLocators()
    },

    onPerPageChange(params) {
      this.updateParams({ limit: params.currentPerPage })
      this.fetchTrackerLocators()
    },

    onSortChange(params) {
      this.updateParams({
        sort: params.map(param => ({
          type: param.type,
          field: param.field,
        }))
      })
      this.fetchTrackerLocators()
    },
    
    onColumnFilter(params) {
      this.updateParams(params)
      this.fetchTrackerLocators()
    },
    async refresh() {
      this.isLoading = true
      const { id } = this.$route.params
      await this.fetchTracker({ id })
      const { data: { expectedResponseCount = null } } = await getTrackerTotalWakeups({ ccid: this.tracker.ccid })
      this.totalWakeups = expectedResponseCount
      if (this.tracker?.orders[0]) {
        this.trackerOrder = (await getOrder(this.tracker?.orders[0])).data || null
        this.trackerOrderParkings = (await getOrderParkingsByOrderId(this.trackerOrder?._id)).data || []
      }
      this.isMapLoading = true
      await this.fetchTrackerLocators()
      this.isLoading = false
      this.trackerHereApis = (await getTrackerHereApisByCcid(this.tracker.ccid)).data || []
      this.isMapLoading = false
    },
    async deleteLocators() {
      const { selectedRows = [] } = this.$refs.trackerPositionsTable.$refs.tableAdvanced
      try {
        await deleteLocators({ locatorIds: selectedRows.map(row => row._id), ccid: this.tracker.ccid })
        await refreshRoute(this.tracker.ccid)
        await this.refresh()
        this.$toast('Successfully deleted', {
          className: ['bg-success']
        })
      } catch (e) {
        this.$toast('Unable to delete locators', {
          className: ['bg-danger']
        })
      }
    },
    async fetchTrackerLocators() {
      const { data: { docs = [], totalDocs = 0 } } = await getTrackerLocators({ 
        ccid: this.tracker.ccid,
        rawData: this.showRawData,
        ...this.serverParams,
        startDate: this.startDate,
        endDate: this.endDate 
      })
      this.trackerLocators = docs.map(tl => {
        return {
          ...tl,
          time: {
            shift: `${tl.time.shift}h`,
            timestamp: moment(tl.time.timestamp)
              .subtract(moment(tl.time.timestamp).isDST() && !this.isTr2Tr4Model ? 1 : 0, 'hours')
              .format(this.USER_DATETIME_FORMAT.replace('Z', '')),
          },
          gps: {
            ...tl.gps,
            accuracy: tl.gps?.accuracy === -1 ? 'GPS' : tl.gps?.accuracy
          }
        }
      })
      this.totalRecords = totalDocs
    },
    getTrackerMarkerPosition(index, gpsType) {
      if (this.selectedTrackerLocators && this.selectedTrackerLocators.length) {
        const trackerOnPosition = this.selectedTrackerLocators[index]?.[gpsType]
        // If no tracker on position -> no marker should be displayed
        if (!trackerOnPosition) return undefined
        return {
          lat: parseFloat(trackerOnPosition.location.latitude),
          lng: parseFloat(trackerOnPosition.location.longitude)
        }
      }
      return undefined
    },
    showLocatorMarker(locator) {
      const { location: { latitude = null, longitude = null } = {} } = locator?.gps
      this.locatorMarker = { position: { lat: latitude, lng: longitude }, title: locator.time.timestamp }
      window.scrollTo(0, 0)
    }
  },
  watch: {
    async 'showRawData' () {
      await this.fetchTrackerLocators()
    }
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('tracker', ['tracker']),
    ...mapGetters('user', [USER_DATETIME_FORMAT]),
    google: gmapApi,
    hereMarkerIcon() {
      return {
        url: '/assets/img/blue-gmap-marker.png',
        scaledSize: this.google && new this.google.maps.Size(50, 50)
      }
    },
    gpsMarkerIcon() {
      return {
        url: '/assets/img/purple-gmap-marker.png',
        scaledSize: this.google && new this.google.maps.Size(50, 50)
      }
    },
    finalDestinationMarker() {
      if (!this.trackerOrder) return undefined
      const { finalDestination: { gps: { latitude = null, longitude = null } = {} } = {} } = this.trackerOrder
      if (!latitude || !longitude) return undefined
      return {
        position: {
          lat: latitude,
          lng: longitude,
        },
        icon: {
          url: '/assets/img/svg/flag-marker.svg',
          scaledSize: this.google && new this.google.maps.Size(25, 25)
        }
      }
    },
    locatorMarkerIcon() {
      return {
        url: '/assets/img/yellow-gmap-marker.png',
        scaledSize: this.google && new this.google.maps.Size(50, 50)
      }
    },
    locatorMarkerInfoWindow() {
      return {
        content: 
              `
                <span style="font-size:11px; color: yellow; background-color: grey;">&nbsp;${this.locatorMarker.title}</span><br />
              `,
        pixelOffset: {
          width: 0,
          height: -15
        }
      }
    },
    orderParkingsMarkers() {
      const markers = []
      this.trackerOrderParkings.forEach(parking => {
        if (parking.location) {
          const { latitude, longitude } = parking.location
          const isLoadingType = parking?.parkingType.toLowerCase() === 'loading'
          if (latitude && longitude) markers.push({
            parkingId: parking._id,
            position: {
              lat: latitude,
              lng: longitude,
            },
            icon: {
              url: isLoadingType ? '/assets/img/svg/loading-marker.svg' : '/assets/img/svg/parking-gmap-marker.svg',
              scaledSize: this.google && new this.google.maps.Size(25, 25)
            },
            zValue: isLoadingType ? 99999 : 10,
          })
        }
      })
      return markers
    },
    isAdmin() {
      return this.user?.role === 'admin'
    },
    isTr2Model() {
      return this.tracker?.model === 'TR2' || false
    },
    isTr4Model() {
      return this.tracker?.model === 'TR4' || false
    },
    isTr2Tr4Model() {
      return this.tracker?.model === 'TR2' || this.tracker?.model === 'TR4' || false
    },
    selectedRows() {
      if (!this.isMounted) return []
      return this.$refs.trackerPositionsTable.$refs.tableAdvanced.selectedRows
    },
    dateFnsFormat() {
      return this.USER_DATETIME_FORMAT.replace('DD', 'dd').replace('YYYY', 'yyyy').replace('A', 'a').replace('Z', '')    
    },
    columns() {
      return [
        { label: 'Timestamp', sortable: false, type: 'date', dateInputFormat: this.dateFnsFormat, dateOutputFormat: this.dateFnsFormat, field: 'time.timestamp', tdClass: 'text-center text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Time Shift', field: 'time.shift', type: 'number', tdClass: 'text-center text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Alert', field: 'isAlert', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap', hidden: !this.isTr2Model },
        { label: 'Latitude', type: 'number', field: 'gps.location.latitude', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Longitude', type: 'number', field: 'gps.location.longitude', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Accuracy', type: 'number', field: 'gps.accuracy', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap', hidden: !this.isAdmin },
        { label: 'Cells count', type: 'number', field: 'cellsCount', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap', hidden: !this.isAdmin },
        { label: 'Type', field: 'gpsSource', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Band/Channel', field: 'bandChannel', hidden: !(this.isAdmin || this.isTr2Tr4Model), tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Battery Voltage', field: 'batteryVoltage', hidden: !(this.isAdmin || this.isTr4Model), tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Raw data', field: 'rawData', tdClass: 'valign-middle text-nowrap',	thClass: 'text-nowrap', hidden: !this.isAdmin || !this.showRawData, sortable: false },
      ]
    },
    selectedTrackerLocators() {
      if (this.selectedRows && this.selectedRows.length) return this.selectedRows
      return this.trackerLocators
    },
    trackerRouteGooglePath() {
      // If smth selected -> generate route based on selected rows
      return this.selectedTrackerLocators.map(trackerLocator => {
        return {
          lat: parseFloat(trackerLocator.gps.location.latitude),
          lng: parseFloat(trackerLocator.gps.location.longitude)
        }
      })
    },
    trackerRouteHerePath() {
      // If smth selected -> generate route based on selected rows
      return this.selectedTrackerLocators
        .filter(trackerLocator => trackerLocator.gpsHere)
        .map(trackerLocator => {
          return {
            lat: parseFloat(trackerLocator.gpsHere.location.latitude),
            lng: parseFloat(trackerLocator.gpsHere.location.longitude)
          }
        })
    },
    trackerRouteGpsPath() {
      if (!this.trackerHereApis || !this.trackerHereApis[0]) return 
      const { shapesString, shapes } = this.trackerHereApis[0]
      if (shapes) {
        return shapes.map(shape => {
          return {
            lat: parseFloat(shape.lat),
            lng: parseFloat(shape.lng)
          }
        })
      } else if (shapesString) {
        return shapesString.split(';').slice(0,-1).map(shape => {
          return {
            lat: parseFloat(shape.split(',')[0]),
            lng: parseFloat(shape.split(',')[1])
          }
        })
      }
      return undefined
    },
    lastGooglePathMarker() {
      return this.getTrackerMarkerPosition(this.selectedTrackerLocators.length - 1, 'gps')
    },
    lastHerePathMarker() {
      return this.getTrackerMarkerPosition(this.selectedTrackerLocators.length - 1, 'gpsHere')
    },
    lastGpsPathMarker() {
      if (!this.trackerHereApis || !this.trackerHereApis[0]) return undefined
      const { shapes = [], shapesString = '' } = this.trackerHereApis[0]
      if (shapes.length) {
        return {
          lat: parseFloat(shapes[shapes.length - 1].lat),
          lng: parseFloat(shapes[shapes.length - 1].lng)
        }
      } else if (shapesString) {
        const parsed = shapesString.split(';').slice(0,-1)
        return {
          lat: parseFloat(parsed[parsed.length - 1].split(',')[0]),
          lng: parseFloat(parsed[parsed.length - 1].split(',')[1])
        }
      }
      return undefined    
    },
    locatorSourceMapping() {
      return {
        GL: 'LTE - Google',
        GG:	'GSM - Google',
        HL:	'LTE - Here',
        HG:	'GSM - Here',
        TL:	'LTE - GPS',
        TG:	'GSM - GPS'
      }
    }
  },
  async created() {
    await this.refresh()
    if (this.trackerHereApis.length > 0) {
      this.showGpsRoute = true
    } else { 
      this.showGoogleRoute = true
    }
  },
  mounted() {
    this.isMounted = true
  }
}
</script>
<style lang="scss">
.tracker-details {
  .date-filter {
    width: 525px;
    justify-content: space-between;
  }
  &__panel-title {
    margin-right: 5px;

    a {
      color: white;
      text-decoration: underline;
    }
  }
}
</style>
