<template>
  <div class="row justify-content-md-center">
    <div class="col-12">
      <TableAdvanced
        class="firmwares-table"
        ref="firmwaresTable"
        title="TR1 Firmware List" 
        :columns="columns" 
        :rows="tr1Firmawares"
        :row-style-class="getRowStyleClass"
        :selectOptions="{ 
          enabled: true,
          selectOnCheckboxOnly: true
        }"
        :sortBy="{field: 'version', type: 'desc'}"
      >
        <template slot="afterTitle">
          <button type="button" @click="openUploadModal('TR1')" class="btn btn-orange ml-2 py-1 px-2">
            <i class="fas fa-file-upload mr-2"></i> Upload new TR1 firmware
          </button>
        </template>
        <template #selectedRowActions>
          <button @click="deleteFirmwares" type="button" class="btn btn-sm btn-danger">DELETE</button>
        </template>
        <template #rowActions="{ props }">
          <span v-if="props.column.field == 'updateTrackers'">
            <button @click="openUpdateTrackersModal(props.row)" type="button" class="btn btn-sm btn-primary">UPDATE TRACKERS</button>
          </span>
          <span v-else-if="props.column.field == 'edit'">
            <button @click="openEditModal(props.row)" type="button" class="btn btn-sm btn-primary">EDIT</button>
          </span>
        </template>
      </TableAdvanced>
      <TableAdvanced 
        class="firmwares-table"
        ref="firmwaresTable"
        title="TR2 Firmware List" 
        :columns="columns" 
        :rows="tr2Firmawares"
        :row-style-class="getRowStyleClass"
        :selectOptions="{ 
          enabled: true,
          selectOnCheckboxOnly: true
        }"
        :sortBy="{field: 'version', type: 'desc'}"
      >
        <template slot="afterTitle">
          <button type="button" @click="openUploadModal('TR2')" class="btn btn-orange ml-2 py-1 px-2">
            <i class="fas fa-file-upload mr-2"></i> Upload new TR2 firmware
          </button>
        </template>
        <template #selectedRowActions>
          <button @click="deleteFirmwares" type="button" class="btn btn-sm btn-danger">DELETE</button>
        </template>
        <template #rowActions="{ props }">
          <span v-if="props.column.field == 'updateTrackers'">
            <button @click="openUpdateTrackersModal(props.row)" type="button" class="btn btn-sm btn-primary">UPDATE TRACKERS</button>
          </span>
          <span v-else-if="props.column.field == 'edit'">
            <button @click="openEditModal(props.row)" type="button" class="btn btn-sm btn-primary">EDIT</button>
          </span>
        </template>
      </TableAdvanced>
    </div>
    <FotaUploadModal :initial-model="initialModel" @upload="uploadNewFirmware" />
    <FotaEditModal :fota="firmware" @hidden="firmware = null" @save="updateFirmware"/>
    <TrackerListModal :model="firmware && firmware.model" :version="firmware && firmware.version" @update="updateTrackersFirmware"/>
  </div>
</template>

<script>
import moment from 'moment'
import api from '@/services/api'
import { mapGetters } from 'vuex'
import { getFirmwares, editFirmware, deleteFirmwares } from '@/services/firmwares'
import { editTrackers } from '@/services/trackers'
import { USER_DATETIME_FORMAT } from '@/store/user/constants'
import TableAdvanced from '@/components/tables/TableAdvanced.vue'
import FotaUploadModal from './modals/FotaUploadModal.vue'
import FotaEditModal from './modals/FotaEditModal.vue'
import TrackerListModal from '@/components/TrackerListModal.vue'


export default {
  data() {
    return {
      firmwares: [],
      firmware: null,
      initialModel: null,
    }
  },
  components: {
    TableAdvanced,
    FotaUploadModal,
    FotaEditModal,
    TrackerListModal,
  },
  created() {
    this.refresh()
  },
  computed: {
    ...mapGetters('user', [USER_DATETIME_FORMAT]),
    dateFnsFormat() {
      return this.USER_DATETIME_FORMAT.replace('DD', 'dd').replace('YYYY', 'yyyy').replace('A', 'a').replace('Z', '')
    },
    tr1Firmawares() {
      return this.firmwares.filter(firmware => firmware.model === 'TR1')
    },
    tr2Firmawares() {
      return this.firmwares.filter(firmware => firmware.model === 'TR2')
    },
    columns() {
      return [
        { label: 'Model', field: 'model', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Version', field: 'version', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Link', field: 'link', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Command', field: 'command', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Note', field: 'note', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { 
          label: 'Created at', 
          field: 'createdAt', 
          type: 'date', 
          dateInputFormat: this.dateFnsFormat,
          dateOutputFormat: this.dateFnsFormat,
          tdClass: 'text-center valign-middle text-nowrap', 
          thClass: 'text-center text-nowrap' 
        },
        { 
          label: 'Updated at', 
          field: 'updatedAt',
          type: 'date', 
          dateInputFormat: this.dateFnsFormat,
          dateOutputFormat: this.dateFnsFormat,
          tdClass: 'text-center valign-middle text-nowrap', 
          thClass: 'text-center text-nowrap' 
        },
        { label: '', field: 'updateTrackers', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap', width: '50px', sortable: false },
        { label: '', field: 'edit', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap', width: '50px', sortable: false },

      ]
    },
  },
  methods: {
    openEditModal(firmware) {
      this.firmware = firmware
      this.$bvModal.show('fota-edit-modal')
    },
    openUploadModal(model) {
      this.initialModel = model
      this.$bvModal.show('fota-upload-modal')
    },
    openUpdateTrackersModal(firmware) {
      this.firmware = firmware
      this.$bvModal.show('tracker-list-modal')
    },
    getRowStyleClass(row) {
      if (row.isProduction) return 'is-production'
      return ''
    },
    async updateTrackersFirmware(trackers) {
      const trackersToUpdate = trackers.map(tracker => {
        // Remove unnecessary props
        return { 
          _id: tracker._id, 
          fota: {
            firmwareVersion: this.firmware.version,
            command: this.firmware.command,
            link: this.firmware.link,
            force: false,
            updateDate: new Date(),
          } 
        }
      })
      try {
        await editTrackers(trackersToUpdate)
        this.$toast('Update successful.', {
          className: ['bg-success']
        })
      } catch (error) {
        this.$toast('Update error', {
          className: ['et-alert']
        })
      }
      this.$bvModal.hide('tracker-list-modal')
    },
    async refresh() {
      const { data = [] } = await getFirmwares()
      this.firmwares = data.map(fw => {
        return {
          ...fw,
          updatedAt: moment(fw.updatedAt).format((this.USER_DATETIME_FORMAT).replace('Z', '')),
          createdAt: moment(fw.createdAt).format((this.USER_DATETIME_FORMAT).replace('Z', ''))
        }
      })
    },
    async uploadNewFirmware({ fota, file }) {
      const formData = new FormData()
      formData.append('model', fota.model)
      formData.append('version', fota.version)
      formData.append('command', fota.command)
      formData.append('note', fota.note)
      formData.append('file', file)
      formData.append('isProduction', fota.isProduction)
      try {
        await api({
          method: 'post',
          url: '/firmware/create',
          data: formData,
          headers: { 'Content-Type': 'multipart/form-data' },
        })
      } catch (error) {
        this.$toast('Error while uploading firmware', {
          className: ['bg-danger']
        })
        return
      }
      this.$toast('Success', {
        className: ['bg-success']
      })
      this.$bvModal.hide('fota-upload-modal')
      await this.refresh()
    },
    async updateFirmware(value) {
      await editFirmware(value)
      this.$toast('Success', {
        className: ['bg-success']
      })
      this.$bvModal.hide('fota-edit-modal')
      await this.refresh()
    },
    async deleteFirmwares() {
      const { selectedRows = [] } = this.$refs.firmwaresTable.$refs.tableAdvanced
      const isProduction = selectedRows.find(row => row.isProduction)
      if (isProduction) {
        this.$toast('Unable to delete production firmware', {
          className: ['bg-danger']
        })
        return
      }
      try {
        await deleteFirmwares({ firmwareIds: selectedRows.map(row => row._id) })
        await this.refresh()
        this.$toast('Successfully deleted', {
          className: ['bg-success']
        })
      } catch (e) {
        this.$toast('Unable to delete firmwares', {
          className: ['bg-danger']
        })
      }
    }
  }
}
</script>

<style lang="scss">
.firmwares-table {
  tr {
    &.is-production {
      span {
        color: #90ca4b;
      }
    }
  }
}
</style>