<template>
  <div class="row justify-content-md-center">
    <div class="col-10">
      <form>
        <panel v-if="order" :title="'Order Edit - ' + order.name">
          <template slot="afterTitle">
            <div class="row d-inline">
              <button class="btn btn-orange ml-4 py-1 px-2" title="Feature is temporary disabled" @click.prevent="openTemplatesModal" disabled>
                <i class="fas fa-plus-circle mr-2"></i>Templates
              </button>
            </div>
          </template>

          <div class="row mb-3 justify-content-md-center">
            <div class="col-2">
              <label>Email for notifications</label>
            </div>
            <div class="col-5">
              <input 
                v-model="order.notificationEmails"
                @input="order.notificationEmails=$event.target.value.toLowerCase()" 
                type="email" 
                class="form-control" 
                placeholder="Enter email" 
              />
            </div>
          </div>
          <div class="row mb-3 justify-content-md-center">
            <div class="col-2">
              <label>Origin Location</label>
            </div>
            <div class="col-5">
              <gmap-autocomplete
                type="search"
                :value="order.startLocation.address"
                class="form-control"
                placeholder="Type an address"
                @place_changed="setStartAddress"
              />
              <div v-if="order.startLocation.address" @click="clearStartAddress" class="autocomplete-clear-button">
                <i class="fas fa-lg fa-times-circle bg-white"></i>
              </div>
            </div>
          </div>
          <div class="row mb-3 justify-content-md-center">
            <div class="col-2">
              <label>Destination Location</label>
            </div>
            <div class="col-5">
              <gmap-autocomplete
                type="search"
                :value="order.finalDestination.address"
                class="form-control"
                placeholder="Type an address"
                @place_changed="setDestinationAddress"
              />
              <div v-if="order.finalDestination.address" @click="clearDestinationAddress" class="autocomplete-clear-button">
                <i class="fas fa-lg fa-times-circle bg-white"></i>
              </div>
            </div>
          </div>
          <div class="row mb-3 justify-content-md-center">
            <div class="col-2">
              <label>ETA</label>
            </div>
            <div class="col-5">
              <DateTimePicker class="form-control" v-model="order.eta" :config="dateTimeOptions" />
            </div>
          </div>
          <div class="row mb-3 justify-content-md-center">
            <div class="col-2">
              <label>Send order GPS position to email every (min)</label>
            </div>
            <div class="col-5">
              <v-select 
                class="period-check-select"
                v-model="order.etaPeriodCheckMins"
                :options="periodCheckOptions"
                :reduce="item => item.value"
                :clearable="false"
              />
            </div>
          </div>
          <div v-if="isAdmin" class="row mb-3 justify-content-md-center">
            <div class="col-2">
              <label>Status</label>
            </div>
            <div class="col-5">
              <v-select 
                class="status-select"
                v-model="order.status"
                :options="orderStatuses"
                :reduce="item => item.value"
                :clearable="false"
              />
            </div>
          </div>
          <div class="row mb-3 justify-content-md-center">
            <div class="col-2">
              <label>Delivered at</label>
            </div>
            <div class="col-5">
              <DateTimePicker class="form-control" v-model="order.deliveredAt" :config="dateTimeOptions" />
            </div>
          </div>
          <div class="row mb-3 justify-content-md-center">
            <div class="col-2">
              <label>Company</label>
            </div>
            <div class="col-5">
              <CompanySelect
                class="company-select" 
                v-model="newOrderCompany"
              />
            </div>
          </div>
          <div class="text-center mb-3">
            <button class="btn mr-3 btn-primary" @click.prevent="calculateRoute">
              Calculate Route
            </button>
            <button class="btn btn-danger" @click.prevent="clear">
              Clear Route
            </button>
          </div>
          <hr>
          <geofencing-place-table :order="order" />
          <hr>
          <geofencing-time-table :order="order" />
          <hr>
          <div class="text-center mb-3">
            <button type="submit" class="btn btn-success btn-save" @click.prevent="save">
              Save
            </button>
          </div>
          <div class="row mb-3 justify-content-md-center">
            <div class="col-12">
              <google-map ref="orderEditMap" panelBodyClass="p-0" mapClass="height-md" :mapCenter="mapCenter" :mapZoom="mapZoom">
                <template slot="content">
                  <GmapPolyline ref="polyline" :editable="true" :path="order.route" @path_changed="updatePath($event)" />
                  <GmapCircle 
                    :key="i" v-for="(place, i) in geofencingPlaces" 
                    :center="{lat: place.gps.latitude, lng: place.gps.longitude}" 
                    :radius="place.distance" 
                    :visible="true" 
                    :options="{fillColor:'red', fillOpacity:0.25, strokeWeight: 1,}" 
                  />
                </template>
              </google-map>
            </div>
          </div>
        </panel>
      </form>
    </div>
    <order-templates-modal :order="order" @transferOrderTemplate="transferOrderTemplate" />
  </div>  
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import { USER_DATETIME_FORMAT } from '@/store/user/constants'
import { getOrder, editOrder, editOrderCompany } from '@/services/orders'
import Panel from '../../plugins/panel/Panel.vue'
import CompanySelect from '@/components/CompanySelect'
import OrderTemplatesModal from '@/pages/OrderTemplates/OrderTemplatesModal.vue'
import GeofencingPlaceTable from './tables/GeofencingPlaceTable'
import GeofencingTimeTable from './tables/GeofencingTimeTable'
import GoogleMap from '@/components/map/GoogleMap.vue'
import DateTimePicker from 'vue-bootstrap-datetimepicker'

import { gmapApi } from 'vue2-google-maps'

export default {
  components: {
    Panel,
    CompanySelect,
    OrderTemplatesModal,
    GoogleMap,
    DateTimePicker,
    GeofencingPlaceTable,
    GeofencingTimeTable,
  },
  name: 'OrderEdit',
  data() {
    return {
      order: null,
      newOrderCompany: {},
      dateTimeOptions: null,
    }
  },
  async mounted() {
    this.dateTimeOptions = {
      format: this.USER_DATETIME_FORMAT,
      useCurrent: false,
      sideBySide: true
    }
    const { data: order = {} } = (await getOrder(this.$route.params.id))
    this.order = {
      finalDestination: {
        city: '',
        country: '',
        address: '',
      },
      startLocation: {
        city: '',
        country: '',
        address: '',
      },
      notificationEmails: '',
      etaPeriodCheckMins: 0,
      ...order,
      eta: order.eta ? moment(order.eta).format(this.USER_DATETIME_FORMAT) : '',
      deliveredAt: order.deliveredAt ? moment(order.deliveredAt).format(this.USER_DATETIME_FORMAT) : ''
    }
    if (!this.order.finalDestination.gps) {
      this.order.finalDestination.gps = {
        latitude: null,
        longitude: null,
      }
    }
    if (!this.order.startLocation.gps) {
      this.order.startLocation.gps = {
        latitude: null,
        longitude: null,
      }
    }
    this.initGmapService()
    this.newOrderCompany = this.companies
      .map(company => {
        return { _id: company._id, name: company.name }
      })
      .find(company => company._id === this.order.company)
  },
  computed: {
    google: gmapApi,
    ...mapState('user', ['user']),
    ...mapState('main', ['companies']),
    ...mapState('order', ['geofencingPlaces']),
    ...mapGetters('user', [USER_DATETIME_FORMAT]),
    isAdmin() {
      return this.user?.role === 'admin'
    },
    mapCenter() {
      const { route = [] } = this.order
      // Get the middle point of the route and center map to it 
      if (route.length) return route[Math.round((route.length - 1) / 2)] 
      // Calculate average middle between all geofencings
      if (this.geofencingPlaces.length) {
        const  middleLat = this.geofencingPlaces.reduce(function (sum, place) {
          return sum + parseFloat(place.gps.latitude)
        }, 0) / this.geofencingPlaces.length
        const  middleLng = this.geofencingPlaces.reduce(function (sum, place) {
          return sum + parseFloat(place.gps.longitude)
        }, 0) / this.geofencingPlaces.length
        if (middleLat && middleLng) {
          return {
            lat: middleLat,
            lng: middleLng
          }
        }
      }
      return undefined
    },
    mapZoom() {
      if (this.geofencingPlaces.length === 1) return 8
      if (this.geofencingPlaces.length > 1) {
        const distance = this.google.maps.geometry.spherical.computeDistanceBetween(
          new this.google.maps.LatLng(this.geofencingPlaces[0].gps.latitude, this.geofencingPlaces[0].gps.longitude), 
          new this.google.maps.LatLng(this.geofencingPlaces[this.geofencingPlaces.length - 1].gps.latitude, this.geofencingPlaces[this.geofencingPlaces.length - 1].gps.longitude)
        )
        const distanceInKm = distance / 1000
        if (distanceInKm < 100) return 7
        if (distanceInKm < 500) return 6
        if (distanceInKm < 1000) return 5
        if (distanceInKm < 3000) return 4
        return 2    
      }
      return 5
    },
    periodCheckOptions() {
      return [
        {
          label: 'Disabled',
          value: 0
        },
        {
          label: '30 min',
          value: 30
        },
        {
          label: '60 min',
          value: 60
        },
        {
          label: '90 min',
          value: 90
        },
        {
          label: '2 hours',
          value: 120
        },
        {
          label: '3 hours',
          value: 180
        },
        {
          label: '4 hours',
          value: 240
        },
        {
          label: '6 hours',
          value: 360
        },
        {
          label: '8 hours',
          value: 480
        },
        {
          label: '12 hours',
          value: 720
        },
        {
          label: '1 day',
          value: 1440
        },
        {
          label: '2 days',
          value: 2880
        },
      ]
    },
    orderStatuses() {
      return [
        { label: 'Delivered', value: 'Delivered' },
        { label: 'In-Transit', value: 'In-Transit' }, 
        { label: 'Loading', value: 'Loading' },  
      ]
    },
  },
  methods: {
    validateEmailString(emailString) {
      const emails = emailString.split(/[,;]+/)
      for (const email of emails) {
        if (!/\S+@\S+\.\S+/.test(email)) return `Invalid email: ${email}`
      }
      return false
    },
    initGmapService() {
      // Init DirectionsService once library has been loaded
      this.$gmapApiPromiseLazy().then(() => {
        this.$options.directionsService = new this.google.maps.DirectionsService() 
      })
    },
    getCityCountryFromAddress(address) {
      // Find city name
      let cityElement = null
      // Try to find 'locality' item
      cityElement = address.find(element => element.types.includes('locality'))
      // If 'locality' item was not found, try 'sublocality'
      if (!cityElement) {
        cityElement = address.find(element => element.types.includes('sublocality'))
      }
      // If also 'sublocality' item was not found, try 'postal_town'
      if (!cityElement) {
        cityElement = address.find(element => element.types.includes('postal_town'))
      }
      // If also 'postal_town' item was not found, try 'administrative_area_level_1'
      if (!cityElement) {
        cityElement = address.find(element => element.types.includes('administrative_area_level_1'))
      }
      // Find country name
      const countryElement = address.find(element => {
        if (element.types.includes('country')) return true
      })
      return { city: cityElement.long_name, country: countryElement.long_name }
    },
    setStartAddress(data) {
      const { city, country } = this.getCityCountryFromAddress(data.address_components)
      this.order.startLocation.city = city
      this.order.startLocation.country = country
      this.order.startLocation.address = data.formatted_address
      this.order.startLocation.gps.latitude = data.geometry.location.lat()
      this.order.startLocation.gps.longitude = data.geometry.location.lng()
    },
    setDestinationAddress(data) {
      const { city, country } = this.getCityCountryFromAddress(data.address_components)
      this.order.finalDestination.city = city
      this.order.finalDestination.country = country
      this.order.finalDestination.address = data.formatted_address
      this.order.finalDestination.gps.latitude = data.geometry.location.lat()
      this.order.finalDestination.gps.longitude = data.geometry.location.lng()
    },
    calculateRoute() {
      const { startLocation, finalDestination } = this.order
      // Calculate driving route from startLocation to finalDestination 
      if (startLocation.address && finalDestination.address) {
        this.$options.directionsService.route({
          origin: startLocation.address,
          destination: finalDestination.address,
          travelMode: 'DRIVING',
        }, (result, status) => {
        // If success -> set path and render Polyline
          if (status === 'OK') {
            this.order.route = result.routes[0].overview_path.map(i => ({ lat: i.lat(), lng: i.lng() }))
          } else {
            this.$toast('Error while calculating route', {
              className: ['bg-danger']
            })
          }
        })
      } else {
        this.$toast('Please provide a start location and destination', {
          className: ['bg-danger']
        })
      }
    },
    clearStartAddress() {
      this.order.startLocation.city = ''
      this.order.startLocation.country = ''
      this.order.startLocation.address = ''
      this.order.startLocation.gps = {
        latitude: null,
        longitude: null,
      }
    },
    clearDestinationAddress() {
      this.order.finalDestination.city = ''
      this.order.finalDestination.country = ''
      this.order.finalDestination.address = ''
      this.order.finalDestination.gps = {
        latitude: null,
        longitude: null,
      }
    },
    clear() {
      this.clearStartAddress()
      this.clearDestinationAddress() 
      // Clear Polyline from the map
      this.$refs.polyline.$polylinePromise.then((polyline) => {
        this.order.route = []
        polyline.setPath([])
      })
    },
    updatePath(event) {
      // Get updated path and store it 
      const path = event.Mb.map(i => ({ lat: i.lat(), lng: i.lng() }))
      this.order.route = [...path]
    },  
    async save() {
      if (this.order.eta) this.order.eta = (this.order.eta ? moment(this.order.eta, this.USER_DATETIME_FORMAT).format() : '')
      if (this.order.deliveredAt) this.order.deliveredAt = (this.order.deliveredAt ? moment(this.order.deliveredAt, this.USER_DATETIME_FORMAT).format() : '')
      if (this.order.notificationEmails) {
        const error = this.validateEmailString(this.order.notificationEmails)
        if (error) { 
          this.$toast(error, { className: ['et-alert'] })
          return
        }
      }
      await editOrder(this.order)
      if (this.newOrderCompany && this.newOrderCompany._id !== this.order.company) {
        await editOrderCompany(this.order, this.newOrderCompany._id)
      }
      this.$router.go(-1)
      this.$toast('Successfully saved', {
        className: ['bg-success']
      })
    },
    openTemplatesModal() {
      this.$bvModal.show('order-templates-modal')
    },
    transferOrderTemplate(orderTemplate) {
      if (orderTemplate) {
        this.order = {
          finalDestination: {
            city: orderTemplate.finalDestination?.city,
            country: orderTemplate.finalDestination?.country,
          },
          notificationEmails: orderTemplate?.notificationEmails
        }
      }
    }
  }
}
</script>
<style lang="scss">
.period-check-select, .status-select, .company-select {
	.vs__search {
		&::placeholder {
			background:#fff;
			border: none;
			color: #394066;
		}
	}
	.vs__dropdown-toggle {
		background: #fff;
		border: none;
		color: #394066;
	}
	.vs__dropdown-menu {
		background: #fff;
		border: none;
		color: #394066;
	}
	.vs__open-indicator {
		fill: #394066;
	}
}
</style>
