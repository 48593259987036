import Vue from 'vue'
import { SET_USER, SET_USERS, SET_LOGGED_IN, SET_FILTER_COMPANY } from './constants'

export default {
  [SET_USER](state, user) {
    Vue.set(state, 'user', user)
  },
  [SET_USERS](state, users) {
    Vue.set(state, 'users', users)
  },
  [SET_LOGGED_IN](state, loggedIn) {
    Vue.set(state, 'loggedIn', loggedIn)
  },
  [SET_FILTER_COMPANY](state, company) {
    Vue.set(state, 'filterCompany', company)
    localStorage.setItem(state?.user?.id, JSON.stringify(company))
  }
}