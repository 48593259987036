import api from './api'
import moment from 'moment'
import $store from '@/store'
import { USER_DATETIME_FORMAT } from '@/store/user/constants'

const datetimeFields = ['createdTime', 'startDatetime', 'endDatetime', 'resolvedTime']

function toServerTime(alert) {
  for (const field of datetimeFields) {
    if (!alert[field]) continue
    alert[field] = moment(alert[field], $store.getters[`user/${USER_DATETIME_FORMAT}`]).format()
  }
  return alert
} 

export const getAlerts = async ({ companyId, excludeTestingCompany = false }) => (
  await api.post('/alerts', { companyId, excludeTestingCompany })
)

export const getAlert = async (id) => (
  await api.post(`/alert/${id}`)
)

export const getActiveAlerts = async ({ companyId, excludeTestingCompany = false }) => (
  await api.post('/alerts/active', { companyId, excludeTestingCompany })
)

export const getResolvedAlerts = async ({ companyId, excludeTestingCompany = false }) => (
  await api.post('/alerts/resolved', { companyId, excludeTestingCompany })
)

export const getAlertsCount = async ({ companyId, excludeTestingCompany = false }) => (
  await api.post('/alerts/count', { companyId, excludeTestingCompany })
)

export const getActiveAlertsCount = async ({ companyId, excludeTestingCompany = false }) => (
  await api.post('/alerts/active/count', { companyId, excludeTestingCompany })
)

export const resolveAlert = async (id) => (
  await api.post(`/alert/update/${id}`, { isResolved: true, resolvedTime: new Date() })
)

export const unresolveAlert = async (id) => (
  await api.post(`/alert/update/${id}`, { isResolved: false, resolvedTime: null })
)

export const editAlert = async (alert) => (
  await api.post(`/alert/update/${alert._id}`, toServerTime(alert))
)