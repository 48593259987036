<template>
  <b-modal 
    id="update-tracker-firmware-modal"
    @hidden="resetFota"
    title="Update firmware"
  >
    <div class="row mb-3 justify-content-md-center">
      <div class="col-2 align-self-center">
        <label>Version</label>
      </div>
      <div class="col-5">
        <v-select
          transition=""
          v-model="newFota.firmwareVersion"
          @input="version => setFotaLink(version)"
          :options="fwVersionOptions"
          label="label"
          :clearable="false"
        />
      </div>
    </div>
    <div class="row mb-3 justify-content-md-center">
      <div class="col-2 align-self-center">
        <label>Command</label>
      </div>
      <div class="col-5">
        <input class="form-control" v-model="newFota.command" placeholder="Enter command" />
      </div>
    </div>
    <div class="row mb-3 justify-content-md-center">
      <div class="col-7 align-self-center">
        <div class="checkbox checkbox-css checkbox-inline">
          <input
            type="checkbox"
            id="forceFotaCheckbox"
            v-model="newFota.force"
          />
          <label for="forceFotaCheckbox">Force</label>
        </div>
      </div>
    </div>
    <template #modal-footer="{ cancel }">
      <button class="btn" @click="cancel">Cancel</button>
      <button class="btn btn-success" @click="update">Update</button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import { getFirmwareByModel } from '@/services/firmwares'
import moment from 'moment'

const defaultState = {
  firmwareVersion: '',
  command: '',
  link: '',
  force: false,
  updateDate: null,
}

export default {
  data() {
    return {
      newFota: { ...defaultState },
      firmwaresByModel: []
    }
  },
  watch: {
    tracker: {
      deep: true,
      immediate: true, 
      async handler (newVal) {
        if (newVal) {
          await this.refresh()
        }
      },
    }
  },
  computed: {
    ...mapState('tracker', ['tracker']),
    fwVersionOptions() {
      if (!this.firmwaresByModel || this.firmwaresByModel.length === 0) return []
      return this.firmwaresByModel.map(fw => fw.version)
    }
  },
  methods: {
    update() {
      this.newFota.updateDate = moment().format()
      this.$emit('update', { ...this.tracker, fota: { ...this.newFota } }) 
    },
    setFotaLink(version) {
      const firmware = this.firmwaresByModel.find(fw => fw.version === version)
      if (firmware) this.newFota.link = firmware.link
    },
    resetFota() {
      this.newFota = { ...defaultState }
    },
    async refresh() {
      this.firmwaresByModel = (await getFirmwareByModel(this.tracker.model)).data || []
    }
  }
}
</script>

<style>

</style>