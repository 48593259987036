import api from './api'

export const editGeofencing = async (geofencing) => (
  await api.post(`/geofencing/update/${geofencing._id}`, geofencing)
)

export const createGeofencing = async (geofencing) => (
  await api.post('/geofencing/create', geofencing)
)

export const getGeofencing = async (id) => (
  await api.post(`/geofencing/${id}`)
)

export const deleteGeofencing = async (id) => (
  await api.delete(`/geofencing/delete/${id}`)
)