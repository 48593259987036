<template>
  <div v-if="order">
    <div class="row justify-content-md-center">
      <div class="col-12">
        <google-map panelBodyClass="p-0" :panelTitle="'Order Details - ' + order.name" mapClass="height-md" :mapZoom="7" :mapCenter="mapCenter">
          <template slot="gmapActions">
            <button v-if="isAdmin" @click="$router.push(`/orders/${order._id}/edit`)" class="btn btn-orange ml-2 py-1 px-2">
              <i class="far fa-edit mr-2"></i>Edit
            </button>
            <div class="checkbox ml-2 checkbox-css checkbox-inline">
              <input type="checkbox" id="originalCheckbox" v-model="showOriginalRoute" />
              <label for="originalCheckbox">Show Original route</label>
            </div>
          </template>
          <template slot="content">
            <template v-for="tracker in showRouteTrackers">
              <GmapPolyline 
                :key="'route-' + tracker._id"
                :path.sync="tracker.hereApiRoute" 
                :options="{ strokeColor: tracker.color }" 
              />
              <GmapMarker 
                v-if="tracker.hereApiLastPosition"
                :key="'marker-' + tracker._id"
                :position="{lat: tracker.hereApiLastPosition.latitude, lng: tracker.hereApiLastPosition.longitude}"
                :icon="{url: '/assets/img/red-gmap-marker.png', scaledSize: google && new google.maps.Size(15, 25)}"
              />
            </template>
            <GmapPolyline v-if="showOriginalRoute" :path.sync="order.route" :options="{ strokeColor: 'yellow' }" />
            <GmapMarker 
              v-if="lastPositionMarker"
              :position="lastPositionMarker.position"
              :clickable="true"
              :draggable="false"
              :icon="lastPositionMarker.icon || null"
              @click="center=lastPosition"
            />
            <GmapMarker 
              v-if="finalDestinationMarker"
              :position="finalDestinationMarker.position"
              :clickable="true"
              :draggable="false"
              :icon="finalDestinationMarker.icon"
              @click="center=finalDestinationMarker"
            />
            <GmapMarker 
              :key="i" v-for="(m, i) in orderParkingsMarkers"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              @click="$router.push({name: 'order-parking-detail', params: {id: m.parkingId, index: i }})"
              :icon="m.icon"
              :zIndex="m.zValue"
            />
          </template>
        </google-map>
        <template v-if="!isOrderEmpty">
          <b-overlay :show="loading.orders" rounded="sm">
            <OrderDetailsTable />
          </b-overlay>
          <b-overlay :show="loading.trackers" rounded="sm">
            <table-basic :title="'Tracker List - ' + order.name">
              <template v-slot:thead>
                <tr class="cursor-pointer">
                  <th>Model</th>
                  <th style="min-width:100px">Tracker ID</th>
                  <th>Order List</th>
                  <th>Truck Pallete</th>
                  <th>Status</th>
                  <th>Last latitude</th>
                  <th>Last longitude</th> 
                  <th>Update Period (min)</th>
                  <th v-if="isAdmin">ICCID</th>
                  <th v-if="isAdmin">IMEI</th>
                  <th v-if="isAdmin">FW version</th>
                </tr>
              </template>
              <template v-slot:tbody>
                <tr v-for="(tracker, index) in trackers" :key="tracker._id">
                  <td>{{tracker.model}}</td>
                  <td>
                    {{tracker.name}}
                    <i v-if="tracker.hasDislocated" title="Tracker has dislocated alert" class="fas fa-info-circle text-red ml-1" />
                  </td>
                  <td>{{order.name}}</td>
                  <td>{{tracker.truckPallete}}</td>
                  <td>{{(tracker.isActive) ? "Active" : "Inactive"}}</td>
                  <td>{{tracker.locators[0] && tracker.locators[0].location ? tracker.locators[0].location.latitude : ''}}</td>
                  <td>{{tracker.locators[0] && tracker.locators[0].location ? tracker.locators[0].location.longitude : ''}}</td>
                  <td>{{Math.floor(tracker.alarmPeriod / 60)}}</td>
                  <td v-if="isAdmin">{{tracker.ccid}}</td>
                  <td v-if="isAdmin">{{tracker.imei}}</td>
                  <td v-if="isAdmin">{{tracker.firmwareVersion}}</td>
                  <!-- Hidden on customer request -->
                  <td v-show="false" class="with-btn row-btn">
                    <ColorPicker :index="index" :value="tracker.color" @onColorChange="val => tracker.color = val" /> 
                  </td>
                  <td v-show="false" class="with-btn row-btn">
                    <button 
                      type="button" 
                      @click="toggleTrackerVisibility(tracker)" 
                      class="btn btn-tracker-visibility btn-sm"
                      :class="{ 'btn-tracker-visibility--hidden': tracker.hidden, 'btn-tracker-visibility--shown': !tracker.hidden }"
                    >
                      {{ tracker.hidden ? 'SHOW' : 'HIDE' }}
                    </button>
                  </td>
                  <td class="with-btn row-btn">
                    <router-link :to="'/tracker-details/' + tracker._id">
                      <button type="button" class="btn btn-sm btn-primary">DETAIL</button>
                    </router-link>
                  </td>
                  <td v-if="isAdmin" class="with-btn row-btn">
                    <router-link :to="'/tracker-edit/' + tracker._id"><button type="button" class="btn btn-sm btn-yellow">EDIT</button></router-link>
                  </td>
                </tr>
              </template>
            </table-basic>
          </b-overlay>
          <b-overlay :show="loading.parkings" rounded="sm">
            <table-basic :title="'Parking List - ' + order.name">
              <template v-slot:thead>
                <tr class="cursor-pointer">
                  <th>Parking</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                </tr>
              </template>

              <template v-slot:tbody>
                <tr v-for="(parking, i) in orderParkings" :key="parking._id">
                  <td>{{ getParkingLabel(parking, i) }}</td>
                  <td>
                    <template v-if="parking.startDateTime">{{ parking.startDateTime | momentDST }}</template>
                  </td>
                  <td>
                    <template v-if="parking.stopDateTime">{{ parking.stopDateTime | momentDST }}</template>
                  </td>
                  <td class="with-btn row-btn">
                    <router-link :to="{name: 'order-parking-detail', params: {id: parking._id, index: i + 1 - loadingOrderParkings.length }}">
                      <button type="button" class="btn btn-sm btn-primary">DETAIL</button>
                    </router-link>
                  </td>
                </tr>
              </template>
            </table-basic>
          </b-overlay>
          <table-basic :title="'Route information - ' + order.name">
            <template v-slot:thead>
              <tr class="cursor-pointer">
                <th></th>
                <th>Status</th>
                <th>Location</th>
                <th>Created</th>
              </tr>
            </template>

            <template v-slot:tbody>
              <tr v-for="(info, i) in routeInfo" :key="info._id">
                <td>{{`Route Status #${i + 1}`}}</td>
                <td>
                  <template v-if="info.info">{{info.info}}</template>
                </td>
                <td>
                  <template v-if="info.location">Lat: {{info.location.latitude}} | Lon: {{info.location.longitude}}</template>
                </td>
                <td>
                  <template v-if="info.createdAt">{{info.createdAt | momentZox}}</template>
                </td>
              </tr>
            </template>
          </table-basic>
          <b-overlay :show="loading.orders" rounded="sm">
            <OrderAlertsTable />
          </b-overlay>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import ColorPicker from '@/components/ColorPicker'
import { mapState, mapActions } from 'vuex'
import { FETCH_ORDER, FETCH_ORDER_DETAILS, FETCH_ORDER_ALERTS } from '@/store/order/constants'
import { getOrderTrackers } from '@/services/orders'
import { getTrackerHereApisByCcid } from '@/services/trackerHereApi'
import { getOrderParkingsByOrderId } from '@/services/orderParkings'
import { getRouteInfoByOrderId } from '@/services/routeInfo'
import TableBasic from '@/components/tables/TableBasic.vue'
import GoogleMap from '@/components/map/GoogleMap.vue'
import OrderDetailsTable from './tables/OrderDetailsTable.vue'
import OrderAlertsTable from './tables/OrderAlertsTable.vue'
import { gmapApi } from 'vue2-google-maps'
import _ from 'lodash'

export default { 
  components: { 
    TableBasic,
    GoogleMap,
    ColorPicker,
    OrderDetailsTable,
    OrderAlertsTable,
  },
  data() {
    return {
      trackers: [],
      orderParkings: [],
      routeInfo: [],
      showOriginalRoute: false,
      loading: {
        orders: false,
        trackers: false,
        hereApi: false,
        parkings: false,
      }
    }
  },
  computed: {
    ...mapState('order', ['order']),
    ...mapState('user', ['user']),
    google: gmapApi,
    orderId() {
      return this.$route.params?.id
    },
    isAdmin() {
      return this.user?.role === 'admin'
    }, 
    isLoadingOrder() {
      if (this.order && this.order.status) {
        return this.order.status.toLowerCase() === 'loading'
      }
      return false
    },
    isOrderEmpty() {
      return _.isEmpty(this.order)
    },
    showRouteTrackers() {
      return this.trackers.filter(t => t.hereApiRoute && !t.hidden)
    },
    lastPositionMarker() {
      const { lastPosition = null } = this.order
      if (!lastPosition) return undefined
      // If order is loading and have only one loading parking, don't display it
      if (
        this.isLoadingOrder && 
        this.loadingOrderParkings.length 
        && this.loadingOrderParkings.length === this.orderParkingsMarkers.length
      ) {
        return undefined
      }
      const marker = {
        position: {
          lat: lastPosition?.latitude,
          lng: lastPosition?.longitude
        }
      }
      if (this.isLoadingOrder) {
        marker.icon = {
          url: '/assets/img/svg/loading-marker.svg',
          scaledSize: this.google && new this.google.maps.Size(25, 25)
        }
      } else {
        marker.icon = {
          url: '/assets/img/blue-gmap-marker.png',
          scaledSize: this.google && new this.google.maps.Size(25, 25)
        }
      }
      return marker
    },
    mapCenter() {
      const { lastPosition: { latitude, longitude } = {} } = this.order
      if (!latitude || !longitude) return undefined
      return {
        lat: latitude,
        lng: longitude
      }
    },
    finalDestinationMarker() {
      const { finalDestination: { gps: { latitude = null, longitude = null } = {} } = {} } = this.order
      if (!latitude || !longitude) return undefined
      return {
        position: {
          lat: latitude,
          lng: longitude,
        },
        icon: {
          url: '/assets/img/svg/flag-marker.svg',
          scaledSize: this.google && new this.google.maps.Size(25, 25)
        }
      }
    },
    loadingOrderParkings() {
      return this.orderParkings.filter(op => op?.parkingType.toLowerCase() === 'loading')
    },
    orderParkingsMarkers() {
      const markers = []
      this.orderParkings.forEach(parking => {
        if (parking.location) {
          const { latitude, longitude } = parking.location
          const isLoadingType = parking?.parkingType.toLowerCase() === 'loading'
          if (latitude && longitude) markers.push({
            parkingId: parking._id,
            position: {
              lat: latitude,
              lng: longitude,
            },
            icon: {
              url: isLoadingType ? '/assets/img/svg/loading-marker.svg' : '/assets/img/svg/parking-gmap-marker.svg',
              scaledSize: this.google && new this.google.maps.Size(25, 25)
            },
            zValue: isLoadingType ? 99999 : 10,
          })
        }
      })
      return markers
    }
  },
  async created() {
    await this.refresh()
  },
  methods: {
    ...mapActions('order', {
      fetchOrder: FETCH_ORDER,
      fetchOrderDetails: FETCH_ORDER_DETAILS,
      fetchOrderAlerts: FETCH_ORDER_ALERTS,
    }),
    getParkingLabel(parking, index) {
      return parking?.parkingType.toLowerCase() === 'loading' ? 'Loading' : `Parking #${index + 1 - this.loadingOrderParkings.length}`
    },
    setLoadingProperty(property, value) {
      Vue.set(this.loading, property, value)
    },
    getParsedShapes(shapes) {
      return shapes.map(shape => {
        return {
          lat: parseFloat(shape.lat),
          lng: parseFloat(shape.lng)
        }
      })
    },
    getParseShapesString(shapesString) {
      // Split by ';' and remove the last element to get correct data 
      return shapesString.split(';').slice(0,-1).map(shape => {
        return {
          lat: parseFloat(shape.split(',')[0]),
          lng: parseFloat(shape.split(',')[1])
        }
      })
    },
    async toggleTrackerVisibility(tracker) {
      tracker.hidden = !tracker.hidden
      if (!tracker.hereApiRoute || !tracker.hereApiRoute.length) {
        this.setLoadingProperty('hereApi', true)
        await this.fetchTrackerHereApiRoute(tracker)
        this.setLoadingProperty('hereApi', false)
      }
    },
    reset() {
      this.trackers = []
      this.orderParkings = []
      this.routeInfo = []
    },
    async fetchTrackerHereApiRoute(tracker) {
      const response = (await getTrackerHereApisByCcid(tracker.ccid)).data || []
      if (response && response[0]) {
        const { shapesString, shapes, lastPosition } = response[0]
        const hereApiRoute = shapesString ? this.getParseShapesString(shapesString) : this.getParsedShapes(shapes)
        Vue.set(tracker, 'hereApiRoute', hereApiRoute)
        if (tracker.hasDislocated) Vue.set(tracker, 'hereApiLastPosition', lastPosition)
      }
    },
    async fetchTrackers() {
      const trackers = (await getOrderTrackers(this.orderId)).data || []
      if (!trackers.length) return trackers
      const sortedTrackers = trackers.sort((t1, t2) => {
        return new Date(t2.lastReceivedDataAt) - new Date(t1.lastReceivedDataAt)
      })
      sortedTrackers[0].hidden = false
      sortedTrackers[0].color = '#0000ff'
      for (const tracker of trackers) {
        // If tracker has dislocated alert -> make it visible and draw with red line
        if (tracker.alerts?.length && tracker.alerts.find(alert => alert.state.toLowerCase() === 'dislocated')) {
          tracker.hidden = false 
          tracker.color = '#ff0000'
          tracker.hasDislocated = true
        }
        if (tracker.hidden !== false) {
          tracker.hidden = true
        }
      }
      return trackers
    },
    async processTrackersWithHereApi() {
      for (const tracker of this.trackers) {
        if (tracker.hidden === false) {
          await this.fetchTrackerHereApiRoute(tracker)
        } 
      }
    },
    async refresh() {
      const { id } = this.$route.params
      this.setLoadingProperty('orders', true)
      this.setLoadingProperty('trackers', true)
      this.setLoadingProperty('hereApi', true)
      this.setLoadingProperty('parkings', true)
      await Promise.allSettled([
        this.fetchOrder({ id }),
        this.fetchOrderDetails({ id }),
        this.fetchOrderAlerts({ id }),
      ])
      this.setLoadingProperty('orders', false)
      this.trackers = await this.fetchTrackers()
      this.setLoadingProperty('trackers', false)
      this.orderParkings = (await getOrderParkingsByOrderId(id)).data || []
      this.setLoadingProperty('parkings', false)
      await this.processTrackersWithHereApi() 
      this.setLoadingProperty('hereApi', false)
      this.routeInfo = (await getRouteInfoByOrderId(id)).data || []
    },
  },
  watch: {
    async '$route' () {
      this.reset()
      await this.refresh()
    }
  }
}
</script>
<style lang="scss" scoped>
.btn-tracker-visibility {
  color: white;

  &--hidden {
    background-color: green;
  }
  &--shown {    
    background-color: gray;
  }
}
</style>