<template>
  <div class="panel" :class="[{ 'panel-expand': expand, 'panel-loading': reload }, panelTheme, panelClass]" v-if="!remove">
    <div class="panel-heading">
      <slot name="header"></slot>
      <h4 class="panel-title" v-if="!hasHeaderSlot">
        <template v-if="hasTitleSlot">
          <slot name="title"></slot>
          <slot name="afterTitle"></slot>
        </template>
        <template v-else>
          {{ title }}
          <slot name="afterTitle"></slot>
        </template>
      </h4>
      <div class="panel-heading-btn" v-if="!noButton">
        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-default" @click="panelExpand"><i class="fa fa-expand"></i></a>
        <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-success" @click="panelReload"><i class="fa fa-redo"></i></a>
        <!-- <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-warning" @click="panelCollapse"><i class="fa fa-minus"></i></a> -->
        <!-- <a href="javascript:;" class="btn btn-xs btn-icon btn-circle btn-danger" @click="panelRemove"><i class="fa fa-times"></i></a> -->
      </div>
    </div>
    <slot name="beforeBody"></slot>
    <div class="panel-body" v-if="!noBody" v-show="!collapse" :class="bodyClass">
      <slot></slot>
      <div class="panel-loader" v-if="reload"><span class="spinner-small"></span></div>
    </div>
    <slot v-else></slot>
    <slot name="outsideBody"></slot>
    <div class="panel-footer" v-if="hasFooterSlot" :class="footerClass">
      <slot name="footer"></slot>
    </div>
    <slot name="afterFooter"></slot>
  </div>
</template>

<script>
export default {
  name: 'Panel',
  props: ['title', 'body', 'footer', 'variant', 'noBody', 'noButton', 'outsideBody', 'bodyClass', 'footerClass', 'panelClass'],
  data() {
    return {
      expand: false,
      collapse: false,
      remove: false,
      reload: false,
      panelTheme: (this.variant) ? 'panel-' + this.variant : 'panel-inverse'
    }
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer
    },
    hasHeaderSlot() {
      return !!this.$slots.header
    },
    hasTitleSlot() {
      return !!this.$slots.title
    }
  },
  methods: {
    panelExpand: function() {
      this.expand = !this.expand
    },
    panelCollapse: function() {
      this.collapse = !this.collapse
    },
    panelRemove: function() {
      this.remove = !this.remove
    },
    panelReload: function() {
      this.reload = true
      setTimeout(function() { 
        this.resetReload() 
      }.bind(this), 2000)
    },
    resetReload: function() {
      this.reload = false
    }
  }
}
</script>

<style>
  .panel {
    border-radius: 1em 1em 1em 1em !important;
  }
  
  .panel-heading {
    border-radius: 1em 1em 0em 0em !important;
  }

  .panel-body {
    border-radius: 0em 0em 1em 1em !important;
    background-color: #2d353c;
  }

  .panel-body label {
    color: white;
  }
</style>