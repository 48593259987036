<template>
  <b-nav-item-dropdown
    ref="dropdown"
    title="Settings"
    class="settings-dropdown"
  >
    <template slot="button-content">
      <a class="ml-0 settings-dropdown__icon" href="#"><i class="fas fa-cog fa-3x"></i></a>
    </template>
    <b-dropdown-item v-for="(item, i) in items" :key="i" @click="$router.push(item.path).catch(() => {})">
      <a class="ml-0 text-black text-decoration-none">{{ item.title }}</a>
    </b-dropdown-item>
  </b-nav-item-dropdown> 
</template>
<script>
export default {
  name: 'HeaderSettingsDropdown',
  data() {
    return {
      items: [
        {
          title: 'Users',
          path: '/users'
        },
        {
          title: 'Companies',
          path: '/companies'
        },
        {
          title: 'System Logs',
          path: '/logs'
        },
        {
          title: 'FOTA',
          path: '/fota'
        },
        {
          title: 'ZOX-SW',
          path: '/zox-sw'
        }
      ]
    }
  }
}
</script>
<style lang="scss">
.settings-dropdown {
  a.nav-link {
    padding: 0;
  }
  &__icon {
    color: #d3d3d3;
    &:hover {
      color: #808080;
    }
  }
  .dropdown-menu {
    font-size: 13px;
    .dropdown-item {
      margin-left: 0 !important;
    }
  } 
}
</style>