<template>
  <panel :bodyClass="panelBodyClass" :class="panelClass" :title="panelTitle">
    <template slot="title">
      <slot name="title"></slot>
    </template>
    <template slot="afterTitle">
      <slot name="gmapActions"></slot>
    </template>
    <GmapMap :class="'bg-black ' + mapClass" :zoom="mapZoom" :center="mapCenter" mapTypeId='hybrid'>
      <slot name="content"></slot>
    </GmapMap>
    <div class="gmap-footer"></div>
  </panel>
</template>

<script>
export default {
  name: 'google-map',
  props: {
    panelBodyClass: String,
    panelClass: String,
    panelTitle: String,
    mapClass: String,
    mapZoom: {
      type: Number,
      default: () => 4
    },
    mapCenter: {
      type: Object,
      default: () => {
        return {
          lat: 50.075539, lng: 14.437800
        }
      }
    }
  }
}
</script>

<style>
  .gmap-footer {
    height: 40px;
    background-color: #1d2226;
    border-radius: 0em 0em 1em 1em;
  }
</style>