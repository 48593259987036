<template>
  <b-modal
    size="xl"
    id="tracker-basic-info-logs-modal"
    footer-bg-variant="dark">
    <div class="row justify-content-md-center">
      <div class="col-12">
        <table-basic title="Tracker Basic Info Logs">
          <template v-slot:thead>
            <tr class="cursor-pointer">
              <th>Changed By</th>
              <th>Changed At</th>
              <th>Value</th>
            </tr>
          </template>

          <template v-slot:tbody>
            <tr v-for="{ _id, changedBy, changedAt, jsonSnapshot} in rows" :key="_id">
              <td>{{ changedBy }}</td>
              <td>{{ changedAt | momentZox }}</td>
              <td>{{ getFormattedLog(jsonSnapshot) }}</td>
            </tr>
          </template>
        </table-basic>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { getTrackerBasicInfoLogsByTrackerBasicInfo } from '@/services/trackerBasicInfoLogs'
import TableBasic from '@/components/tables/TableBasic.vue'

export default { 
  props: ['trackerBasicInfo'],
  components: {
    TableBasic
  },
  methods: {
    async refresh() {
      this.rows = (await getTrackerBasicInfoLogsByTrackerBasicInfo(this.trackerBasicInfo)).data || []
    },
    getFormattedLog(json) {
      const keysToDelete = ['_id', '__v', 'createdAt', 'updatedAt']
      const parsed = JSON.parse(json)
      for (const key in parsed) {
        if (keysToDelete.includes(key)) delete parsed[key]
      }
      return JSON.stringify(parsed).replace(/"/g, ' ')
    },
  },
  watch: {
    trackerBasicInfo: {
      deep: true,
      immediate: true, 
      handler (newVal) {
        if (newVal) {
          this.refresh()
        }
      }
    }
  },
  data() {
    return {
      rows: [],
    }
  },
  async mounted() {
    if (this.trackerBasicInfo) await this.refresh()
  }
}
</script>

<style lang="scss">
#tracker-basic-info-logs-modal {
  .modal-body, .modal-body .panel {
    margin: 0 !important;
    padding: 0 !important;
  }

  .panel {
    margin: 0;
  }

  .panel-body, .panel-heading {
    border-radius: 0em !important;
  }

  .modal-header, .modal-footer {
    display: none !important;
  }
}
</style>
