<template>
  <div class="row orders justify-content-md-center">
    <div class="col-12">
      <google-map v-if="countryCode" panelBodyClass="p-0" mapClass="height-md" :mapZoom="7" :mapCenter="mapCenter" :panelTitle="panelTitle">
        <template slot="content">
          <GmapMarker @click="$router.push(`/orders/${m.orderId}`)" :key="i" v-for="(m, i) in ordersLastPositionMarkers" :position="m.position" :clickable="true" :draggable="false"></GmapMarker>
        </template>
      </google-map>
      <template v-if="orders">
        <TableAdvanced  :title="panelTitle" :columns="columns" :rows="rows" :sortBy="{field: '_id', type: 'desc'}">
          <template slot="afterTitle">
            <div class="row d-inline">
              <button class="btn btn-orange ml-4 py-1 px-2" @click="openModal">
                <i class="fas fa-plus-circle mr-2"></i>New Order
              </button>
            </div>
          </template>
          <template #rowActions="{ props }">
            <span v-if="props.column.field == 'orderDetail'">
              <router-link :to="`/orders/${props.row._id}`">
                <button type="button" class="btn btn-sm btn-primary">DETAIL</button>
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'orderEdit'">
              <router-link :to="`/orders/${props.row._id}/edit`">
                <button type="button" class="btn btn-sm btn-yellow">EDIT</button>
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'orderDelete'">
              <span v-if="props.row.trackersCount !== 0" v-b-tooltip.hover title="Order cannot be deleted because of associated trackers">
                <b-button variant="danger" size="sm" disabled type="button">DELETE</b-button>
              </span>
              <span v-else>
                <b-button variant="danger" size="sm" type="button" @click="deleteOrder(props.row)">DELETE</b-button>
              </span>
            </span>
          </template>
        </TableAdvanced>
      </template>
    </div>
    <NewOrderModal modalId="new-order-modal" @created="onOrderCreated" />
  </div>
</template>

<script>
import Swal from 'sweetalert2'

import moment from 'moment'

import { mapState, mapActions, mapGetters } from 'vuex'
import { getOrders, deleteOrder } from '@/services/orders'
import TableAdvanced from '@/components/tables/TableAdvanced.vue'
import GoogleMap from '@/components/map/GoogleMap.vue'

import NewOrderModal from '@/components/modals/NewOrderModal'

import { FETCH_ORDERS } from '@/store/order/constants'
import { USER_DATETIME_FORMAT } from '@/store/user/constants'

export default {
  props: ['countryCode'],
  components: {
    TableAdvanced,
    GoogleMap,
    NewOrderModal
  },
  data() {
    return {
      activeOrdersByLocation: [],
    }
  },
  watch: {
    '$route': {
      handler: async function() {
        await this.refresh() 
      },
      immediate: true,
      deep: true,
    }
  },
  methods: {
    ...mapActions('order', {
      fetchOrders: FETCH_ORDERS,
    }),
    openModal() {
      this.$bvModal.show('new-order-modal')
    },
    async onOrderCreated() {
      this.$bvModal.hide('new-order-modal')
      await this.refresh()
    },
    async deleteOrder(order) {
      try {
        const { isConfirmed } = await Swal.fire({
          html: `<span> Are you sure you want to delete this order: </span> <b>${order.name}</b> ?`,
          showDenyButton: true,
          confirmButtonText: 'Yes',
          denyButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-success btn-sm mr-2',
            denyButton: 'btn btn-danger btn-sm'
          },
          buttonsStyling: false
        })
        if (isConfirmed) {
          await deleteOrder(order)
          await this.refresh()
          this.$toast('Successfully deleted', {
            className: ['bg-success']
          })
        }
      } catch (e) {
        this.$toast('Unable to delete order', {
          className: ['bg-danger']
        })
      }
    },
    async refresh() {
      // Fetch orders by location and display only active
      if (this.countryCode) {
        const { _id, excludeTestingCompany = false } = this.filterCompany
        // If user is admin -> use company from selector
        const company = this.user?.role === 'admin' ? _id : this.user?.company
        const ordersByLocation = (await getOrders({ companyId: company || null, excludeTestingCompany, countryCode: this.countryCode })).data || []
        if (ordersByLocation && ordersByLocation.length > 0) {
          this.activeOrdersByLocation = ordersByLocation.filter(order => order.status && order.status.toLowerCase() !== 'delivered')
        }
      } else {
        await this.fetchOrders()
      }
    }
  },
  computed: {
    ...mapState('main', ['companies']),
    ...mapState('order', ['orders']),
    ...mapState('user', ['user', 'filterCompany']),
    ...mapGetters('user', [USER_DATETIME_FORMAT]),
    isAdmin() {
      return this.user?.role === 'admin'
    },
    panelTitle() {
      if (this.countryCode) {
        return `All Active Orders - ${this.countryCode.toUpperCase()}`
      }
      return 'All Orders'
    },
    displayedOrders() {
      if (this.countryCode) return this.activeOrdersByLocation || []
      return this.orders
    },
    rows() {
      return this.displayedOrders.map(order => {
        const company = this.companies.find(company => company._id === order.company)
        return {
          finalDestination: {
            company: '',
            country: '',
            city: '',
            address: '',
          },
          startLocation: {
            country: '',
            city: '',
          },
          status: '',
          ...order,
          companyName: company?.name,
          subsidiary: company?.subsidiary,
          eta: order.eta ? moment(order.eta).format(this.USER_DATETIME_FORMAT) : ''
        }
      })
    },
    ordersLastPositionMarkers() {
      return this.displayedOrders.map(order => {
        return {
          orderId: order?._id,
          position: {
            lat: order?.lastPosition?.latitude,
            lng: order?.lastPosition?.longitude
          }
        }
      })
    },
    mapCenter() {
      if (!this.ordersLastPositionMarkers.length) return undefined
      return this.ordersLastPositionMarkers[0].position
    },
    columns() {
      return [
        { field: '_id', hidden: true },
        { label: 'Order Name', field: 'name',tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Origin Country', field: 'startLocation.country', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Origin City', field: 'startLocation.city', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Destination Country', field: 'finalDestination.country', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Destination City', field: 'finalDestination.city', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'ETA', field: 'eta', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Company', field: 'companyName', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap', hidden: !this.isAdmin },
        { label: 'Subsidiary', field: 'subsidiary', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap', hidden: !this.isAdmin },
        { label: 'Address', field: 'address', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Last Location', field: 'lastPosition.country', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: 'Status', field: 'status', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap' },
        { label: '', field: 'orderDetail', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap', sortable: false },
        { label: '', field: 'orderEdit', tdClass: 'valign-middle text-nowrap', thClass: 'text-center text-nowrap', sortable: false, hidden: !this.isAdmin },
        { label: '', field: 'orderDelete', tdClass: 'valign-middle text-nowrap', thClass: 'text-center text-nowrap', sortable: false, hidden: !this.isAdmin }
      ]
    },
  },
}
</script>
