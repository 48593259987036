import moment from 'moment'
import { getOrders, getOrder, getOrderDetails, getOrderAlerts } from '@/services/orders'
import { getOrderTemplate } from '@/services/orderTemplates'
import { 
  FETCH_ORDERS, FETCH_ORDER, FETCH_ORDER_DETAILS, FETCH_ORDER_ALERTS, FETCH_ORDER_TEMPLATE, FETCH_GEOFENCING_PLACES,
  SET_ORDERS, SET_ORDER, SET_ORDER_DETAILS, SET_ORDER_ALERTS, SET_ORDER_TEMPLATE, SET_GEOFENCING_PLACES
} from './constants'
import { getGeofencingPlaceByOrder } from '@/services/geofencingPlace'
import { USER_DATETIME_FORMAT } from '@/store/user/constants'

export default {
  async [FETCH_ORDERS]({ commit, rootState }) {
    const { user, filterCompany } = rootState.user 
    const { _id, excludeTestingCompany = false } = filterCompany
    // If user is admin -> use company from selector
    const company = user?.role === 'admin' ? _id : user?.company
    const { data = [] } = await getOrders({ companyId: company || null, excludeTestingCompany })
    commit(SET_ORDERS, data)
  },

  async [FETCH_ORDER]({ commit }, { id }) {
    const { data = [] } = await getOrder(id)
    commit(SET_ORDER, data)
  },

  async [FETCH_ORDER_DETAILS]({ commit }, { id }) {
    const { data = {} } = await getOrderDetails(id)
    commit(SET_ORDER_DETAILS, data)
  },

  async [FETCH_ORDER_ALERTS]({ commit, rootGetters }, { id }) {
    const userDatetimeFormat = rootGetters[`user/${USER_DATETIME_FORMAT}`]
    const { data = {} } = await getOrderAlerts(id)
    commit(SET_ORDER_ALERTS, data.map(aa => {
      return {
        ...aa,
        createdTime: moment(aa.createdTime).format(userDatetimeFormat),
        startDatetime: aa.startDatetime && moment(aa.startDatetime).format(userDatetimeFormat),
        endDatetime: aa.endDatetime && moment(aa.endDatetime).format(userDatetimeFormat),
        resolvedTime: null,
      }
    }))
  },

  async [FETCH_ORDER_TEMPLATE]({ commit }, { id }) {
    const { data = [] } = await getOrderTemplate(id)
    commit(SET_ORDER_TEMPLATE, data)
  },

  async [FETCH_GEOFENCING_PLACES]({ commit }, { orderId }) {
    const { data = [] } = await getGeofencingPlaceByOrder(orderId)
    commit(SET_GEOFENCING_PLACES, data)
  },
}