<template>
  <div>
    <TableAdvanced 
      ref="allTrackersTable" 
      :title="`${$route.params.status === 'active' ? 'Active' : 'All'} Trackers`"
      mode="remote" 
      @on-page-change="onPageChange"
      @on-per-page-change="onPerPageChange"
      :columns="columns" 
      :rows="trackers"
      :paginationOptions="{ enabled: true, perPageDropdown: [20, 50, 100, 500, 1000] }"
      :searchOptions="{ 
        enabled: false,
      }"
      :select-options="{ enabled: false }"
      :isLoading="isLoading"
      :totalRows="totalRecords"
    >
      <template slot="afterTitle">
        <span>{{ `(${trackerCountInfo})` }}</span>
      </template>
      <template #rowActions="{ props }">
        <template v-if="props.column.field == 'orderName'">
          <span :class="{'clickable': props.row.orders[0]}" v-on="props.row.orders[0] ? {click: () => $router.push(`/orders/${props.row.orders[0]}`)} : {}">
            {{ props.row.orderName }}
          </span>  
        </template>
        <template v-else-if="props.column.field == 'isActive'">
          <span>{{props.row.isActive ? "Active" : "Inactive"}}</span>
        </template>
        <template v-else-if="props.column.field == 'alarmPeriod'">
          <span>{{ `${Math.floor(props.row.alarmPeriod / 60)} minutes`}}</span>
        </template>
        <template v-else-if="props.column.field == 'state'">
          <span>{{ getTrackerStateLabel(props.row) }}</span>
        </template>
        <template v-else-if="props.column.field == 'simStatus'">
          <template v-if="getSimStatusBgColor(props.row) === 'circle-cross'">
            <i class="circle-cross far fa-2x fa-times-circle"></i>
          </template>
          <template v-else>
            <div class="circle" :class="getSimStatusBgColor(props.row)"></div>
          </template>
        </template>
        <template v-else-if="props.column.field == 'trackerDetail'">
          <router-link :to="'/tracker-details/' + props.row._id">
            <button :disabled="!props.row.ccid" type="button" class="btn btn-sm btn-primary">DETAIL</button>
          </router-link>
        </template>
        <template v-else-if="props.column.field == 'trackerEdit'">
          <router-link :to="'/tracker-edit/' + props.row._id"><button :disabled="!props.row._id" type="button" class="btn btn-sm btn-yellow">EDIT</button></router-link>
        </template>
        <template v-else-if="props.column.field == 'trackerDelete'">
          <div v-if="props.row.lastReceivedDataAt" v-b-tooltip.hover title="Tracker cannot be deleted because of associated locators">
            <button disabled type="button" class="btn btn-sm btn-danger">DELETE</button>
          </div>
          <template v-else>
            <button @click="deleteTracker(props.row)" type="button" class="btn btn-sm btn-danger">DELETE</button>
          </template>
        </template>
      </template>
    </TableAdvanced>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import { FETCH_TRACKERS } from '@/store/tracker/constants'
import { getTrackerLocatorsCount, deleteTracker } from '@/services/trackers'
import TableAdvanced from '@/components/tables/TableAdvanced.vue'


export default {
  components: { TableAdvanced },
  data() {
    return {
      isLoading: false,
      serverParams: {
        page: 1,
        limit: 20,
      }
    }
  },
  watch: {
    filterCompany: {
      async handler() {
        await this.refresh()
      },
      deep: true
    }
  },
  computed: {
    ...mapState('tracker', ['trackers', 'totalRecords']),
    ...mapState('main', ['trackersCount', 'activeTrackersCount', 'freeTrackersCount']),
    ...mapState('user', ['user', 'filterCompany']),
    columns() {
      return [
        { label: 'Model', field: 'model',tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', sortable: false },
        { label: 'Tracker ID', field: 'name', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', sortable: false },
        { label: 'Order List', field: 'orderName', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', sortable: false },
        { label: 'Truck Pallete', field: 'truckPallete', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', sortable: false },
        { label: 'Tracker Status', field: 'isActive', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', sortable: false },
        { label: 'Update Period', field: 'alarmPeriod', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', sortable: false },
        { label: 'State', field: 'state', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', sortable: false },
        { label: 'ICCID', field: 'ccid', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', hidden: !this.isAdmin, sortable: false },
        { label: 'IMEI', field: 'imei', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', hidden: !this.isAdmin, sortable: false },
        { label: 'FW version', field: 'firmwareVersion', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', hidden: !this.isAdmin, sortable: false }, 
        { label: 'SIM Status', field: 'simStatus', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap',  hidden: !this.isAdmin, sortable: false },
        { label: '', field: 'trackerDetail', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap', sortable: false },
        { label: '', field: 'trackerEdit', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap', hidden: !this.isAdmin, sortable: false },
        { label: '', field: 'trackerDelete', tdClass: 'text-center valign-middle text-nowrap', thClass: 'text-center text-nowrap', hidden: !this.isAdmin, sortable: false },
      ]
    },
    orders() {
      return this.$store.state.order.orders || []
    },
    isAdmin() {
      return this.user?.role === 'admin'
    },
    trackerCountInfo() {
      if (this.$route.params.status === 'active') return `Active/Total: ${this.activeTrackersCount}/${this.trackersCount}`
      return `Free/Total: ${this.freeTrackersCount}/${this.trackersCount}`
    }
  },
  created() {
    this.refresh()
  },
  methods: {
    ...mapActions('tracker', {
      fetchTrackers: FETCH_TRACKERS
    }),

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps)
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.refresh()
    },

    onPerPageChange(params) {
      this.updateParams({ limit: params.currentPerPage })
      this.refresh()
    },

    getTrackerStateLabel(tracker) {
      if (tracker.isActive) return 'OnRoute'
      if (tracker.orders.length) return 'Used'
      return 'Free' 
    },

    getSimStatusBgColor(tracker) {
      const { sim = null } = tracker
      // If there is no sim data for tracker -> display orange circle
      if (!sim) return 'bg-orange'
      // If sim is inactive -> display red circle
      if (!sim?.active) {
        // If sim is terminated -> display red cross icon
        if (sim.terminationDate && moment(sim.terminationDate).isBefore(new Date())) return 'circle-cross'
        else return 'bg-red'
      }
      // If credit's threshold is lower than 70% from limit or termination date is closer than 30 days -> display orange circle 
      if (
        (sim?.creditLimit - sim?.creditActual) < (sim?.creditLimit * 0.3) || 
        moment(sim?.terminationDate || null).subtract(30, 'days').isBefore(new Date())
      ) { 
        return 'bg-orange' 
      }
      return 'bg-green'
    },

    async deleteTracker({ ccid, _id }) {
      try {
        const { isConfirmed } = await Swal.fire({
          html: `<span> Are you sure you want to delete this tracker: </span> <b>${ccid}</b> ?`,
          showDenyButton: true,
          confirmButtonText: 'Yes',
          denyButtonText: 'No',
          customClass: {
            confirmButton: 'btn btn-success btn-sm mr-2',
            denyButton: 'btn btn-danger btn-sm'
          },
          buttonsStyling: false
        })
        if (isConfirmed) {
          const { data: locatorsCount = 0 } = await getTrackerLocatorsCount({ ccid })
          if (locatorsCount !== 0) {
            this.$toast('Tracker cannot be deleted because of associated locators', {
              className: ['et-alert']
            })
            return 
          }
          await deleteTracker({ _id })
          await this.refresh()
          this.$toast('Successfully deleted', {
            className: ['bg-success']
          })
        }
      } catch (e) {
        this.$toast('Unable to delete tracker', {
          className: ['bg-danger']
        })
      }
    },
    async refresh() {
      this.isLoading = true
      await this.fetchTrackers({ serverParams: this.serverParams })
      this.isLoading = false
    }
  }
}
</script>
<style lang="scss" scoped>
.circle {
  width: 24px;
  height: 24px;
  line-height: 20px;
  border-radius: 50%; /* the magic */
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  margin: 0 auto;
}
.circle-cross {
  color: #ff5b57;
}
</style>