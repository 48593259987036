import Vue from 'vue'
import { 
  SET_TRACKERS,
  SET_TRACKER,
  SET_TOTAL_RECORDS
} from './constants'

export default {
  [SET_TRACKERS](state, items) {
    Vue.set(state, 'trackers', items)
  },
  [SET_TOTAL_RECORDS](state, total) {
    Vue.set(state, 'totalRecords', total)
  },
  [SET_TRACKER](state, item) {
    Vue.set(state, 'tracker', item)
  },
}