<template>
  <panel class="table-basic bg-dark" :title="title" :tablePanelId="tablePanelId">
    <template slot="afterTitle">
      <slot name="afterTitle"></slot>
    </template>
    <div class="table-responsive">
      <table class="table text-white m-b-0" :id="tableId">
        <thead>
          <slot name="thead"></slot>
        </thead>
        <tbody>
          <slot name="tbody"></slot>
        </tbody>
      </table>
    </div>
  </panel>
</template>

<script>
export default {
  name: 'TableBasic',
  props: {
    title: String,
    tablePanelId: String,
    tableId: String
  }
}
</script>

<style lang="scss">
  .table-basic {
    .table {
      th, td {
        text-align: center;
      }
    }
  }
</style>