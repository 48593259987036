<template>
  <div class="companies row justify-content-md-center">
    <div class="col-10">
      
      <table-basic :title="'Companies'">
        <template slot="afterTitle">
          <button type="button" @click="openEditModal(null)" class="btn btn-orange ml-2 py-1 px-2">
            <i class="fas fa-plus-circle mr-2"></i> Create
          </button>
        </template>
        <template v-slot:thead>
          <tr class="cursor-pointer">
            <th>Name</th>
            <th>Parent Company</th>
            <th>Contact Phone</th>
            <th>Contact E-mail</th>
            <th>Address</th>
          </tr>
        </template>

        <template v-slot:tbody>
          <tr v-for="company in companiesWithParent" :key="company._id">
            <td>{{ company.name }}</td>
            <td>{{ (company.parentCompany && company.parentCompany.name) || 'None' }}</td>
            <td>{{ company.phone }}</td>
            <td>{{ company.email }}</td>
            <td>{{ company.address }}</td>
            <td class="with-btn text-center">
              <button type="button" @click="openEditModal(company)" class="btn btn-sm btn-yellow">EDIT</button>
            </td>
            <td class="with-btn text-center">
              <span v-if="!checkIfCompanyCanBeDeleted(company)" v-b-tooltip.hover title="Company cannot be deleted because of associated trackers, orders or users">
                <b-button variant="danger" size="sm" disabled type="button">DELETE</b-button>
              </span>
              <span v-else>
                <b-button variant="danger" size="sm" type="button" @click="deleteCompany(company)">DELETE</b-button>
              </span>
            </td>
          </tr>
        </template>
      </table-basic>
    </div>
    <company-edit-modal @hidden="currentCompany = null" :company="currentCompany" @save="updateCompany" />
  </div>
</template>

<script>
import Swal from 'sweetalert2'

import TableBasic from '@/components/tables/TableBasic.vue'
import CompanyEditModal from './modals/CompanyEditModal.vue'
import { mapState, mapActions } from 'vuex'
import { FETCH_COMPANIES } from '@/store/main/constants'
import { deleteCompany, createCompany, editCompany } from '@/services/companies'

export default {
  data() {
    return {
      currentCompany: null,
    }
  },
  components: { 
    TableBasic,
    CompanyEditModal
  },
  created() {
    this.refresh()
  },
  computed: {
    ...mapState('main', ['companies']),
    companiesWithParent() {
      return this.companies.map(company => {
        return {
          ...company,
          parentCompany: this.companies.find(item => item._id === company.parentCompany) || null
        }
      })
    }
  },
  methods: {
    ...mapActions('main', {
      fetchCompanies: FETCH_COMPANIES
    }),
    openEditModal(company) {
      this.currentCompany = company
      this.$bvModal.show('company-edit-modal')
    },
    checkIfCompanyCanBeDeleted(company) {
      const { trackersCount = 0, ordersCount = 0, usersCount = 0 } = company
      if (trackersCount == 0 && ordersCount == 0 && usersCount == 0) return true
      return false
    },
    async deleteCompany(company) {
      const { isConfirmed } = await Swal.fire({
        html: `<span> Are you sure you want to delete this company: </span> <b>${company.name}</b> ?`,
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-success btn-sm mr-2',
          denyButton: 'btn btn-danger btn-sm'
        },
        buttonsStyling: false
      })
      if (isConfirmed) {
        await deleteCompany(company._id)
        this.$toast('Success', {
          className: ['bg-success']
        })
        await this.refresh()
      }
    },
    async updateCompany({ event, value }) {
      if (event === 'create') {
        await createCompany(value)
      } else if (event === 'edit') {
        await editCompany(value)
      }
      this.$toast('Success', {
        className: ['bg-success']
      })
      this.$bvModal.hide('company-edit-modal')
      await this.refresh()
    },
    async refresh() {
      await this.fetchCompanies()
    }
  }
}
</script>

<style lang="scss">
  .companies {
    td {
      &.with-btn {
        width: 70px;
      }
    }
  }
</style>