<template>
  <b-modal 
    id="fota-edit-modal"
    @hidden="$emit('hidden')"
    title="Edit firmware"
  >
    <template v-if="fotaModel">
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Model</label>
        </div>
        <div class="col-5">
          <v-select
            transition=""
            disabled
            v-model="fotaModel.model"
            :options="['TR1', 'TR2']"
            label="label"
            :clearable="false"
          />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Version</label>
        </div>
        <div class="col-5">
          <input disabled class="form-control" v-model="fotaModel.version" placeholder="Enter version" />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-2 align-self-center">
          <label>Note</label>
        </div>
        <div class="col-5">
          <input class="form-control" v-model="fotaModel.note" placeholder="Enter note" />
        </div>
      </div>
      <div class="row mb-3 justify-content-md-center">
        <div class="col-7 align-self-center">
          <div class="checkbox checkbox-css checkbox-inline">
            <input type="checkbox" id="isProductionCheckbox" v-model="fotaModel.isProduction" />
            <label for="isProductionCheckbox">Is Production</label>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer="{ cancel }">
      <button class="btn" @click="cancel">Cancel</button>
      <button class="btn btn-success" @click="save">Save</button>
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'

export default {
  props: ['fota'],
  data() {
    return {
      fotaModel: null,
    }
  },
  watch: {
    fota: {
      deep: true,
      immediate: true, 
      handler (newVal) {
        if (newVal) {
          this.fotaModel = _.cloneDeep(newVal)
        }
      }
    },
  },
  methods: {
    save() {
      this.$emit('save', this.fotaModel) 
    }
  }
}
</script>

<style>

</style>