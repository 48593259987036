import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const state = {
  orders: [],
  order: {},
  orderDetails: {},
  orderAlerts: {},
  orderTemplate: {}, 
  geofencingPlaces: [],
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}