import api from './api'

export const getTrackers = async ({ status, companyId, excludeTestingCompany = false, page, limit }) => (
  await api.post(status === 'active' ? '/trackers/active' : '/trackers', { companyId, excludeTestingCompany, page, limit })
)

export const getTracker = async (_id) => (
  await api.post(`/tracker/${_id}`)
)

export const getTrackerByImei = async (imei) => (
  await api.post(`/trackerByImei/${imei}`)
)

export const getTrackersCount = async ({ companyId, excludeTestingCompany = false }) => (
  await api.post('/trackers/count', { companyId, excludeTestingCompany })
)

export const getActiveTrackersCount = async ({ companyId, excludeTestingCompany = false }) => (
  await api.post('/trackers/count/active', { companyId, excludeTestingCompany })
)

export const getFreeTrackersCount = async ({ companyId, excludeTestingCompany = false }) => (
  await api.post('/trackers/count/free', { companyId, excludeTestingCompany })
)

export const getTrackerLocators = async ({ ccid, rawData = false, page, limit, columnFilters, sort, startDate, endDate }) => (
  await api.post(`/tracker/${ccid}/locators`, { rawData, page, limit, columnFilters, sort, startDate, endDate })
)

export const getTrackerLocatorsCount = async ({ ccid }) => (
  await api.post(`/tracker/${ccid}/locators/count`)
)

export const editTracker = async (tracker) => (
  await api.post(`/tracker/update/${tracker._id}`, tracker)
)

export const editTrackers = async (trackers) => (
  await api.post('/trackers/update', { trackers })
)

export const deleteTracker = async (tracker) => {
  await api.delete(`/tracker/delete/${tracker._id}`)
}

export const getTrackerTotalWakeups = async ({ ccid }) => (
  await api.post(`/tracker/expectedResponseCount/${ccid}`)
)