<template>
  <div class="row justify-content-md-center">
    <div class="col-12">
      <form>
        <panel id="trackerEditPanel" :title="'Tracker edit' + (!tracker.name ? '' : ' - ' + tracker.name)">
          <template slot="afterTitle">
            <button type="button" @click="$bvModal.show('update-tracker-firmware-modal')" class="btn btn-orange ml-2 py-1 px-2">
              <i class="fas fa-file-upload mr-1"></i> Update firmware
            </button>
            <button v-if="isPendingFirmwareUpdate" type="button" @click="cancelUpdate" class="btn btn-danger ml-2 py-1 px-2">
              <i class="fas fa-times-circle mr-1"></i> Cancel update
            </button>
          </template>
          <div class="row mb-3">
            <div class="offset-1 col-1">
              <label>Tracker ID</label>
            </div>
            <div class="col-3">
              <input class="form-control" v-model="tracker.name">
            </div>
            <div class="offset-1 col-1">
              <label>Company</label>
            </div>

            <div class="col-3">
              <select class="col-12 form-control" v-if="tracker.company" v-model="tracker.company">
                <option v-for="company in companies" :key="company._id" :value="company._id">{{company.name}}</option>
              </select>
            </div>
          </div>

          <div class="row mb-3">
            <div class="offset-1 col-1">
              <label>ICCID</label>
            </div>
            <div class="col-3">
              <input class="form-control" v-model="tracker.ccid" readonly>
            </div>
            <div class="offset-1 col-1">
              <label>Order list</label>
            </div>
            
            <div class="col-3">
              <select class="col-12 form-control" v-if="tracker.orders" v-model="tracker.orders[0]">
                <option v-for="order in orders" :key="order._id" :value="order._id">{{order.name}}</option>
              </select>
            </div>
          </div>

          <div class="row mb-3">
            <div class="offset-1 col-1">
              <label>IMEI</label>
            </div>
            <div class="col-3">
              <input class="form-control" v-model="tracker.imei" readonly>
            </div>
            <div class="offset-1 col-1">
              <label>Standard time</label>
            </div>
            <div class="col-3">
              <input class="form-control" v-model="tracker.standardTime">
            </div>
          </div>
          <div class="row mb-3">
            <div class="offset-1 col-1">
              <label>FW version</label>
            </div>
            <div class="col-5">
              <span class="text-white">{{ firmwareLabel }}</span>
            </div>
          </div>

          <div class="row mb-3">
            <div class="offset-1 col-1">
              <label>Alarm period</label>
            </div>
            <div class="col-3">
              <input class="form-control" v-model="tracker.alarmPeriod">
            </div>
          </div>

          <div class="row mb-3">
            <div class="offset-1 col-1">
              <label>Active</label>
            </div>
            <div class="col-3">
              <select class="col-12 form-control" v-model="tracker.isActive">
                <option :value="true">true</option>
                <option :value="false">false</option>
              </select>
            </div>  
          </div>
          <div v-if="tracker.model === 'TR2' || tracker.model === 'TR4'" class="row mb-3">
            <div class="offset-1 col-1">
              <label>Use GPS</label>
            </div>
            <div class="col-3">
              <select class="col-12 form-control" v-model="tracker.useGps">
                <option :value="true">true</option>
                <option :value="false">false</option>
              </select>
            </div>  
          </div>
          <hr>
          <div class="text-center">
            <button type="submit" class="btn btn-success btn-save" @click.prevent="save">
              Save
            </button>
          </div>
        </panel>
        <SimManagementPanel />
      </form>
      <google-map v-show="false" panelBodyClass="p-0" mapClass="height-md">
        <template slot="content">
          <GmapCircle :center="finalPosition" :radius="diameter" :visible="true" :options="{fillColor:'red', fillOpacity:1.0}"></GmapCircle>
          <GmapCircle :center="finalPosition" :radius="20000" :visible="true"></GmapCircle>
          <GmapCircle :center="finalPosition" :radius="50000" :visible="true"></GmapCircle>
          <GmapCircle :center="finalPosition" :radius="100000" :visible="true"></GmapCircle>
          <GmapMarker :position="finalPosition" :clickable="true" :draggable="true" @click="center=finalPosition" @dragend="updateCoordinates"></GmapMarker>
        </template>
      </google-map>
      
      <table-basic v-show="false" title="Geofencing - final">
        <template slot="afterTitle">
          <button v-show="tracker.geofencings !== undefined && tracker.geofencings.length == 0" class="btn btn-orange ml-4 py-1 px-2" @click="addGeofencing">
            <i class="fas fa-plus-circle mr-2"></i>New position
          </button>
        </template>
        <template v-slot:thead>
          <tr class="cursor-pointer">
            <!--<th>Enabled</th>-->
            <th>Name</th>
            <th>Address</th>
            <th>Email</th>
            <th>Latitude</th>
            <th>Longitude</th>
            <th>Radius (m)</th>
          </tr>
        </template>

        <template v-slot:tbody>
          <tr v-for="geofencing in tracker.geofencings" :key="geofencing.ccid">
            <td><input v-model="geofencing.name" class="editableElement" placeholder="..." /></td>
            <td><input v-model="geofencing.address" class="editableElement" placeholder="..." /></td>
            <td><input v-model="geofencing.email" @input="geofencing.email=$event.target.value.toLowerCase()" class="editableElement" placeholder="..." /></td>
            <td><input v-model="geofencing.gps.latitude" @change="setCoordinatesToMap(geofencing.gps.latitude, geofencing.gps.longitude)" class="editableElement" placeholder="..." /></td>
            <td><input v-model="geofencing.gps.longitude" @change="setCoordinatesToMap(geofencing.gps.latitude, geofencing.gps.longitude)" class="editableElement" placeholder="..." /></td>
            <td><input v-model="geofencing.diameter" @change="setDiameter(geofencing.diameter)" class="editableElement" placeholder="..." /></td>
            <td class="with-btn row-btn">
              <button type="button" class="btn btn-success" @click="saveGeofencing(geofencing)">SAVE</button>
            </td>
            <td class="with-btn row-btn">
              <button type="button" class="btn btn-danger" @click="deleteGeofencing(geofencing)">DELETE</button>
            </td>
          </tr>
        </template>
      </table-basic>
    </div>
    <update-tracker-firmware-modal :tracker="tracker" @update="updateFirmware"/>
  </div>
</template>

<script>
import moment from 'moment'
import Swal from 'sweetalert2'
import TableBasic from '@/components/tables/TableBasic.vue'
import SimManagementPanel from './components/SimManagementPanel'
import UpdateTrackerFirmwareModal from './modals/UpdateTrackerFirmwareModal'
import { editTracker } from '@/services/trackers'
import { editGeofencing, createGeofencing, getGeofencing, deleteGeofencing } from '@/services/geofencings.js'
import GoogleMap from '@/components/map/GoogleMap.vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import { FETCH_TRACKER } from '@/store/tracker/constants'
import { USER_DATETIME_FORMAT } from '@/store/user/constants'
import { getOrders } from '@/services/orders'

export default {
  components: {
    TableBasic,
    GoogleMap,  
    UpdateTrackerFirmwareModal,
    SimManagementPanel
  },
  data() {
    return {
      finalPosition: {
        lat: this.tracker && this.tracker.geofencings !== undefined ? this.tracker.geofencings[0].gps.latitude : 50.075539,
        lng: this.tracker && this.tracker.geofencings !== undefined ? this.tracker.geofencings[0].gps.longitude : 14.437800
      },
      diameter: this.tracker !== undefined ? this.tracker.diameter : 0,
      orders: null, 
    }
  },
  computed: {
    ...mapState('main', ['companies']),
    ...mapState('tracker', ['tracker']),
    ...mapGetters('user', [USER_DATETIME_FORMAT]),
    isPendingFirmwareUpdate() {
      return this.tracker.fota && this.tracker.fota.firmwareVersion
    },
    firmwareLabel() {
      if (this.isPendingFirmwareUpdate) {
        return `${this.tracker.firmwareVersion} - pending update ${this.tracker.fota.firmwareVersion} from ${this.parseDatetime(this.tracker.fota.updateDate)}`
      }
      if (this.tracker.firmwareUpdatedAt) {
        return `${this.tracker.firmwareVersion} -  updated at ${this.parseDatetime(this.tracker.firmwareUpdatedAt)}`
      }
      return this.tracker.firmwareVersion
    },
  },
  watch: {
    '$route': {
      handler: async function() {
        await this.refresh() 
      }
    }
  },
  methods: {
    async save() { 
      if (this.tracker.isActive == 'false ')
        this.tracker.isActive = false
      await editTracker(this.tracker)
      this.$router.go(-1)
      this.$toast('Successfully saved', {
        className: ['bg-success']
      })
    },
    parseDatetime(datetime) {
      if (!datetime) return ''
      return moment(datetime).format(this.USER_DATETIME_FORMAT)
    },
    ...mapActions('tracker', {
      fetchTracker: FETCH_TRACKER
    }),
    async saveGeofencing() {
      if (this.tracker.geofencings.length !== 0) {
        if (this.tracker.geofencings[0]._id != null && getGeofencing(this.tracker.geofencings[0]._id) !== null) {
          await editGeofencing(this.tracker.geofencings[0])
        }
        else {
          this.tracker.geofencings[0]._id = (await createGeofencing(this.tracker.geofencings[0])).data._id
        }

        this.tracker.finalDestination = this.tracker.geofencings[0]._id
        await editTracker(this.tracker)

        this.$toast('Successfully saved', {
          className: ['bg-success']
        })
      }
    },
    async deleteGeofencing() {
      if (this.tracker.geofencings[0]._id)
        await deleteGeofencing(this.tracker.geofencings[0]._id)

      this.tracker.geofencings.splice(0, 1)
      this.tracker.finalDestination = null
      await editTracker(this.tracker)

      this.$toast('Successfully deleted', {
        className: ['bg-success']
      })
    },
    updateCoordinates(location) {
      this.addGeofencing()

      this.tracker.geofencings[0].gps.latitude = location.latLng.lat()
      this.tracker.geofencings[0].gps.longitude = location.latLng.lng()

      this.finalPosition = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng()
      }
    },
    setCoordinatesToMap(lat, lng) {
      this.finalPosition.lat = parseFloat(lat)
      this.finalPosition.lng = parseFloat(lng)
    },
    setDiameter(diameter) {
      this.diameter = parseInt(diameter)
    },
    addGeofencing() {
      if (this.tracker.geofencings.length === 0) {
        this.tracker.geofencings.push({
          id_: null,
          ccid: this.tracker.ccid,
          enabled: true,
          address: null,
          email: '',
          name: '',
          gps: {
            latitude: null,
            longitude: null
          },
          diameter: 0,
          finalDestination: null
        })
      }
    },
    async updateFirmware(value) {
      await editTracker(value)
      await this.refresh()
      this.$toast('Success', {
        className: ['bg-success']
      })
      this.$bvModal.hide('update-tracker-firmware-modal')
    },
    async cancelUpdate() {
      const { isConfirmed } = await Swal.fire({
        text: `Are you sure you want to cancel pending firmware update to ${this.tracker.fota.firmwareVersion} ?`,
        showDenyButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: 'No',
        customClass: {
          confirmButton: 'btn btn-success btn-sm mr-2',
          denyButton: 'btn btn-danger btn-sm'
        },
        buttonsStyling: false
      })
      if (isConfirmed) {
        try {
          await editTracker({ ...this.tracker, fota: null })
          this.$toast('Successfully canceled', {
            className: ['bg-success']
          })
          await this.refresh()
        } catch (e) {
          this.$toast('Error while canceling update', {
            className: ['bg-danger']
          })
        }
      }
    },
    async refresh() {
      // Fetch orders by company
      this.orders = (await getOrders({ companyId: this.tracker.company })).data
      const { id } = this.$route.params
      await this.fetchTracker({ id })
    },
  },
  async created() {
    await this.refresh()

    this.finalPosition = {
      lat: this.tracker && this.tracker.geofencings && this.tracker.geofencings[0] !== undefined ? parseFloat(this.tracker.geofencings[0].gps.latitude) : 50.075539,
      lng: this.tracker && this.tracker.geofencings && this.tracker.geofencings[0] !== undefined ? parseFloat(this.tracker.geofencings[0].gps.longitude) : 14.437800
    }

    this.diameter = this.tracker && this.tracker.geofencings && this.tracker.geofencings[0] !== undefined ? this.tracker.geofencings[0].diameter : 0
  }
}
</script>

<style>
.btn-save {
  width: 150px;
}
</style>
