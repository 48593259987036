<template>
  <div>
    <div class="row justify-content-md-center">
      <div class="col-12">
        <TableAdvanced v-if="activeAlerts" title="Active alerts" :columns="columnsActives" :rows="activeAlerts" :sortBy="{field: 'createdTime', type: 'desc'}">
          <template #rowActions="{ props }">
            <span v-if="props.column.field == 'alertDetail'">
              <router-link :to="{ path: `/alerts/${props.row._id}`,}">
                <button type="button" class="btn btn-xs btn-primary">DETAIL</button>
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'trackerOrderName'">
              <router-link v-if="props.row.trackerOrder !== undefined" :to="'/orders/' + props.row.trackerOrder">
                <label class="label-router-link mb-0">{{props.row.trackerOrderName}}</label>
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'trackerName'">
              <router-link v-if="props.row.trackers.length > 0" :to="'/tracker-details/' + props.row.trackers[0]._id">
                <label class="label-router-link mb-0">{{props.row.trackerName}}</label>
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'alertInfo'">
              <span>{{ getInfoLabel(props.row) }}</span>
            </span>
          </template>
        </TableAdvanced>

        <TableAdvanced v-if="resolvedAlerts" title="Resolved alerts" :columns="columnsResolved" :rows="resolvedAlerts" :sortBy="{field: 'createdTime', type: 'desc'}">
          <template #rowActions="{ props }">
            <span v-if="props.column.field == 'alertDetail'">
              <router-link :to="{ path: `/alerts/${props.row._id}`,}">
                <button type="button" class="btn btn-xs btn-primary">DETAIL</button>
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'trackerOrderName'">
              <router-link v-if="props.row.trackerOrder !== undefined" :to="'/orders/' + props.row.trackerOrder">
                <label class="label-router-link mb-0">{{props.row.trackerOrderName}}</label>
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'trackerName'">
              <router-link v-if="props.row.trackers.length > 0" :to="'/tracker-details/' + props.row.trackers[0]._id">
                <label class="label-router-link mb-0">{{props.row.trackerName}}</label>
              </router-link>
            </span>
            <span v-else-if="props.column.field == 'alertInfo'">
              <span> {{ getInfoLabel(props.row) }} </span>
            </span>
          </template>
        </TableAdvanced>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions,mapGetters } from 'vuex'
import { FETCH_ACTIVE_ALERTS, FETCH_RESOLVED_ALERTS } from '@/store/alert/constants'
import { USER_DATETIME_FORMAT } from '@/store/user/constants'
import TableAdvanced from '@/components/tables/TableAdvanced.vue'

export default { 
  components: {
    TableAdvanced
  },
  data() {
    return {
      isLoading: false,
    }
  },
  watch: {
    filterCompany: {
      async handler() {
        await this.refresh()
      },
      deep: true
    },
    user: {
      async handler() {
        await this.refresh()
      },
      deep: true
    },
  },
  computed: {
    ...mapState('user', ['user']),
    ...mapState('alert', ['activeAlerts', 'resolvedAlerts']),
    ...mapGetters('user', [USER_DATETIME_FORMAT]),
    isAdmin() {
      return this.user?.role === 'admin'
    },
    dateFnsFormat() {
      return this.USER_DATETIME_FORMAT.replace('DD', 'dd').replace('YYYY', 'yyyy').replace('A', 'a').replace(' Z', '')
    },
    columnsActives() {
      return [
        { label: 'Order List', field: 'trackerOrderName', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', width: '200px' },
        { label: 'Tracker ID', field: 'trackerName', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', width: '200px' },
        { label: 'State', field: 'state', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', width: '100px' },
        { label: 'Description', field: 'description', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', sortable: false },
        { 
          label: 'Created time',
          type: 'date',
          dateInputFormat: this.dateFnsFormat,
          dateOutputFormat: this.dateFnsFormat,
          formatFn: (value) => this.isLoading ? '' : value,
          field: 'createdTime',
          hidden: this.isLoading,
          tdClass: 'text-center text-nowrap valign-middle',
          thClass: 'text-center text-nowrap' 
        },
        { label: 'Info', field: 'alertInfo', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', width: '300px', sortable: false },
        { field: 'alertDetail', width: '50px', sortable: false, hidden: !this.isAdmin },
      ]
    },
    columnsResolved() { 
      return [
        { label: 'Order List', field: 'trackerOrderName', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', width: '200px' },
        { label: 'Tracker ID', field: 'trackerName', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', width: '200px' },
        { label: 'State', field: 'state', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', width: '100px' },
        { label: 'Description', field: 'description', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', sortable: false },
        { 
          label: 'Created time', 
          type: 'date', 
          dateInputFormat: this.dateFnsFormat, 
          dateOutputFormat: this.dateFnsFormat,
          hidden: this.isLoading, 
          formatFn: (value) => this.isLoading ? '' : value,
          field: 'createdTime', 
          tdClass: 'text-center text-nowrap valign-middle', 
          thClass: 'text-center text-nowrap' 
        },
        { 
          label: 'Resolved time', 
          type: 'date',
          dateInputFormat: this.dateFnsFormat,
          dateOutputFormat: this.dateFnsFormat,
          hidden: this.isLoading, 
          formatFn: (value) => this.isLoading ? '' : value,
          field: 'resolvedTime',
          tdClass: 'text-center text-nowrap valign-middle',
          thClass: 'text-center text-nowrap' 
        },
        { label: 'Info', field: 'alertInfo', tdClass: 'text-center text-nowrap valign-middle', thClass: 'text-center text-nowrap', width: '300px', sortable: false },
        { field: 'alertDetail', width: '50px', sortable: false, hidden: !this.isAdmin },
      ]
    }
  },
  async mounted() {
    await this.refresh()
  },
  methods: {
    ...mapActions('alert', {
      fetchActiveAlerts: FETCH_ACTIVE_ALERTS,
      fetchResolvedAlerts: FETCH_RESOLVED_ALERTS
    }),
    getInfoLabel({ startDatetime, endDatetime, state }) {
      const prefix = state.toLowerCase() === 'dislocated' ? 'Dislocated' : 'Data Missing'
      if (startDatetime && endDatetime) return `${prefix} between ${startDatetime} and ${endDatetime}`
      if (startDatetime) return `${prefix} from ${startDatetime}`
      if (endDatetime) return `${prefix} till ${endDatetime}`
      return `No ${state.toLowerCase()} data` 
    },
    async refresh() {
      this.isLoading = true
      // Load alerts data
      await this.fetchActiveAlerts()
      await this.fetchResolvedAlerts()
      this.isLoading = false
    }
  }
}
</script>