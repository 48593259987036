<template>
  <div class="login login-v1">
    <div class="login-container">
      <div class="login-header">
        <div class="brand">
          <router-link to="/" class="navbar-brand">
            <img src="/assets/img/logo/logo-admin-white.png" class="login-logo"/>
          </router-link>
        </div>
        <div class="icon">
          <i class="fa fa-lock"></i>
        </div>
      </div>
      <div class="login-body">
        <div class="login-content">
          <form @submit.prevent="handleSubmit" class="margin-bottom-0">
            <div class="form-group m-b-20">
              <input v-model="email" @input="email=$event.target.value.toLowerCase()" type="text" class="form-control form-control-lg inverse-mode" placeholder="Email Address" required />
            </div>
            <div class="form-group m-b-20">
              <input v-model="password" type="password" class="form-control form-control-lg inverse-mode" placeholder="Password" required />
            </div>
            <div class="row">
              <div class="checkbox checkbox-css m-b-20 col">
                <input type="checkbox" id="remember_checkbox" /> 
                <label for="remember_checkbox">
                  Remember Me
                </label>
              </div>
              <div class="col-4 mt-1 float-right">
                <router-link class="text-decoration-none loginText" to="/forgotten-password">
                  Forgotten password
                </router-link>
              </div>
            </div>
            <div class="login-buttons">
              <button type="submit" class="btn btn-yellow btn-block btn-lg">Login</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import { LOGIN, LOGOUT, SET_FILTER_COMPANY } from '@/store/user/constants'
import { FETCH_COMPANIES, FETCH_TRACKERS_COUNT, FETCH_ACTIVE_ALERTS_COUNT } from '@/store/main/constants'
import { FETCH_ORDERS } from '@/store/order/constants'
import pageOptions from '../config/pageOptions'

export default {
  data() {
    return {
      email: '',
      password: '',
    }
  },
  created() {
    pageOptions.pageEmpty = true
    this.logout()
  },
  beforeRouteLeave (to, from, next) {
    pageOptions.pageEmpty = false
    next()
  },
  computed: {
    ...mapState('user', ['loggedIn', 'user'])
  },
  methods: {
    ...mapMutations('user', {
      setFilterCompany: SET_FILTER_COMPANY
    }),
    
    ...mapActions('user', {
      login: LOGIN,
      logout: LOGOUT
    }),

    ...mapActions('main', {
      fetchCompanies: FETCH_COMPANIES,
      fetchTrackersCount: FETCH_TRACKERS_COUNT,
      fetchActiveAlertsCount: FETCH_ACTIVE_ALERTS_COUNT
    }),

    ...mapActions('order', {
      fetchOrders: FETCH_ORDERS,
    }),

    async handleSubmit() {
      const { email, password } = this
      try {
        await this.login({ email, password })
        if (!this.loggedIn) throw new Error('Login failed')

        // Get filter company from LS
        const cachedFilterCompany = JSON.parse(localStorage.getItem(this.user?.id))
        if (cachedFilterCompany) this.setFilterCompany(cachedFilterCompany)

        // Fetch orders, companies and trackers after login
        await this.fetchOrders()
        await this.fetchCompanies()
        await this.fetchTrackersCount()
        await this.fetchActiveAlertsCount()
        this.$router.push({ path: '/' }).catch(() => {})
      } catch (e) {
        this.$toast('Login failed', {
          className: ['et-alert']
        })
      }
    }
  }
}
</script>

<style>
.loginText {
  color: #c6ced5;
}

.loginText:hover {
  color: white;
}
</style>